import React, { useState } from 'react'
import attachmentIcon from "../../assets/icons/profileManagementIcons/attachmentIcon.svg"
import noteIcon from "../../assets/icons/profileManagementIcons/notesIcon.svg"
import TableRow from "@material-ui/core/TableRow";
import Table from "../Table/Table";
import { CircularProgress, createStyles, IconButton, makeStyles, TableBody, TableCell, TableHead } from '@material-ui/core';
import moment from 'moment';
import exportIcon from "../../assets/icons/profileManagementIcons/exportIcon.svg"
import Notes from './Notes';
import Attachments from './Attachment';
import { useSelector } from 'react-redux';
import { downloadFileFromUrl } from '../../utils/utils';
import { ReusableTablePagination } from './ProfileMangementContent';
const useStyles = makeStyles((theme) => createStyles({
  tableHead:{
    
    "& th" :{
      backgroundColor: "#32c2d9",
      color: "#ffffff",
    },
    
    "& .MuiTableCell-head":{
      padding:"0.5rem"
    }
  },
  tableBody:{
   "& tr>td" :{
      padding:"1px"
    },
  }
}))
function RecordedMeeting({selectedTreeMapClientId,getRecordedMeetingList,scheduleMeetingList,isScheduleFetching,totalCount,limit,offset,handleFilterList}) {
    const classes=useStyles()
    const userToken = useSelector((state) => state.auth.currentUserData.token);
    const [openNotes,setOpenNotes]=useState(false);
    const [openAttachments,setOpenAttachments]=useState(false);
    const [isExporting,setisExporting]=useState(false);
    const [selectedRowIndex,setSelectedRowIndex]=useState([]);

    const omitImageAttachment=(selectedRowDetails)=>{
      const allowedFileTypes=[".doc",".docx",".pdf",".eml"]
      const filteredList=selectedRowDetails?.attachments?.filter(file=>{
        const fileName=file?.attachment?.length?extractFileName(file?.attachment):""
        const isFileTypeAccepted=allowedFileTypes.some(fileType=>fileName.includes(fileType))
        return isFileTypeAccepted
      })
      return {...selectedRowDetails,attachments:filteredList}
    }
    const extractFileName = (url) => {
      const pathParts = url.split("/");
      const attachmentIndex = pathParts.indexOf("meeting_attachments");
        return pathParts[attachmentIndex + 1].split("?")[0];
  };
    const exportMeetingData=async()=>{
          setisExporting(true)
          try{
            await downloadFileFromUrl(`${process.env.REACT_APP_BASE_URL}export-meeting-details/?client_id=${selectedTreeMapClientId}`,"meeting-details",true,userToken)
          }catch(e){
        }
        setisExporting(false)
      }
  return (
		<>
    <div  className="d-flex justify-content-end" style={{ color: '#32c2d9' ,cursor:"pointer",margin:"4px"}} >
              <span onClick={exportMeetingData}>Export<span className='ms-3'>{isExporting?<CircularProgress size="1rem" style={{ color: '#32c2d9' }} />:<img src={exportIcon}/>}</span>
              </span></div>
    <Table stickyHeader={true} scrollable={true} maxHeight="380px"  className={classes.excelTable}>
			<TableHead className={classes.tableHead}>
				<TableRow>
                      <TableCell align="left" style={{ color: "white" }}>
                          Date & Time
                      </TableCell>
                       <TableCell align="left" style={{ color: "white" }}>
                          Organizers
                      </TableCell>
                       <TableCell align="left" style={{ color: "white" }}>
                          Format
                      </TableCell>
                       <TableCell align="left" style={{ color: "white" }}>
                          Action
                      </TableCell>

				</TableRow>
			</TableHead>
			<TableBody className={classes.tableBody}>
				{(scheduleMeetingList && scheduleMeetingList?.length>0)?scheduleMeetingList.map((row, index) => {
                    const meetingDate=moment.utc(row?.meeting_date).format("DD-MM-YYYY")
                    const meetingTime=moment.utc(row?.meeting_date).format("hh:mm A")
					return (
						<TableRow>
							<TableCell >
                            {meetingDate+" "+meetingTime}
							</TableCell>
							<TableCell >
								{row?.organizer?`${row?.organizer?.user?.first_name} ${row?.organizer?.user?.last_name}`:""}
							</TableCell>
							<TableCell >
								{row?.meeting_type_display || ""}
							</TableCell>
							<TableCell align="left">
                            <IconButton onClick={()=>{
                               setSelectedRowIndex(index);
                               setOpenAttachments(true);
                               }}>
                               <img src={attachmentIcon} style={{margin:"6px"}}/>
                               </IconButton>
                               <IconButton onClick={()=>{
                               setSelectedRowIndex(index);
                               setOpenNotes(true);
                               }}>
                               <img src={noteIcon} style={{margin:"6px"}}/>
                               </IconButton>
						</TableCell>
					</TableRow>
			 )
																		}): 
                                      
          isScheduleFetching ?<TableRow>
          <TableCell colSpan={12} className="text-center">
          <CircularProgress size="1.5rem" style={{ color: "#32c2d9" }}/>
          </TableCell></TableRow>
          :
          <TableRow>
            <TableCell colSpan={12} className="text-danger text-center">
            No Data Found
          </TableCell>
           </TableRow>
         
        } 
    </TableBody>
    
</Table>
<ReusableTablePagination
        totalCount={totalCount}
        offset={offset}
        limit={limit}
        getFilterList={handleFilterList}
      />
   <Attachments getAttachments={getRecordedMeetingList} open={openAttachments} extractFileName={extractFileName} selectedList={omitImageAttachment(scheduleMeetingList[selectedRowIndex])}  setOpenAttachments={setOpenAttachments} isAttachmentAdded={isScheduleFetching} />
   <Notes getNotes={getRecordedMeetingList} open={openNotes} selectedList={scheduleMeetingList[selectedRowIndex]}  setOpenNotes={setOpenNotes} isNotesAdded={isScheduleFetching} />
</>
  )
}

export default RecordedMeeting
