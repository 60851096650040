import React, { useState, useEffect, useRef, useMemo } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@mui/material/Tooltip';
import Modal from '../../Modal/Modal';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '../../Button/Button';
import { Formik, Form, Field } from 'formik';
import { addEventSchema } from '../../../schemas/calendarSchema';
import Grid from '@material-ui/core/Grid';
import Input from '../../Input/Input';
import * as Yup from 'yup';
import CheckBox from '../../CheckBox/CheckBox';
import DatePicker from '../../DatePicker/DatePicker';
import TimePicker from '../../TimePicker/TimePicker';
import PersonIcon from '@material-ui/icons/Person';
import TitleIcon from '@material-ui/icons/Title';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useDispatch, useSelector } from 'react-redux';
import RoomIcon from '@material-ui/icons/Room';
import SubjectIcon from '@material-ui/icons/Subject';
import { calendarRepeatOptions, reminderOptions, daysCircles } from '../../../utils/constants';
import moment from 'moment';
import { arrayToRandomObjectsTransformer, bytesToSize } from '../../../utils/utils';
import { addEventAsync, editEventAsync, removeEventData } from '../../../redux/calendar/calendar.actions';
import FileAttachmentModalComponent from '../../FileAttachmentModal/FileAttachmentModalComponent/FileAttachmentModalComponent';
import { removeIndivigualFileItems, openCloseAttachmentModal } from '../../../redux/ui/ui.actions';
import appConfig from '../../../appConfig.json';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Composer, { customElementStylesPreservingPlugin, CustomFigureAttributes } from '../../Composer/Composer';
import { searchMailRecipientsAsync, getInternalDriveFoldersAsync } from '../../../redux/mail/mail.action';
import MultiSelect from '../../MultiSelect/MultiSelect';
import './AddEditModal.scss';
import _ from 'lodash';
import FileAttachmentPreview from '../../FileAttachmentPreview/FileAttachmentPreview';
import {  emailCheckerSchema } from '../../../schemas/mailSchema';
import FullCalendar from '@fullcalendar/react';
import { useSnackbar } from 'notistack';
import { axiosGet } from '../../../utils/api';
import { fetchGET, fetchGet } from '../../../services/FeeMatchingService';
import { CircularProgress, IconButton } from '@material-ui/core';

const toolbarItems = ['heading', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'Undo', 'Redo'];


export const moment_UTC_format=(date,time)=>{
    const date_string=moment(date).format("YYYY-MM-DD")
    const time_string=moment(time).format("HH:mm")+":00"
    return moment(`${date_string} ${time_string}`).format("YYYY-MM-DDTHH:mm:ss[Z]")
}
const AddandEditEventModal = ({ 
    calendarRef, isAddEVentModalOpen, setIsAddEventModalOpen, eventData, selectedCalendarInfo, handleClose,
    openEmailErrorModalHandler,addZoomLinkButton,
    setErrorEmailList
}) => {

    const dispatch = useDispatch();
    const formikRef = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const defaultDescription=useMemo(()=>"<!DOCTYPE html>\r\n<html>\r\n   <head>\r\n      <title>Page Title</title>\r\n   </head>\r\n<body></body></html>",[])
    const [isZoomCreating,setIsZoomCreating]= useState(false);
    const [description,setDescription]= useState(null);
    const [isConfirmAlertShown,setIsConfirmAlertShown]=useState(false);
    const userToken = useSelector((state) => state.auth.currentUserData.token);
    const searchMailRecipientsData = useSelector(state => state.mail.searchMailRecipientsData);
    const isSearchMailReceiptLoading = useSelector(state => state.mail.isSearchMailReceiptLoading);
    const totalAttachmentFileSize = useSelector(state => state.ui.totalAttachmentFileSize);
    const isAttachmentModalOpen = useSelector(state => state.ui.isAttachmentModalOpen);
    const addEventLoading = useSelector(state => state.calendar.addEventLoading);
    const isEditFetching = useSelector(state => state.calendar.isEditFetching);

    const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
    const internalDriveFoldersData = useSelector(state => state.mail.internalDriveFoldersData);
    const filesSelectedFromAttachmentModal = useSelector(state => state.ui.filesSelectedFromAttachmentModal);
    var eventIdForEdit = null;
    useEffect(() => {
        if (internalDriveFoldersData)
        {
            setOpenAttachmentModal(true)
        }
    }, [internalDriveFoldersData])

    // same function for closing every modal
    const closeAttachmentModal = () => {
        setOpenAttachmentModal(false);
    };

    const getZoomMeetingLink=async (queryParams)=>{
        try {
          setIsZoomCreating(true)
          const response=await fetchGET(userToken,"create-zoom-room",queryParams)
          const joinurl=response.join_url
          if(response.status&&response.status_code!="400"){
             setIsZoomCreating(false)
             return joinurl
          }
          else{
            setIsZoomCreating(false)
            enqueueSnackbar( response.message , {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
          }
        }
        catch(err){
          setIsZoomCreating(false)
          enqueueSnackbar( err.message , {
            variant: 'error',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
        });
        }
    }


      const isZoomLinkPresent = useMemo(() => {
       if(description)
        return description?.includes('id="zoom-link"')
       else
         return false
    }, [description]);

    const handleInsertOrRemoveZoomLinkAtLine = async (values) => {
        const {stateDate, startTime, endDate, endTime, title, allDayEvent ,description}=values
        
            const  queryParams={"subject":title,"event_start":moment_UTC_format(stateDate,startTime),"event_end":moment_UTC_format(endDate,endTime)}
            const parser = new DOMParser();
            const doc = parser.parseFromString(description,'text/html');
        
            if (isZoomLinkPresent) {
              // Remove the Zoom link if it exists
              const zoomLinkDiv = doc.querySelector('#zoom-link');
              if (zoomLinkDiv) zoomLinkDiv.remove();
            } else {
              // Fetch the Zoom link from the API
              let zoomLink=""
              try {
                 zoomLink = await getZoomMeetingLink(queryParams);
        
                // Add the Zoom link if it doesn't exist
                const body = doc.body;
                if (body) {
                  const zoomDiv = document.createElement('div');
                  zoomDiv.innerHTML =zoomLink? `
                    <div class="public-DraftStyleDefault-block public-DraftStyleDefault-ltr" id="zoom-link">
                    <p>Hi,</p>
  <p>
    It was great to speak earlier. I look forward to our meeting on <strong>${momentFormats(values.stateDate,"DD-MM-YYYY, dddd")}</strong> at <strong>${momentFormats(values.startTime,"HH:mm")} - ${momentFormats(values.endTime,"HH:mm")}</strong>.
  </p>
  <p>Please see below for the meeting link:</p>
  <p>
    Join Zoom Meeting: 
    <a class="rdw-link-decorator-wrapper" href="${zoomLink}" target="_self">
      ${zoomLink}
    </a>
  </p></div>`.trim():""
                  body.appendChild(zoomDiv.firstElementChild);
                }
              } catch (error) {
                
            }
        }
            // Serialize the updated DOM back to a string
            const updatedTemplateData = new XMLSerializer().serializeToString(doc.documentElement);
  formikRef.current.setFieldValue("description", updatedTemplateData);
  setIsZoomCreating(false);
  setDescription(updatedTemplateData);
    }
   
    const getDateTimeFromForm = (date, time, allDayEvent, isEndTime = false) => {
        const dateObj = moment(date).toString();
        const timeObj = moment(time).toString();

        let dateString = moment(dateObj).format('YYYY-MM-DD');
        const timeString = moment(timeObj).format('HH:mm') + ":00";

        //For all day logic
        if (allDayEvent)
        {
            if (isEndTime)
            {
                // adding 1 day for end time
                dateString = moment(dateObj).add(1, 'days').format('YYYY-MM-DD');
            }
            const dateNeeded = moment(moment(`${dateString} 00:00:00`).toString()).format('Y-MM-DD HH:mm');
            return dateNeeded
        }
        const newDateString = moment(dateString + " " + timeString).utc().format('Y-MM-DD HH:mm');
        return newDateString;
    }



     // Section Starts :: If opening for editing form should be prefilled else field should be empty , also managing setting start and end date in modal baed on days dragged in calendar  -- 
     const initialValuesMaker = (isOpenForEditing, eventData, selectedCalendarInfo = null) => {
    if (isOpenForEditing) {
        if (eventData) {
            const participants = arrayToRandomObjectsTransformer(eventData.attendees, 'searchRecipientName');
            const reminderData = reminderOptions.find(reminderItem => reminderItem.value === eventData.remind_before_minutes);
            eventIdForEdit = eventData.id;

            // Adjusting endDate for all-day events
            let endDate = eventData.event_end;
            if (eventData.is_all_day) {
                endDate = moment(eventData.event_end).subtract(1, 'days').toDate();
            }

            return {
                participants: participants,
                title: eventData.subject,
                allDayEvent: eventData.is_all_day,
                stateDate: moment(eventData.event_start).local().toDate(), // Use Date object instead of formatted string
                endDate: endDate, // Keep endDate as a Date object
                startTime: moment(eventData.event_start).toDate(), // Use Date object
                endTime: moment(eventData.event_end).toDate(), // Use Date object
                reminder: reminderData ? reminderData.id : '',
                repeat: 1, // not needed in this phase
                location: eventData.location,
                description: defaultDescription,
                days: [],
            };
        }
    } else {
        // Default endDate logic for new events
        let endDateForForm = selectedCalendarInfo ? new Date(selectedCalendarInfo.end) : new Date();
        if (selectedCalendarInfo?.view?.type === 'monthView' || selectedCalendarInfo?.view?.type === 'dayGridMonth') {
            endDateForForm = selectedCalendarInfo ? moment(selectedCalendarInfo.end).subtract(1, 'days').toDate() : new Date();
        } else if (selectedCalendarInfo?.view?.type === 'weekView' || selectedCalendarInfo?.view?.type === 'workWeekView' || selectedCalendarInfo?.view?.type === 'dayView') {
            if (selectedCalendarInfo?.allDay) {
                endDateForForm = selectedCalendarInfo ? moment(selectedCalendarInfo.end).subtract(1, 'days').toDate() : new Date();
            }
        }

        return {
            participants: (eventData && eventData.attendees) ? eventData.attendees : [],
            title: (eventData && eventData.subject) ? eventData.subject : '',
            allDayEvent: false,
            stateDate: selectedCalendarInfo ? new Date(selectedCalendarInfo.start) : new Date(),
            endDate: endDateForForm,
            startTime: selectedCalendarInfo ? new Date(selectedCalendarInfo.start) :new Date(), // Use current date-time
            endTime: selectedCalendarInfo ? new Date(selectedCalendarInfo.end) :moment().add(1, 'hour').toDate(), // Set endTime an hour after startTime
            reminder: 1,
            repeat: 1, // not needed in this phase
            location: '',
            description:defaultDescription,
            days: [],
        };
    }
};

     // Section Starts :: If opening for editing form should be prefilled else field should be empty , also managing setting start and end date in modal baed on days dragged in calendar  -- 

    


    // Section Starts :: save event  -- 
    const currentUserId = useSelector(state => state.auth.currentUserData.id);
    const currentUserEmail = useSelector(state => state.auth.currentUserData.email);
    const saveEventHandler = async (formikValues, eventData=null) => {

        const { stateDate, startTime, endDate, endTime, allDayEvent, title, repeat, reminder, location, description, until, days, participants } = formikValues;

        const reminderData = reminderOptions.find(reminderItem => reminderItem.id === reminder); // take value from options with reminder it coming from formik
        const reminderValue = reminderData ? reminderData?.value : '';


        var participantsPlainArray = [];
        if (participants.length > 0)
        {
            participantsPlainArray = participants.map(participantItem => participantItem.searchRecipientName);
        }

        const repeatData = calendarRepeatOptions.find(repeatItem => repeatItem.id === repeat);
        const repeatValue = repeatData.value;

        const daysValue = []
        daysCircles.forEach(daysItem => {
            if (days.includes(daysItem.id))
            {
                daysValue.push(daysItem.name);
            }
        });


        // making data for api 

        const formData = new FormData();
        formData.append("subject", title);

        formData.append("event_start", getDateTimeFromForm(stateDate, startTime, allDayEvent));
        formData.append("event_end", getDateTimeFromForm(endDate, endTime, allDayEvent, true));


        formData.append("location", location);
        formData.append("remind_before_minutes", reminderValue ? reminderValue : '');
        formData.append("is_all_day", allDayEvent);
        formData.append("description", description);
        formData.append("organizer", currentUserEmail);
        formData.append("importance", 1);
        formData.append("user", currentUserId);

        participantsPlainArray.map((item) => {
            formData.append('attendees', item);
        })

        if(eventData && eventData?.task_id){
            formData.append("task_id", eventData?.task_id);
        }
        if(eventData && eventData?.advisor_id){
            formData.append("advisor_id", eventData?.advisor_id);
        }
        if(eventData && eventData?.client_id){
            formData.append("client_id", eventData?.client_id);
        }
        if(eventData && eventData?.is_draft_meeting){
            formData.append("is_draft_meeting", eventData?.is_draft_meeting);
        }

       
        let alreadyExistingAttachments;
        if(eventData)
        {
            const { attachment_list } = eventData;
            alreadyExistingAttachments = attachment_list.map( item => item.fileId);
        }
        else
        {
            alreadyExistingAttachments = [];
        }


        // filter out local files and cap drive files 
        let localFileAttachments = [];
        let driveFiles = [];
        filesSelectedFromAttachmentModal.forEach(item => {
            if (item instanceof File)
            {
                localFileAttachments.push(item)
            }
            else
            {
                driveFiles.push(item.fileId)
            }
        });


        localFileAttachments.forEach(fileItem => {
            formData.append('attachments', fileItem, fileItem.name);
        });


        driveFiles.forEach( fileItem => {
            if(alreadyExistingAttachments.includes(fileItem))
            {
                formData.append('file_id_list', fileItem);
            }
            else
            {
                formData.append('cap_drive_attachments', fileItem); 
            }
        })




        // check if anyofthe email entered is error and show popup else submit form
        const participantEmailErrors = [];
        const isToValid = await Promise.all(participantsPlainArray.map(async (emailItem) => {
            const isItemValid = await emailCheckerSchema.isValid({ emailItem: emailItem });
            if (!isItemValid)
            {
                participantEmailErrors.push(emailItem);
            }
            return emailCheckerSchema.isValid({ emailItem: emailItem })
        }))
        const areAllParticipantsEmailsValid = isToValid.every(Boolean);
        const errors = {
            "participantEmailErrors" : participantEmailErrors,
        }


        // send if everything is ok
        if (!areAllParticipantsEmailsValid)
        {
            openEmailErrorModalHandler(true);
            setErrorEmailList(errors);
        }
        else
        {
            if (eventIdForEdit)
            {
                dispatch(editEventAsync(formData, eventIdForEdit));
                eventIdForEdit = null;
            } else
            {
                dispatch(addEventAsync(formData))
            }
    
            // close on save
            dispatch(removeEventData())
            dispatch(removeIndivigualFileItems([]))
        }



    }

    // Section Starts :: save event  --


    const isFormDirty = (initialData, newData) => {

        const updatedValues = {...newData};
        const initialFormValues = initialData;
                            
        const initialParticipants = initialFormValues.participants.map( participantItem => participantItem.searchRecipientName);
        const newParticipants =  updatedValues.participants.map( participantItem => participantItem.searchRecipientName);
        const particpantsEqual =  _.isEqual(initialParticipants, newParticipants);
    
        delete updatedValues.participants;
        delete initialFormValues.participants;

        const valuesWithOutParticipantsEqual = _.isEqual(initialFormValues, updatedValues);
        
        const initialAttachments = eventData.attachment_list;
        const newFileAttachments = filesSelectedFromAttachmentModal;
        const attachmentsEqual = _.isEqual(initialAttachments, newFileAttachments);

        
        if(particpantsEqual && valuesWithOutParticipantsEqual && attachmentsEqual)
        {
            return false;
        }
        else
        {
            return true;
        }

    }
    const momentFormats=(time,format)=>{
        return moment(time).format(format)
    }
    const addEventSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        participants: Yup.string().required("participants is required"),
        stateDate: Yup.date().required("Start date is required"),
        startTime: Yup.date()
            .required("Start time is required")
            .test('is-future-time', 'You cannot select a past time.', function (value) {
                if (!value) return true;
                const { stateDate } = this.parent;
                const isToday = moment(stateDate).isSame(new Date(), 'day');
                const selectedTime = moment(value);
                const currentTime = moment();
                return isToday ? selectedTime.isAfter(currentTime) : true;
            }),
        endDate: Yup.date()
            .min(Yup.ref('stateDate'), "End date can't be before start date")
            .required("End date is required"),
        endTime: Yup.date()
            .required("End time is required")
            .nullable()
            .test('is-valid-date', 'End time must be a valid date.', value => moment(value).isValid())
            .test('is-not-same-time', 'Start time and End time cannot be the same.', function (value) {
                const { startTime } = this.parent;
                if (!value || !startTime) return true;
                const selectedEndTime = moment(value, "HH:mm");
                const selectedStartTime = moment(startTime, "HH:mm");
                return !selectedEndTime.isSame(selectedStartTime);
            })
            .test('is-after-start-time', 'End time cannot be before start time.', function (value) {
                const { startTime, stateDate, endDate } = this.parent;
                if (!value || !startTime) return true;
                const isSameDay = moment(stateDate).isSame(endDate, 'day');
                const selectedEndTime = moment(value, "HH:mm");
                const selectedStartTime = moment(startTime, "HH:mm");
                return isSameDay ? selectedEndTime.isAfter(selectedStartTime) : true;
            }),
    });
    
   
    
    
    const maxFileAttachmentSize = appConfig.document_upload.calendar_attachments.maxSize;
    const attachmentMessage = `Attachment size exceeded (Max. ${bytesToSize(maxFileAttachmentSize)})`;
    const attachmentLabelMsg = `(Max file size is ${bytesToSize(maxFileAttachmentSize)})`;

    return (
        <Modal
            className="customModal addCalendarEventModal customScrollbar"
            open={isAddEVentModalOpen.status}
            handleClose={setIsAddEventModalOpen}
            disableBackdropClick
            size= "md"
        >
            <div className="customModalContent">
                <Formik
                    innerRef={formikRef}
                    initialValues={initialValuesMaker(isAddEVentModalOpen.isOpenForEditing, eventData, selectedCalendarInfo)}
                    validationSchema={addEventSchema}
                    validateOnChange
                    onSubmit={(values) => {
                        // in the case of editing only save if values are changed
                        if(isAddEVentModalOpen.isOpenForEditing)
                        {
                            const initialFormValues = initialValuesMaker(isAddEVentModalOpen.isOpenForEditing, eventData, selectedCalendarInfo);
                            const dirty = isFormDirty(initialFormValues, values);
                            if(dirty)
                            {
                                saveEventHandler(values, eventData)
                            }
                            else
                            {
                                setIsAddEventModalOpen({ status: false, isOpenForEditing: false });
                            }
                        }
                        else
                        {
                            saveEventHandler(values, eventData)
                        }
                    }}
                    
                >
                    {({ validateForm,values, errors, setFieldValue,setTouched, handleChange, dirty, isSubmitting }) => {
                        return (
                            <Form >
                                <DialogContent className="dialogueWrapper">
                                    <Grid container spacing={2} className="formItemWrapper">

                                        <Grid container spacing={2} item>
                                            <Grid item xs={12} sm={8} className="eventItemWrapper">
                                                <TitleIcon className="eventModalItemIcon" />
                                                <Field
                                                    component={Input}
                                                    name="title"
                                                    label="Title"
                                                    className="eventModalItem"
                                                    convertToCase="sentenceCase"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Field
                                                    component={CheckBox}
                                                    name="allDayEvent"
                                                    label="All Day Event"
                                                    checkBoxType="single"
                                                    className="eventModalItem"

                                                />
                                            </Grid>
                                            <Grid item xs={12} className="eventItemWrapper">
                                                <PersonIcon className="eventModalItemIcon" />

                                                <Field
                                                    component={MultiSelect}
                                                    name="participants"
                                                    label="Participant"
                                                    filterSelectedOptions={true}
                                                    getOptionsSelectedBasedOnOptionLabel={true}
                                                    //required
                                                    onInputChange={(e, value, reason) => {
                                                        dispatch(searchMailRecipientsAsync(value));
                                                    }}
                                                    options={searchMailRecipientsData}
                                                    loading={isSearchMailReceiptLoading}
                                                    optionLabel="searchRecipientName"
                                                    creatable
                                                    placeholder={false}
                                                // clearOnBlur
                                                />

                                            </Grid>

                                            <Grid item xs={12} sm={3} className="eventItemWrapper">
                                                <AccessTimeIcon className="eventModalItemIcon" />
                                                <Field
                                                    component={DatePicker}
                                                    name="stateDate"
                                                    label="Start Date"
                                                    icon={false}
                                                    disablePast={true}
                                                    className="eventModalItem"
                                                />
                                            </Grid>
                                            {!values.allDayEvent &&
                                                <Grid item xs={12} sm={3}>
                                                    <Field
                                                        component={TimePicker}
                                                        name="startTime"
                                                        label="Start Time"
                                                        onChange={(value)=>{ 
                                                        const formattedStartTime = moment(value, "HH:mm");
                                                        const endTime = formattedStartTime.clone().add(1, "hour"); // Add 1 hour
                                                        setTimeout(()=>{
                                                            setFieldValue("endTime", endTime._d);
                                                        },0)
                                                    }}
                                                        icon={false}
                                                        className="eventModalItem"
                                                        disabled={values.allDayEvent ? true : false}
                                                    />
                                                </Grid>
                                            }
                                            <Grid item xs={12} sm={3}>
                                                <Field
                                                    component={DatePicker}
                                                    name="endDate"
                                                    label="End Date"
                                                    icon={false}
                                                    disablePast={true}
                                                    className="eventModalItem"
                                                />
                                            </Grid>
                                            {!values.allDayEvent &&
                                                <Grid item xs={12} sm={3}>
                                                    <Field
                                                        component={TimePicker}
                                                        name="endTime"
                                                        label="End Time"
                                                        icon={false}
                                                        className="eventModalItem"
                                                        disabled={values.allDayEvent ? true : false}
                                                    />
                                                </Grid>
                                            }
                                            {values.allDayEvent && <Grid item xs={6}></Grid>}
                                            <Grid item xs={12} className="eventItemWrapper">
                                                <RoomIcon className="eventModalItemIcon" />
                                                <Field
                                                    component={Input}
                                                    name="location"
                                                    label="Location"
                                                    className="eventModalItem"
                                                    convertToCase="sentenceCase"
                                                />
                                            </Grid>
                                            {addZoomLinkButton&&<Grid item xs={12} md={10}>
                                                  <Button
                                                              width="170px"
                                                              bgColor="secondary"
                                                              disabled={isZoomCreating || values.allDayEvent}
                                                              onClick={async (e) => {
                                                                const errors = await　validateForm();
                                                                if(isZoomLinkPresent){
                                                                    handleInsertOrRemoveZoomLinkAtLine(values);
                                                                }
                                                               else if (Object.keys(errors).length === 0 && !isZoomLinkPresent) {
                                                                    setIsConfirmAlertShown(true)
                                                                } else {
                                                                      setTouched(
                                                                          Object.keys(errors).reduce((acc, key) => {
                                                                              acc[key] = true;
                                                                              return acc;
                                                                          }, {}),
                                                                          true
                                                                      );
                                                                           }
                                                             }}
                                                              className="insertZoomlink btn"
                                                            >
                                                              {isZoomLinkPresent ? 'Remove Zoom Link' : 'Attach Zoom Link'} {isZoomCreating&&<CircularProgress size={12} style={{color:"#32c2d9",marginLeft:"3px"}} />}
                                                            </Button>
                                                             <Tooltip title={`Schedules the meeting on ${momentFormats(values.stateDate,"DD-MM-YYYY, dddd")} (${momentFormats(values.startTime,"hh:mm a")} - ${momentFormats(values.endTime,"hh:mm a")})`}  interactive arrow placement="top"><IconButton disabled={values.allDayEvent}><InfoIcon className="info_icon icon"/></IconButton></Tooltip>
                                               </Grid>}
                                            <Grid item xs={12} className="eventItemWrapper">
                                                <SubjectIcon className="eventModalItemIcon eventModalItemIcon--mod" />
                                                <Composer
                                                    config={{
                                                         toolbar: toolbarItems,
                                                         extraPlugins: [customElementStylesPreservingPlugin, CustomFigureAttributes],
                                                    }}
                                                    height="100px"
                                                    className="eventDescriptionComposer"
                                                    data={values.description}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setFieldValue('description', data);  
                                                        setDescription(data);
                                                    }}
                                                />
                                                {errors.description}
                                                {
                                                    totalAttachmentFileSize > maxFileAttachmentSize 
                                                    ? 
                                                <p className="attachmentSizeExceedMsg">{attachmentMessage}</p> 
                                                    : 
                                                    null
                                                }
                                                {
                                            }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                        {
                                            filesSelectedFromAttachmentModal.length > 0 &&
                                            <FileAttachmentPreview
                                                files={filesSelectedFromAttachmentModal}
                                                showDownloadIcon={false}
                                            />
                                        }
                                        </Grid>
                                    </Grid>
                                    <DialogActions className="modalFooter">
                                        <div className="modalFooterWrapper">
                                            <Grid container>
                                                <Grid item xs={12} md={6} className="attachmentIconWrapper">
                                                    <span className="attachIcon">
                                                        <AttachFileIcon
                                                            className="attachmentIcon"
                                                            onClick={() => {
                                                                dispatch(openCloseAttachmentModal(true));
                                                            }}
                                                        />
                                                        <p>{attachmentLabelMsg}</p>
                                                    </span>
                                                </Grid>
    
                                                <Grid container xs={12} md={6} justify="flex-end" className="modalFooterButtons">
                                                    <Button onClick={()=>{
                                                        handleClose();
                                                        setFieldValue('description', defaultDescription);
                                                        }} color="primary" customStyle={{ background: 'white', marginLeft: '15px' }} width='150px' className="calendarBtn" >
                                                        Discard
                                                    </Button>
                                                    <Button type="submit" color="primary" autoFocus bgColor="secondary" width='150px' className="calendarBtn"  
                                                    disabled={(isSubmitting || addEventLoading || isEditFetching || (totalAttachmentFileSize > maxFileAttachmentSize)) ? true : false}
                                                    >
                                                        {
                                                            isAddEVentModalOpen.isOpenForEditing ? 'Update' : 'Create'
                                                        }{(isSubmitting || addEventLoading || isEditFetching )&& <CircularProgress size={12} style={{color:"#32c2d9",marginLeft:"3px"}} />}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        
                                        </div>
                                    </DialogActions>
                                </DialogContent>
                                <Modal open={isConfirmAlertShown} handleClose={() => setIsConfirmAlertShown(false)} size="xs" className="messageDraftModal" showDefaultCloseIcon>
                <DialogContent>
                    <h3 className="customHead">Meeting Creation</h3>
                    <p className="customDescription">{`Are you sure you want to schedule a meeting on ${momentFormats(values.stateDate,"DD-MM-YYYY")} (${momentFormats(values.startTime,"hh:mm a")} - ${momentFormats(values.endTime,"hh:mm a")}) ?.`}</p>
                    <DialogActions className="modalFooter eventActionFooter">
                        <Button onClick={() => setIsConfirmAlertShown(false)} color="primary" customStyle={{ background: 'white', marginRight: '10px' }} width='150px'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() =>  {
                                handleInsertOrRemoveZoomLinkAtLine(values);
                                setIsConfirmAlertShown(false);
                            }}
                            color="primary" autoFocus bgColor="secondary" width='150px' customStyle={{ marginRight: '20px' }}
                        >
                            Schedule
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Modal>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
          
            {/* Attachment Modal */}
            <FileAttachmentModalComponent
                open={isAttachmentModalOpen}
                handleClose={() => dispatch(openCloseAttachmentModal(false))}
                size="sm"
                fileSizeErrorMessage={attachmentMessage}
                maxSize={maxFileAttachmentSize} // 4 MB
            />

        </Modal>

    )
}



export default AddandEditEventModal;





