import React, { useRef, useEffect, useState } from 'react';
import './DashboardContent.scss'
import { formatNumber, isNOTNullOrUndefined } from '../../utils/utils';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Person, PersonAdd } from '@material-ui/icons';


const RenderTooltip =({ active, payload, coordinate, zoomLevel })=> {
  const social_mediums = useSelector(state => state.dashboard.social_medium_data);
  const tooltipRef = useRef(null);
  const [tooltipDimensions, setTooltipDimensions] = useState({ width: 0, height: 0 });
  const [isZoomed, setIsZoomed] = useState(false);
  useEffect(() => {
    setIsZoomed(zoomLevel > 200);
  }, [zoomLevel]);

  useEffect(() => {
    if (tooltipRef.current) {
      setTooltipDimensions({
        width: tooltipRef.current.offsetWidth,
        height: tooltipRef.current.offsetHeight,
      });
    }
  }, [active, payload]); // Update dimensions when tooltip visibility or content changes
 const scale = 100 / zoomLevel;
 const leftOffset = -tooltipDimensions.width / 2;
    const topOffset = -tooltipDimensions.height ;

  if (payload && payload.length > 0) {
    const { name, value, aum, color, last_email_sent, socials,relative_client_name } = payload[0].payload;
    const foundSocialMediums = new Set(socials);
    const tooltipStyles = {
      transform: `scale(${scale})`,
    
    };
    const tooltipStylesZoom ={
      transform: `scale(${scale})`,
      position: 'absolute',
       left: coordinate.x + leftOffset +50,
       top: coordinate.y + topOffset +10,
       whiteSpace: 'nowrap',
    }
 
   
    
    
    return (
      <div className='tooltip-custom'
       ref={tooltipRef}
      style={
        isZoomed? tooltipStylesZoom : tooltipStyles 
    
      }>
        <div className="tooltip-left">
          <p className='tooltip-name'>{name}</p>
          {relative_client_name ?  <div className='tooltip-relation'>
            <PersonAdd style={{fontSize:'10px',color:'teal'}} />
            <p className='tooltip-name' style={{fontSize:'10px',color:'teal',margin:0}}>{relative_client_name}</p>
          </div>:null }
         
          <p className='tooltip-aum'>£{formatNumber(aum)}</p>
          <div style={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
            {social_mediums.map(item => (
              <div key={item.id} style={{ width: '18px', height: '18px' }}>
                {foundSocialMediums.has(item.id) ? (
                  <img src={item.image_base64} alt={item.name} style={{ width: '100%', display: 'block' }} />
                ) : (
                  <img src={item.image_base64} alt={item.name} style={{ width: '100%', display: 'block', filter: 'grayscale(100%)' }} />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="tooltip-right">
          <div className="tooltip-datefield">
            <p className="tooltip-label">Last Contact Date :</p>
            <p className="tooltip-value">{moment(last_email_sent).format("MM-DD-YYYY")}</p>
          </div>
          <div className="tooltip-datefield">
            {/* <p className="tooltip-label">Due Date :</p>
            <p className="tooltip-value">10-10-2022</p> */}
          </div>
        </div>

      </div>
    );
  }
  return null;
};

export default RenderTooltip