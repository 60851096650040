import React, { useState, useEffect, useCallback, useRef } from 'react';
import Paper from '../../Mui/Paper/Paper';
import Grid from '@material-ui/core/Grid';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import './MailView.scss';
import PageWrapper from '../../PageWrapper/PageWrapper';
import { getMailDetailsAsync, expandAllMailReplayBox, markMailAsReadAsync, removeMailIdFromArray, getMailsFromFolderAsync, removeMailSuccessMessage, clearMailReplaySuccessMessage, clearMailReplayErrorMessage, markReadMailFromList, removeMailDetails, removeMailsInFolder } from '../../../redux/mail/mail.action';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ConversationBox from '../ConversationBox/ConversationBox';
import NoResults from '../../NoResults/NoResults';
import { useParams, useLocation, useHistory } from "react-router-dom";
import Layout from '../../../hoc/LayoutHOC/LayoutHOC';
import NameCircle from '../../NameCircle/NameCircle';
import Modal from '../../Modal/Modal';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '../../../components/Button/Button';
import { fetchMailCategoriesAsync } from '../../../redux/mail/mail.action';
import { toLowercaseAndRemoveSpaces, getTrimmedString, bytesToSize, checkPermissions } from '../../../utils/utils';
import FileAttachmentModalComponent from '../../FileAttachmentModal/FileAttachmentModalComponent/FileAttachmentModalComponent';
import ComposeMailForm from '../ComposeMailForm/ComposeMailForm';
import { useSnackbar } from 'notistack';
import CircularLoader from '../../CircularLoader/CircularLoader';
import appConfig from '../../../appConfig.json';
import { openCloseAttachmentModal } from '../../../redux/ui/ui.actions';
import { isEmpty, head } from 'lodash';
import NotAuthorizedMessage from '../../NotAuthorizedMessage/NotAuthorizedMessage';
import InfiniteScroll from 'react-infinite-scroller';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';


// --- Starts  : Scroll to bottom of page when clicked on any item -- 
const scrollDownHandler = () => {
    const div = document.getElementById("mailsRightWrapper");
    const windowHeight  = window.outerHeight;
    const options = {
        top: div.offsetTop + windowHeight/2,
        left: 0,
        behavior: 'smooth'
    }
    window.scrollTo(options);
};
// --- Ends  : Scroll to bottom of page when clicked on any item -- 



const expandAllConvoBoxes = (mailData,expandedItems, dispatch) => {
    if(isEmpty(expandedItems))
    {
        const allItemsIds = {};
        mailData.forEach(mailItem => {
            allItemsIds[mailItem.id] = true;
        })
        dispatch(expandAllMailReplayBox(allItemsIds));
    }
    else
    {
        dispatch(expandAllMailReplayBox({}));
    }
}




export const letterMaker = (name) => {

    if(name){
        let nameString = name.toString();
        const splittedArray = nameString.split(" ");
        const firstLetterOfFirstName = splittedArray[0].substring(0, 1);
        let firstLetterOfSecondName;
        if (splittedArray.length > 1)
        {
            firstLetterOfSecondName = splittedArray[1].substring(0, 1);
            return `${firstLetterOfFirstName}${firstLetterOfSecondName}`;
        }
        else
        {
            return firstLetterOfFirstName
        }
    }else{
        return "";
    }
}



const MailView = () => {

    const convoBoxRef =  useRef();
    const composeComponentRef = useRef();
    const leftViewRef = useRef()
    const [messageDraft, setMessageAsDraft] = useState(false);
    const history = useHistory();
    const location = useLocation();
    let { folderId, folderName } = useParams();
    const [ shouldScrollToBottomClick, setShouldScrollToBottomOnClick] = useState(false);
    const maxFileAttachmentSize = appConfig.document_upload.mail_attachments.maxSize;
    const currentUserData = useSelector(state => state.auth.currentUserData);
    const expandedItems = useSelector(state => state.mail.expandedItems);
    const dataLimit = 10;


    let isComposeMailFormAuthorized = false;
    if(!isEmpty(currentUserData))
    {
        isComposeMailFormAuthorized = checkPermissions(currentUserData.permission_list,['COMPOSE-MAIL'])
    }



    useEffect(() => {
        dispatch(fetchMailCategoriesAsync());
        const innerWidth = window.innerWidth;
        if(innerWidth < 600 )
        {
            setShouldScrollToBottomOnClick(true);
        }
        var resizeWindow = () => {
            const innerWidth = window.innerWidth;
            if(innerWidth < 600  && shouldScrollToBottomClick === false)
            {
                setShouldScrollToBottomOnClick(true);
            }
            else 
            {
                setShouldScrollToBottomOnClick(false);
            }
        }
        window.addEventListener("resize", resizeWindow);

        return () => {
            window.removeEventListener('resize', resizeWindow)
        }
    }, [])


    

    const [isComposeShown, setIsComposeShown] = useState(false);
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const action = params.get('action');
        if (action === 'compose')
        {
            setIsComposeShown(true)
        }
        else
        {
            // since compose mail is done in inbox section in mail view, page title not chaning issue was there for inbox page solving it here
            if(folderName == toLowercaseAndRemoveSpaces(appConfig.menu_static_names.inbox) && mailFolderList)
            {
                const folderData = mailFolderList.find(folderItem => {
                    // updating page title since hook cant be called inside hook
                    return toLowercaseAndRemoveSpaces(folderItem.folder_name) == toLowercaseAndRemoveSpaces(appConfig.menu_static_names.inbox);
                });
                document.title = folderData.pageTitle;
                setPageName(folderData.folder_name);
            }
            setIsComposeShown(false)
        }
    }, [location.search])





    
    const dispatch = useDispatch();
    useEffect(() => {
        let params = {
            "folder":folderId,
            "limit":dataLimit
        }
        dispatch(removeMailDetails());
        dispatch(removeMailsInFolder())
        dispatch(getMailsFromFolderAsync(params));
        // Code for scroll to top in mailLeftMaker
        if(leftViewRef.current){
            leftViewRef.current.scrollIntoView({block: "start"});
        }
    }, [folderId])


    const [currentlyActiveMail, setCurrentlyActiveMail] = useState(null);
    const [mailSubject, setSubject] = useState(null);
    const mailsInFolderLoading = useSelector(state => state.mail.mailsInFolderLoading);
    const mailsInFolder = useSelector(state => state.mail.mailsInFolder);
    const mailsInFolderError = useSelector(state => state.mail.mailsInFolderError);
    const mailDataListLoading = useSelector(state => state.mail.mailDataListLoading);
    const mailDataList = useSelector(state => state.mail.mailDataList);
    const mailDataListError = useSelector(state => state.mail.mailDataListError);
    const unreadMailsIdArray = useSelector(state => state.mail.unreadMailsIdArray);
    const isAttachmentModalOpen = useSelector(state => state.ui.isAttachmentModalOpen);
    const mailsInFolderCount = useSelector(state => state.mail.mailsInFolderCount);
    const mailsInFolderOffset = useSelector(state => state.mail.mailsInFolderOffset);


    // Get the first mail from mails and call api to list mails inside that folder -- 
    useEffect(() => {
        // Section Starts :: handling when there are empty mails inside a folder -- 
        if (mailsInFolder && mailsInFolder.length)
        {
            const firstElementInMailInFolderData = mailsInFolder[0]?.id;
            const firstMailItemConversartionId = mailsInFolder[0].conversation_id;
            dispatch(markMailAsReadAsync(firstMailItemConversartionId));
            
            // only send second request to fetch details of default selected mail if there are mails 
            if (!mailDataList && firstElementInMailInFolderData)
            {
                setCurrentlyActiveMail(firstElementInMailInFolderData)
                dispatch(removeMailIdFromArray(firstElementInMailInFolderData))
                dispatch(markMailAsReadAsync(firstMailItemConversartionId));
                dispatch(markReadMailFromList(firstElementInMailInFolderData)); // Mark read status in reducer

            }


            // when mail is deleted from right side page refresh and set previosly selected item as currently selected item in left side
            if (!currentlyActiveMail)
            {
                const firstElementInMailInFolderData = mailsInFolder[0]?.id;
                // only send second request to fetch details of default selected mail if there are mails 
                if (firstElementInMailInFolderData)
                {
                    setCurrentlyActiveMail(firstElementInMailInFolderData)
                    dispatch(markMailAsReadAsync(firstMailItemConversartionId));
                    dispatch(markReadMailFromList(firstElementInMailInFolderData)); // Mark read status in reducer
                }
            }
            else
            {
                const doesMailExistInLeftSide = mailsInFolder.find( mailItem => mailItem.id === currentlyActiveMail);
                if(doesMailExistInLeftSide)
                {
                    if(!mailDataList){
                        setCurrentlyActiveMail(currentlyActiveMail)
                    }
                }
                else
                {
                    setCurrentlyActiveMail(firstElementInMailInFolderData)
                    dispatch(markMailAsReadAsync(firstMailItemConversartionId));
                    dispatch(markReadMailFromList(firstElementInMailInFolderData)); // Mark read status in reducer
                }
            }
        }
        else
        {
            setCurrentlyActiveMail(null)
        }
      
        // Section Starts :: handling when there are empty mails inside a folder -- 
    }, [mailsInFolder])

 





    useEffect(() => {
        if (!isEmpty(mailDataList))
        {
            const mailSubject = mailDataList[0]?.message_subject;
            setSubject(mailSubject);
            // setting recent mail as expanded by default
            const firstMailInArray = head(mailDataList);
            dispatch(expandAllMailReplayBox({[firstMailInArray.id] : true}));     
        }
    }, [mailDataList])



    // Finding folder and settings breadcrumbs for it --
    const mailFolderList = useSelector(state => state.mail.mailFolderList);
    const [breadCrumbData, setBreadCrumbData] = useState(null);
    const [pageName, setPageName] = useState(null);
    const { search } = location;
    useEffect(() => {
        if (folderId && mailFolderList && search  === '')
        {
            const folderData = mailFolderList.find(folderItem => {
                // updating page title since hook cant be called inside hook
                return folderItem.id == folderId;
            });
            document.title = folderData.pageTitle;
            setPageName(folderData.folder_name);
            setBreadCrumbData(folderData.breadCrumbs)
        }
    }, [folderId, mailFolderList, search]);




    // since inbox and compose are same components, page title needs to managed seperatly for these two
    useEffect(() => {
        if (location && mailFolderList)
        {
            const { search } = location;
            if (search)
            {
                setPageName("Compose Mail");
                document.title = `CAP - Compose Mail`;
                const inboxFolder = mailFolderList.find(folderItem => folderItem.folder_name.toLowerCase() === 'inbox');
                if(inboxFolder)
                {
                    setBreadCrumbData([
                        { name: 'Client Management', path: '/' },
                        { name: 'Mail', path: `/mail/inbox/${inboxFolder.id}?action=compose` },
                        { name: 'Compose Mail', path: '/' },
                    ])
                }
            }
        }
    }, [location, pageName, mailFolderList])

 



    // Compose Mail section starts -- 
    let inboxFolderId;
    let deleteFolderId;
    if (mailFolderList)
    {
        inboxFolderId = (mailFolderList.find(folderItem => folderItem.folder_name.toLowerCase() === 'inbox')).id;
    }
    if (mailFolderList)
    {
        deleteFolderId = (mailFolderList.find(folderItem => toLowercaseAndRemoveSpaces(folderItem.folder_name) === 'deleteditems')).id;
    }





    const [errorEmailList, setErrorEmailList] = useState([]);
    const [openEmailErrorModal, setOpenEmailErrorModal] = useState(false);
    const openEmailErrorModalHandler = (trueOrFalse) => {
        setOpenEmailErrorModal(trueOrFalse);
    }
    const emailListLength = errorEmailList.length;






    // Showing conversation replay success message, writing it in parent component since its in a loop -- 
    const sendMailReplayErrorData = useSelector(state => state.mail.sendMailReplayErrorData);
    const sendMailReplayData = useSelector(state => state.mail.sendMailReplayData);
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (sendMailReplayErrorData)
        {
            enqueueSnackbar(sendMailReplayErrorData, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                onClose: () => {
                    dispatch(clearMailReplayErrorMessage())
                }
            });
        }
        if (sendMailReplayData)
        {
            setTimeout(()=>{
                enqueueSnackbar(sendMailReplayData, {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    onClose: () => {
                        dispatch(clearMailReplaySuccessMessage())
                    }
                });
            },800)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendMailReplayErrorData, sendMailReplayData])



    // refresh left and right when draft is saved or edited and saved again
    const composeMailLoading = useSelector(state => state.mail.composeMailLoading);
    useEffect(() => {
        if (composeMailLoading === false && isEmpty(search))
        {
            let params = {
                "folder":folderId,
                "limit":dataLimit
            }
            dispatch(getMailsFromFolderAsync(params));
        }
    }, [composeMailLoading])

    // Function to fetch paginated data on scroll
    const fetchMoreData = () => {

        let params = {
            "folder":folderId,
            "limit":dataLimit,
            "offset":dataLimit+mailsInFolderOffset
        }
        if(mailsInFolderCount && mailsInFolderCount >= (dataLimit + parseInt(mailsInFolderOffset))){
            dispatch(getMailsFromFolderAsync(params));
        }
    }



    const mailsRightMaker = useCallback((mailDataList, pageName) => {
        const mailsLeftData =  mailDataList.map((conversationItem, i) => {
            const currentPageName = pageName && toLowercaseAndRemoveSpaces(pageName);
            return (
                <ConversationBox
                    key={conversationItem.id}
                    convoboxdata={conversationItem}
                    pageName={currentPageName}
                    folderId={parseInt(folderId)}
                    deleteFolderId={deleteFolderId}
                    ref={convoBoxRef}
                    composeComponentRef={composeComponentRef}
                    setCurrentlyActiveMail={setCurrentlyActiveMail}
                    openEmailErrorModalHandler={openEmailErrorModalHandler}
                    setErrorEmailList={setErrorEmailList}
                    setMessageAsDraft={setMessageAsDraft}
                    noOfMailsInFolder={mailDataList.length}
                />
            )
        });
        return mailsLeftData;
    }, [mailDataList])

    const mailsLeftMaker = useCallback((mailsInFolder, pageName) => {
        return mailsInFolder.map((mailItem, i) => {
            // switching time to show based on inbox and sent items page
            let dateAndTime;
            let dateOrTime; 
            dateAndTime = mailItem.message_recieved ? moment(mailItem.message_recieved).format('ddd MM/D/YYYY h:mm a') : moment(mailItem.message_created).format('ddd MM/D/YYYY h:mm a');
            dateOrTime = moment(dateAndTime).format('D MMM yyyy');
            if(moment(dateAndTime).isSame(moment(), 'day'))
            {
                dateOrTime = moment(dateAndTime).format('h:mm a');
            }

            let firstTwoLetters;
            let messageSenderName;
            const currentPageName = toLowercaseAndRemoveSpaces(pageName);
            const isSentItemsPage = currentPageName === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.sentItems) ?  true : false;
            const isDraftsPage = currentPageName === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.drafts) ?  true : false;
            const isOutBoxPage = currentPageName === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.outbox) ?  true : false;
            const isInboxPage = currentPageName === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.inbox) ? true :  false;

            if (pageName)
            {
                if (isSentItemsPage || isDraftsPage || isOutBoxPage)
                { 
                    // to address is shown in these folders
                    messageSenderName = mailItem.message_to ? mailItem.message_to.join(", ") : "";
                    messageSenderName = (toLowercaseAndRemoveSpaces(messageSenderName)) === "" ? "No name" : messageSenderName 
                    firstTwoLetters = letterMaker(messageSenderName)
                }
                else if(currentPageName === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.deletedItems))
                {
                    // for delete mail folder names shown should be based on what folder it is deleted from
                    const senderName = mailItem.message_sender_details.sender_name;
                    messageSenderName = (toLowercaseAndRemoveSpaces(senderName)) === "" ? "No name" : senderName 
                    firstTwoLetters = letterMaker(messageSenderName)
                    const mailDeletedFromId = mailItem.message_deleted_from;
                    const mailDeletedFrom = mailFolderList.find(folderItem => folderItem.id === mailDeletedFromId);
                    const mailDeletedFromFolderName = mailDeletedFrom?.folder_name;
                    if(mailDeletedFromFolderName)
                    {
                        const sentItemsFolderType = toLowercaseAndRemoveSpaces(appConfig.menu_static_names.sentItems);
                        const draftFolderType = toLowercaseAndRemoveSpaces(appConfig.menu_static_names.drafts);
                        const mailDeletedFromFolderNameFormatted = toLowercaseAndRemoveSpaces(mailDeletedFromFolderName);
                        if( (mailDeletedFromFolderNameFormatted == sentItemsFolderType || mailDeletedFromFolderNameFormatted == draftFolderType) )
                        {
                            const senderName = mailItem.message_to ? mailItem.message_to.join(", ") : "";
                            messageSenderName = (toLowercaseAndRemoveSpaces(senderName)) === "" ? "No name" : senderName;
                            firstTwoLetters = letterMaker(messageSenderName);
                        }
                    }
                }
                else
                {
                    // from address shown for all other folders
                    messageSenderName = mailItem.message_sender_details.sender_name;
                    messageSenderName = (toLowercaseAndRemoveSpaces(messageSenderName)) === "" ? "No name" : messageSenderName 
                    firstTwoLetters = letterMaker(messageSenderName)
                }
            }


            return (
                <div
                    key={mailItem.id}
                    className={`cursor-pointer mailItem ${mailItem.id === currentlyActiveMail ? 'active' : null}`}
                    onClick={() => {
                        if( (convoBoxRef.current && convoBoxRef.current.checkReplayDirty()) || (composeComponentRef.current && composeComponentRef.current.checkComposeMailDirty()) )
                        {
                            history.push(location.pathname, { mailItem : mailItem });
                        }
                        else
                        {
                            setCurrentlyActiveMail(mailItem.id)
                            if (mailItem.unread_count > 0)
                            {
                                dispatch(markMailAsReadAsync(mailItem.conversation_id));
                                dispatch(removeMailIdFromArray(mailItem.id))
                                dispatch(markReadMailFromList(mailItem.id)); // Mark read status in reducer
                            }
                            history.push(location.pathname);
                            if(shouldScrollToBottomClick)
                            {
                                scrollDownHandler();
                            }
                        }
                    }}
                >
                    {
                        !isSentItemsPage
                        &&
                        <div className="left">
                            <NameCircle size="32px" name={firstTwoLetters} style={{ marginRight: '0' }} />
                        </div>
                    }

                    {i == 0 && <span ref={leftViewRef}></span>}

                    <div className={`right ${!isSentItemsPage ? '' : 'extraPadding'}`}>
                        {
                            (messageSenderName && messageSenderName !== '')
                                ?
                                <h3 className={`customHeader tile ${(isInboxPage && unreadMailsIdArray.includes(mailItem.id)) ? 'bold' : ''}`}>{messageSenderName}</h3>
                                :
                                <h3 className={`customHeader tile ${(isInboxPage && unreadMailsIdArray.includes(mailItem.id)) ? 'bold' : ''}`}>No name.</h3>
                        }
                        {
                            (mailItem.message_subject && mailItem.message_subject !== '')
                                ?
                                <p className={`subtitle customSubheader ${(isInboxPage && unreadMailsIdArray.includes(mailItem.id)) ? 'bold' : ''}`}>{mailItem.message_subject}</p>
                                :
                                <p className={`subtitle customSubheader ${(isInboxPage && unreadMailsIdArray.includes(mailItem.id)) ? 'bold' : ''}`}>No subject</p>
                        }
                        <p className="caption customDescription">
                            {(mailItem.message_body_preview && mailItem.message_body_preview !== '') && getTrimmedString(mailItem.message_body_preview, 30)}
                        </p>
                        <p className="absolute time">
                            {dateOrTime}
                        </p>
                        {
                            (!unreadMailsIdArray.includes(mailItem.id))
                            &&
                            <p className="absolute readIndicator">
                                <DoneAllIcon className="icon" />
                            </p>
                        }
                        {
                            (isInboxPage && unreadMailsIdArray.includes(mailItem.id)) && <p className="absolute unreadCount">{mailItem.unread_count}</p>
                        }
                    </div>
                </div>
            )
        })
    }, [mailsInFolder,currentlyActiveMail])

    // Fetch selected mail details
    useEffect(()=>{
        if(currentlyActiveMail){
            dispatch(getMailDetailsAsync(currentlyActiveMail));
        }else{
            dispatch(removeMailDetails());
        }
    },[currentlyActiveMail])


    const attachmentSizeExceededMessage  = `Attachment size exceeded (Max. ${bytesToSize(maxFileAttachmentSize)})`;


    return (
        <Layout padding={2}>

            <PageWrapper
                breadCrumbData={breadCrumbData}
                heading={pageName}
                className="mailViewPage"
            >
                {
                    <div className="mailPage globalMailPage customScrollbar">
                        <Paper className="mailWrapper">

                            {/* This area was removed and will be done later, kept like this to keep view correct according to VD */}
                            <div className="pageOptions">
                                <Grid container>
                                    <Grid item xs={12} sm={5} md={4}>
                                        <div className="left">
                                            {/* <CheckIcon className="icon" /> */}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={7} md={8}>
                                        <div className="right">

                                        </div>
                                    </Grid>
                                </Grid>
                            </div>

                            <Grid container className="mailInnerWrapper">

                                {/* Mails listing side  */}
                                <Grid item xs={12} sm={5} md={4}>
                                <div
                                    id="mailsLeftWrapper"
                                    className={`mailLeft customScrollbar
                                        ${!(mailsInFolder && mailsInFolder.length > 0) ? 'center-stuff' : ''}
                                    `}
                                >
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={()=>{fetchMoreData()}}
                                        hasMore={true}
                                        loader={<>{mailsInFolderLoading && <CircularLoader size={20} className="mailLoader"/>}</>}
                                        useWindow={false}
                                        threshold={200}
                                        initialLoad={false}
                                    >
                                        {
                                            (mailsInFolder && mailsInFolder.length > 0 && pageName) ? (
                                                mailsLeftMaker(mailsInFolder, pageName)
                                            ) : (
                                                <>
                                                {mailsInFolderError ?
                                                    <p>{mailsInFolderError}</p>
                                                    :
                                                    mailsInFolderLoading ? <NoResults message={"Loading..."}/> : <NoResults />
                                                }
                                                </>
                                            )
                                        }
                                    </InfiniteScroll>
                                </div>
                                </Grid>



                                {/* Compose mail View  */}
                                {
                                    isComposeShown
                                        ?
                                        (
                                            <Grid
                                                item xs={12} sm={7} md={8}
                                                style={{ background: '#f0f0f0' }}
                                                container
                                            >
                                                <div
                                                    className={`mailRight ${isComposeMailFormAuthorized ? '' : 'mailRightWidthFull'}`}
                                                >
                                                    {
                                                        isComposeMailFormAuthorized
                                                        ?
                                                        <ComposeMailForm
                                                            openEmailErrorModalHandler={openEmailErrorModalHandler}
                                                            setErrorEmailList={setErrorEmailList}
                                                            setMessageAsDraft={setMessageAsDraft}
                                                        />
                                                        :
                                                        <NotAuthorizedMessage centered>{appConfig.messages.not_authorized_section_message}</NotAuthorizedMessage>
                                                    }
                                                    
                                                </div>
                                            </Grid>
                                        )
                                        :
                                        (
                                            <Grid item xs={12} sm={7} md={8}>
                                                <div
                                                    className={`mailRight customScrollbar ${!(mailDataList && mailDataList.length > 0) ? 'center-stuff' : ''}`}
                                                >


                                                    {
                                                        ( mailDataListLoading )
                                                            ?
                                                            <div className="loader innerLoader">
                                                                <CircularLoader size={20} />
                                                            </div>
                                                            :
                                                            <>
                                                                {
                                                                    mailDataList && mailDataList.length > 0 ?
                                                                        (
                                                                            <>
                                                                                <div className="convoHead">
                                                                                    {mailSubject ? <span>{mailSubject}</span> : <span>No subject</span>}
                                                                                    <span className="flexItem--mod" onClick={() => expandAllConvoBoxes(mailDataList, expandedItems, dispatch)}>
                                                                                        {isEmpty(expandedItems) ? (
                                                                                            <AddCircleOutlineIcon className="icon cursor-pointer" />
                                                                                        ) : (
                                                                                            <RemoveCircleOutlineIcon className="icon cursor-pointer" />
                                                                                        )}
                                                                                    </span>
                                                                                </div>

                                                                                <div className="convoContent">
                                                                                    {
                                                                                        mailsRightMaker(mailDataList, pageName)
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        )
                                                                        :
                                                                        <>
                                                                            {mailDataListError ?
                                                                                <p>{mailDataListError}</p>
                                                                                :
                                                                                mailsInFolderLoading ? <NoResults message={"Loading..."}/> : <NoResults />
                                                                            }
                                                                        </>
                                                                }
                                                            </>
                                                    }
                                                </div>
                                            </Grid>
                                        )
                                }
                                {/* Conversation listing side  */}


                            </Grid>

                        </Paper>
                    </div>
                }
            </PageWrapper>



            

            {/* Attachment Modal */}
            <FileAttachmentModalComponent
                open={isAttachmentModalOpen}
                handleClose={()=> dispatch(openCloseAttachmentModal(false))}
                size="sm"
                fileSizeErrorMessage={attachmentSizeExceededMessage}
                maxSize={maxFileAttachmentSize} // 4 MB
            />


            {/* Email error Modal */}
            <Modal open={openEmailErrorModal} handleClose={() => openEmailErrorModalHandler(false)} size="xs" className="emailError">
                <DialogContent>


                    {
                        errorEmailList.toErrors && errorEmailList.toErrors.length > 0 &&
                        <>
                            <h3>Error</h3>
                            <p>Some address : "{errorEmailList.toErrors.map((errorEmailItem, i) => {
                                return <strong>{`${errorEmailItem}${i === (errorEmailList.toErrors.length - 1) ? '' : ', '}`}</strong>
                            })}" in <strong>"to"</strong> field was not recognized. Please make sure all addresses are properly formed.</p>
                        </>
                    }

                    {
                        errorEmailList.ccErrors && errorEmailList.ccErrors.length > 0 &&
                        
                        <>
                            <p>Some address : "{errorEmailList.ccErrors.map((errorEmailItem, i) => {
                                return <strong>{`${errorEmailItem}${i === (errorEmailList.ccErrors.length - 1) ? '' : ', '}`}</strong>
                            })}" in <strong>"cc"</strong> field was not recognized. Please make sure all addresses are properly formed.</p>
                        </>
                    }

                    {
                        errorEmailList.bccErrors && errorEmailList.bccErrors.length > 0 &&
                        <>
                            <p>Some address : "{errorEmailList.bccErrors.map((errorEmailItem, i) => {
                                return <strong>{`${errorEmailItem}${i === (errorEmailList.bccErrors.length - 1) ? '' : ', '}`}</strong>
                            })}" in <strong>"bcc"</strong> field was not recognized. Please make sure all addresses are properly formed.</p>
                        </>
                    }

                    
                    
                    
                    
                    <DialogActions className="modalFooter">
                        <Button onClick={() => openEmailErrorModalHandler(false)} color="primary" autoFocus bgColor="secondary" width='150px' >
                            Ok
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Modal>
        </Layout>
    )
}

export default MailView;




