import React, { useRef, useState, useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Grid, CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import { FastField, Form, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import Modal from '../Modal/Modal';
import messageSendIcon from "../../assets/icons/profileManagementIcons/sendMessageIcon.svg"
import Input from '../Input/Input';
import moment from 'moment';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import CircleIcon from '@mui/icons-material/Circle';
import {  axiosPost,} from '../../utils/api';
import { useSnackbar } from 'notistack';

const noteValidation = Yup.object().shape({
  note: Yup.string().required('Please add a note before submitting.')
});
const useStyles = makeStyles({
  timelineItem: {
    '&:before': {
      display: 'none', // Hides the pseudo-element completely
    },
  },
});
// {
//   "status_code": "200",
//   "status": true,
//   "message": "Meetings fetched successfully",
//   "data": [
//       {
//           "id": 1,
//           "client": {
//               "id": 1008,
//               "user": {
//                   "id": 1119,
//                   "username": "roshsigh@gmail.com",
//                   "first_name": "Rosh",
//                   "last_name": "Sigh",
//                   "email": "roshsigh@gmail.com",
//                   "phone_number": "1223457898",
//                   "user_type": "Client"
//               },
//               "phone_number": "1223457898",
//               "referred_by_id": null,
//               "referred_date": null,
//               "company": "TEST COMPANY",
//               "related_company": "WIRTUAL",
//               "enable_cold_calling": false,
//               "referred_user_first_name": null,
//               "referred_user_last_name": null,
//               "referred_user_email": null,
//               "referred_user_phone_number": "",
//               "company_id": 3,
//               "related_company_id": 3,
//               "net_worth": "0.00",
//               "pre_contract_percent": 20,
//               "atp_percent": 0,
//               "post_contract_percent": 0,
//               "is_confirmed_client": false,
//               "created_by": 8,
//               "current_task_id": null,
//               "is_survey_updated": false,
//               "advisor": "Amal Mohanan",
//               "ni_number": null,
//               "is_existing": true,
//               "is_new_client": false,
//               "is_Exist_FeeNotManaged": false,
//               "is_advisor_agreed": false,
//               "client_type": "new",
//               "joint_client": false,
//               "relative_client": null,
//               "parallelly_added": false,
//               "aum": 0,
//               "social_mediums": [],
//               "is_shadow_client": false,
//               "surveyform_percentage_avg": 0,
//               "relative_client_name": null,
//               "old_advisor": null
//           },
//           "organizer": {
//               "id": 25,
//               "user": {
//                   "id": 281,
//                   "username": "amal.mohanan.superadmin",
//                   "first_name": "Amal",
//                   "last_name": "Super Admin",
//                   "email": "amal.superadmin@test.com",
//                   "phone_number": "",
//                   "user_type": "SuperAdmin"
//               },
//               "company": "WIRTUAL"
//           },
//           "meeting_date": "2023-10-10T10:00:00Z",
//           "meeting_time": "10:00:00",
//           "meeting_notes": [],
//           "meeting_type_display": "In Person",
//           "meeting_type": "1",
//           "attachments": []
//       },
//       {
//           "id": 2,
//           "client": {
//               "id": 489,
//               "user": {
//                   "id": 589,
//                   "username": "firstclient78@gmail.com",
//                   "first_name": "Firstclient",
//                   "last_name": "L",
//                   "email": "firstclient78@gmail.com",
//                   "phone_number": "8765467495",
//                   "user_type": "Client"
//               },
//               "phone_number": "8765467495",
//               "referred_by_id": null,
//               "referred_date": null,
//               "company": "TEST",
//               "related_company": null,
//               "enable_cold_calling": false,
//               "referred_user_first_name": null,
//               "referred_user_last_name": null,
//               "referred_user_email": null,
//               "referred_user_phone_number": "",
//               "company_id": 24,
//               "related_company_id": 24,
//               "net_worth": "0.00",
//               "pre_contract_percent": 10,
//               "atp_percent": 0,
//               "post_contract_percent": 0,
//               "is_confirmed_client": false,
//               "created_by": 4,
//               "current_task_id": null,
//               "is_survey_updated": false,
//               "advisor": "Sakthikumar M",
//               "ni_number": null,
//               "is_existing": true,
//               "is_new_client": false,
//               "is_Exist_FeeNotManaged": false,
//               "is_advisor_agreed": false,
//               "client_type": "Gold",
//               "joint_client": false,
//               "relative_client": 488,
//               "parallelly_added": true,
//               "aum": 0,
//               "social_mediums": [],
//               "is_shadow_client": true,
//               "surveyform_percentage_avg": 0,
//               "relative_client_name": "Firstpartner Pl",
//               "old_advisor": null
//           },
//           "organizer": {
//               "id": 32,
//               "user": {
//                   "id": 322,
//                   "username": "aditya@gmail.com",
//                   "first_name": "aditya",
//                   "last_name": "lst",
//                   "email": "aditya@gmail.com",
//                   "phone_number": "",
//                   "user_type": "Advisor"
//               },
//               "company": "WIRTUAL"
//           },
//           "meeting_date": "2025-02-06T16:00:00Z",
//           "meeting_time": "16:00:26",
//           "meeting_notes": [
//               {
//                   "id": 2,
//                   "meeting": 2,
//                   "note": "Hello"
//               }
//           ],
//           "meeting_type_display": "Phone Call",
//           "meeting_type": "2",
//           "attachments": []
//       },
//       {
//           "id": 3,
//           "client": {
//               "id": 575,
//               "user": {
//                   "id": 675,
//                   "username": "parallelone@gmail.com",
//                   "first_name": "Parallel",
//                   "last_name": "Oneeu",
//                   "email": "parallelone@gmail.com",
//                   "phone_number": "8967243252",
//                   "user_type": "Client"
//               },
//               "phone_number": "8967243252",
//               "referred_by_id": null,
//               "referred_date": null,
//               "company": "TTHHGH",
//               "related_company": null,
//               "enable_cold_calling": true,
//               "referred_user_first_name": null,
//               "referred_user_last_name": null,
//               "referred_user_email": null,
//               "referred_user_phone_number": "",
//               "company_id": 23,
//               "related_company_id": 23,
//               "net_worth": "0.00",
//               "pre_contract_percent": 40,
//               "atp_percent": 0,
//               "post_contract_percent": 0,
//               "is_confirmed_client": false,
//               "created_by": 4,
//               "current_task_id": null,
//               "is_survey_updated": true,
//               "advisor": "Sakthikumar M",
//               "ni_number": null,
//               "is_existing": true,
//               "is_new_client": false,
//               "is_Exist_FeeNotManaged": false,
//               "is_advisor_agreed": false,
//               "client_type": "Gold",
//               "joint_client": false,
//               "relative_client": 574,
//               "parallelly_added": true,
//               "aum": 0,
//               "social_mediums": [],
//               "is_shadow_client": true,
//               "surveyform_percentage_avg": 0,
//               "relative_client_name": "Parallel Twooo",
//               "old_advisor": null
//           },
//           "organizer": {
//               "id": 6,
//               "user": {
//                   "id": 9,
//                   "username": "ajay.jayakumar@giglabz.com",
//                   "first_name": "Ajay",
//                   "last_name": "Jayakumar",
//                   "email": "amal.mohanan@giglabz.com",
//                   "phone_number": "",
//                   "user_type": "Advisor"
//               },
//               "company": "WIRTUAL"
//           },
//           "meeting_date": "2025-02-07T10:53:00Z",
//           "meeting_time": "10:53:01",
//           "meeting_notes": [
//               {
//                   "id": 3,
//                   "meeting": 3,
//                   "note": "Hello"
//               }
//           ],
//           "meeting_type_display": "Phone Call",
//           "meeting_type": "2",
//           "attachments": []
//       }
//   ]
// }
function Notes({isNoteAdded,selectedList,meetingId,getNotes,open,setOpenNotes}) {
  const classes=useStyles();
  const {enqueueSnackbar}=useSnackbar();
  const [isNotesAdding,setIsNotesAdding]=useState(false)
  const formikRef = useRef();

//   useEffect(() => {
//     if (commentModel) {
//       getComments(meetingId);
//     }
//   }, [commentModel, meetingId]);

  const handleModalClose = () => {
    setOpenNotes(false);
  };
  const add_a_note=async (note)=>{
    try{
      setIsNotesAdding(true)
      const newFormData=new FormData()
      newFormData.append("note",note)
      newFormData.append(selectedList?.event_flag?"event_id":"meeting_id" , selectedList?.id)
      const data = await axiosPost(`meetingnotes/`, newFormData,{},true);
      if (data.status) {
        enqueueSnackbar(data?.data?.message || "Note Added", {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        })
        getNotes();
        formikRef.current.resetForm();
      }
      setIsNotesAdding(false)
    }
    catch(e){
      enqueueSnackbar(e?.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      })
      setIsNotesAdding(false)
    }
  }

 

  return (
    <Modal
      className="customModal"
      open={open}
      handleClose={handleModalClose}
      size="sm"
      titleBlocking
    >
      <DialogTitle
       style={{
          position: 'sticky',
          top: 0,
          left: 0,
          padding: '10px',
          backgroundColor: 'white',
          zIndex: 1000,
          display:"flex",
          justifyContent:"space-between",
          alignItems:"center"
        }}
      >
       <span>Note</span>
       <CloseIcon style={{color:"#686868"}} className="viewCalendarEventModalIcon closeIcon cursor-pointer" onClick={handleModalClose} />
      </DialogTitle>
      <DialogContent className="descriptionContent">
      <Timeline style={{ padding: 0 }}>
          {isNoteAdded ? (
            <CircularProgress size="1.5rem" style={{ color: '#32c2d9' }} />
          ) : (selectedList && selectedList?.meeting_notes?.length > 0 )? (
            selectedList?.meeting_notes?.map((note, index) => (
              <TimelineItem key={index} className={classes.timelineItem}>
              <div
                    style={{
                      width: '35%',
                      fontSize: '12px',
                      color: '#888',
                      paddingRight: '10px',
                      display:"flex",
                      flexDirection:"column",
                      fontSize:"14px"
                    }}
                  >
                   <span>{ moment(note?.create_time).format("DD-MM-YYYY")}</span> 
                    <span>{moment(note?.create_time).format('hh:mm A')}</span>
                  </div>
                <TimelineSeparator>
                    <CircleIcon style={{ color: '#32c2d9', margin:"3px"}}  />
                  {index < selectedList?.meeting_notes?.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent style={{ display: 'flex', flexDirection: 'row' }}>
                  <Stack
                    direction="column"
                    spacing={1}
                    style={{
                      backgroundColor: '#f9f9f9',
                      borderRadius: '8px',
                      padding: '10px',
                      flex: 1
                    }}
                  >
                    <div style={{ fontSize: '14px' }}>{note.note}</div>
                  </Stack>
                </TimelineContent>
              </TimelineItem>
            ))
          ) : (
            <div className="noNotes">No Notes Added</div>
          )}
        </Timeline>
      </DialogContent>
      <div
        style={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'white',
          padding: '10px',
          boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
          zIndex: 1000
        }}
      >
        <Formik
          innerRef={formikRef}
          initialValues={{ note: '' }}
          validationSchema={noteValidation}
          onSubmit={(values) => {
            if (values.note) {
              add_a_note(values.note);
            }
          }}
        >
          <Form>
            <Grid container  alignItems='center' >
              <Grid item xs={11}>
                 <FastField
               component={Input}
               placeholder="Write a note..."
               label="Note"
               name="note"
               size="small"
               className=""
               multiline={true}
               stripTags={true}
               inputLabelStyle={false}
               convertToCase="sentenceCase"
             />
              </Grid>
              <Grid item xs={1}>
              <IconButton type='submit' >
              {isNotesAdding?<CircularProgress size="1rem" style={{ color: '#32c2d9' }} />:<img src={messageSendIcon}/>}
              </IconButton>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
}

export default Notes;
