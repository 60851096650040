import React, { useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import moment from 'moment';
import AttachmentIcon from '@material-ui/icons/Attachment';
import Button from '../../Button/Button';
import Composer from '../../Composer/Composer';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import MultiSelect from '../../MultiSelect/MultiSelect';
import Grid from '@material-ui/core/Grid';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ReplyIcon from '@material-ui/icons/Reply';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import { sendMailReplayAsync, getMailDetailsAsync, searchMailRecipientsAsync, removeMailDetails } from '../../../redux/mail/mail.action';
import NameCircle from '../../NameCircle/NameCircle';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { removeIndivigualFileItems, openCloseAttachmentModal } from '../../../redux/ui/ui.actions';
import { expandCollapseMailReplayBox, moveMailToFolderAsync, removeMailSuccessMessage, fetchMailTemplatesAsync } from '../../../redux/mail/mail.action';
import { replayMailSchema, emailCheckerSchema } from '../../../schemas/mailSchema';
import NavigationPrompt from '../../NavigationPromt/NavigationPromt';
import Modal from '../../Modal/Modal';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { toLowercaseAndRemoveSpaces, uniqueIdObjectMaker, getTrimmedString, hasTotalAttachmentsSizeCrossed, checkPermissions } from '../../../utils/utils';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ComposeMailForm from '../ComposeMailForm/ComposeMailForm';
import FileAttachmentPreview from '../../FileAttachmentPreview/FileAttachmentPreview';
import appConfig from '../../../appConfig.json';
import { letterMaker } from '../MailView/MailView';
import { MAIL_SENDING_ACTIONS } from '../../../utils/constants';
import { useLocation, useHistory } from "react-router-dom";
import { bytesToSize } from '../../../utils/utils';
import cheerio from 'cheerio';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import './ConversationBox.scss';





const toolbarItemsData = ['heading', 'bold', 'Strike', 'Subscript', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote',
    'Undo', 'Redo', 'Underline', 'RemoveFormat', 'CopyFormatting', 'JustifyLeft'];


const handleExpandCollapse = (id, dispatch) => {
    dispatch(expandCollapseMailReplayBox(id));
}



const replayMailFormatter = (editor, currentlyLoggedUserName, previousMailContent) => {
    editor.editing.view.focus();
}


const convoHeaderPrinter = (convoboxdata, isShown) => {

    const senderName = isShown ? convoboxdata.message_sender_details.sender_name : getTrimmedString(convoboxdata.message_sender_details.sender_name, 57);
    const senderFrom = toLowercaseAndRemoveSpaces(convoboxdata.message_sender_details.sender_mail) === toLowercaseAndRemoveSpaces(convoboxdata.message_sender_details.sender_name) ? null : convoboxdata.message_sender_details.sender_mail;
    return (
        <>
            {
                toLowercaseAndRemoveSpaces(senderName) === "" ? "No name" : senderName
            }

            {/* Show sender from address when expanded and if sendername and senderfrom are different */}
            {
                senderFrom && isShown &&
                <span className="fromAddress">
                    {
                        ` <${senderFrom}>`
                    }
                </span>
            }

        </>
    )
}




const attachmentsTotalFileSizeFinder = (attachments) => {
    const driveFileItems = [];
    let newDriveItemsFileSize = 0;
    const localFileAttachments = [];
    let newLocalAttachmentFileSize = 0;
    attachments.forEach(fileItem => {
        if (fileItem instanceof File)
        {
            localFileAttachments.push(fileItem);
            newLocalAttachmentFileSize += fileItem.size;
        }
        else
        {
            driveFileItems.push(fileItem);
            newDriveItemsFileSize += fileItem.item_size;
        }
    })
    const totalSize = newDriveItemsFileSize + newLocalAttachmentFileSize;
    return totalSize;
}


const CoversationBox = forwardRef(( {
    convoboxdata, pageName, folderId, deleteFolderId, noOfMailsInFolder, // normal props
    setTemplateData,
    templateData,
    openEmailErrorModalHandler,
    setErrorEmailList,
    setOpenPreviewModal,
    setMessageAsDraft,
    composeComponentRef,
    setCurrentlyActiveMail
}, ref )=> {

    const history = useHistory();
    const location = useLocation();
    const replayMailRef = useRef();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(removeIndivigualFileItems([]))
        return () => {
            dispatch(removeIndivigualFileItems([]))
        }
    }, [])

    const currentUserData = useSelector(state => state.auth.currentUserData);
    let isComposeMailFormAuthorized = false;
    let isDeleteMailAuthorized = false;
    if(!isEmpty(currentUserData))
    {
        isComposeMailFormAuthorized = checkPermissions(currentUserData.permission_list,['COMPOSE-MAIL'])
        isDeleteMailAuthorized = checkPermissions(currentUserData.permission_list,['DELETE-MAIL'])
    }

    



    const searchMailRecipientsData = useSelector(state => state.mail.searchMailRecipientsData);
    const isSearchMailReceiptLoading = useSelector(state => state.mail.isSearchMailReceiptLoading);
    const sendMailReplayLoading = useSelector(state => state.mail.sendMailReplayLoading);
    const composeMailLoading = useSelector(state => state.mail.composeMailLoading);
    const currentUserEmail = useSelector(state => state.auth.currentUserData.email);
    const mailFolderList = useSelector(state => state.mail.mailFolderList);


    const [replayRecipient, setReplayRecipient] = useState(null);
    const [replayAllRecipient, setReplayAllRecipient] = useState(null);
    const [forwardMailData, setForwardMailData] = useState(null);
    const [isReplayView, setIsReplayView] = useState(false);
    const [composeMailView, setComposeMailView] = useState(false);
    const [isDiscardModalShown, setIsDiscardModalShown] = useState(false);




    let messageSenderName = convoboxdata.message_sender_details.sender_name;
    messageSenderName = toLowercaseAndRemoveSpaces(messageSenderName) === "" ? "No name " : messageSenderName; // 
    const firstTwoLetters = letterMaker(messageSenderName);



    let dateAndTime = convoboxdata.message_recieved ? moment(convoboxdata.message_recieved).format('ddd DD/MM/YYYY h:mm a') : moment(convoboxdata.message_created).format('ddd DD/MM/YYYY h:mm a');



    const id = convoboxdata.id;
    const currentlyLoggedUserId = useSelector(state => state.auth.currentUserData.id);
    const currentlyLoggedUserEmail = useSelector(state => state.auth.currentUserData.email);
    const currentlyLoggedUserName = `${useSelector(state => state.auth.currentUserData.first_name)} ${useSelector(state => state.auth.currentUserData.last_name)}`;
    const [isCCShown, setIsCCShown] = useState(false);
    const [isBCCShown, setIsBCCShown] = useState(false);
    let filesSelectedFromAttachmentModal = useSelector(state => state.ui.filesSelectedFromAttachmentModal);
    const filesSelectedFromAttachmentModalWithActionName = useSelector(state => state.ui.filesSelectedFromAttachmentModalWithActionName);
    const expandedItems = useSelector(state => state.mail.expandedItems);
    const isShown = expandedItems[id] ? true : false;
    const dataLimit = 10;
    const mailsInFolderCount = useSelector(state => state.mail.mailsInFolderCount);
    const mailsInFolderOffset = useSelector(state => state.mail.mailsInFolderOffset);
    
    const replayAllSetter = useCallback(() => {

        if (!isComposeMailFormAuthorized)
        {
            enqueueSnackbar(appConfig.messages.not_authorized_action_message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
            return;
        }


        dispatch(removeIndivigualFileItems([]))
        setForwardMailData(null);
        setReplayRecipient(null);

        const replayMessageBody = convoboxdata.message_body;
        const subject = `Re All : ${convoboxdata.message_subject}`;


        // if replay all combine cc, bcc, to and sender to one single array and spread it inside array of formik to field
        const replayTo = convoboxdata.message_to || [];
        const messageCC = convoboxdata.message_cc || [];
        const messageBcc = convoboxdata.message_bcc || [];

        const messageCCTransformedForMultiSelect = uniqueIdObjectMaker(messageCC, 'searchRecipientName');


        const messageBccTransformedForMultiSelect = uniqueIdObjectMaker(messageBcc, 'searchRecipientName');
        const messageToTransformedForMultiSelect = uniqueIdObjectMaker(replayTo, 'searchRecipientName');
        const messageSenderToArray = convoboxdata.message_sender ? uniqueIdObjectMaker([convoboxdata.message_sender], 'searchRecipientName') : [];
        // Note :: to do :: if currently logged in user , remove bcc from this list , people in to and cc should not have bcc in the list
        const allItemsCombined = [
            ...messageCCTransformedForMultiSelect,
            ...messageBccTransformedForMultiSelect,
            ...messageToTransformedForMultiSelect,
            ...messageSenderToArray
        ];
        const allItemsCombinedWithOutCurrentUser = allItemsCombined.filter(item => item.searchRecipientName !== currentlyLoggedUserEmail);

        setReplayAllRecipient({
            to: allItemsCombinedWithOutCurrentUser,
            replayMessageBody: '', // removed as composer was having issues
            subject: subject,
            action: MAIL_SENDING_ACTIONS[2].id,
            reply_id: convoboxdata.object_id
        });

        setIsReplayView(true);
    }, [convoboxdata])

    const replaySetter = useCallback(() => {


        if (!isComposeMailFormAuthorized)
        {
            enqueueSnackbar(appConfig.messages.not_authorized_action_message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
            return;
        }


        dispatch(removeIndivigualFileItems([]))
        setReplayAllRecipient(null);
        setForwardMailData(null);
        const replayTo = convoboxdata.message_sender;
        const messageToArray = convoboxdata.message_to || [];
        let messageToTransformedForMultiSelect;

        messageToTransformedForMultiSelect = replayTo ? uniqueIdObjectMaker([replayTo], 'searchRecipientName') : [];
        let currentUserRemoved = messageToTransformedForMultiSelect.filter(item => item.searchRecipientName !== currentlyLoggedUserEmail);

        // exeptional case if toaddress has only one person and if that person is not curretly signed in user set him in to field
        if ((convoboxdata.message_to.includes(currentlyLoggedUserEmail)) === false && convoboxdata.message_to.length === 1)
        {
            currentUserRemoved = uniqueIdObjectMaker([convoboxdata.message_to], 'searchRecipientName')
        }


        const replayMessageBody = convoboxdata.message_body;
        const subject = `Re : ${convoboxdata.message_subject}`;


        
        setReplayRecipient({
            to: currentUserRemoved,
            replayMessageBody: '',// removed as composer was having issues
            subject: subject,
            action: MAIL_SENDING_ACTIONS[1].id,
            reply_id: convoboxdata.object_id
        });

        setIsReplayView(true);
    }, [convoboxdata])

    const forwardSetter = useCallback(() => {

        if (!isComposeMailFormAuthorized)
        {
            enqueueSnackbar(appConfig.messages.not_authorized_action_message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
            return;
        }


        dispatch(removeIndivigualFileItems([]))
        setReplayRecipient(null);
        setReplayAllRecipient(null);

        const replayMessageBody = convoboxdata.message_body;
        const subject = `Fwd : ${convoboxdata.message_subject}`;

        setForwardMailData({
            to: [],
            replayMessageBody: '',
            subject: subject,
            action: MAIL_SENDING_ACTIONS[3].id,
            reply_id: convoboxdata.object_id,
            attachments: convoboxdata.attachment_list
        });
        setIsReplayView(true);
    }, [convoboxdata])

    const replaySender = async (values, resetForm, mailType, isInitiatedFromOutSideClickModal=false) => {

        const isMessageDraft = mailType === 'draft' ? true : false;

        // transform cc and bcc list to simple array 
        const transformedCC = values.cc.map(ccItem => ccItem.searchRecipientName);
        const transformedBcc = values.bcc.map(bccItem => bccItem.searchRecipientName);
        const transformedTo = values.to.map(toItem => toItem.searchRecipientName);


        // check if anyofthe email entered is error and show popup else submit form
        const toErrors = [];
        const ccErrors = [];
        const bccErrors = [];
        const isToValid = await Promise.all(transformedTo.map(async (emailItem) => {
            const isItemValid = await emailCheckerSchema.isValid({ emailItem: emailItem });
            if (!isItemValid)
            {
                toErrors.push(emailItem);
            }
            return emailCheckerSchema.isValid({ emailItem: emailItem })
        }))
        const isCcEmailsValid = await Promise.all(transformedCC.map(async (emailItem) => {
            const isItemValid = await emailCheckerSchema.isValid({ emailItem: emailItem });
            if (!isItemValid)
            {
                ccErrors.push(emailItem);
            }
            return emailCheckerSchema.isValid({ emailItem: emailItem })
        }))
        const isBccEmailsValid = await Promise.all(transformedBcc.map(async (emailItem) => {
            const isItemValid = await emailCheckerSchema.isValid({ emailItem: emailItem });
            if (!isItemValid)
            {
                bccErrors.push(emailItem);
            }
            return emailCheckerSchema.isValid({ emailItem: emailItem })
        }))
        const areAllToEmailsValid = isToValid.every(Boolean);
        const areAllCCEmailsValid = isCcEmailsValid.every(Boolean);
        const areAllBccEmailsValid = isBccEmailsValid.every(Boolean);
        const errors = {
            "toErrors": toErrors,
            "ccErrors": ccErrors,
            "bccErrors": bccErrors
        }


        if (!areAllToEmailsValid || !areAllCCEmailsValid || !areAllBccEmailsValid)
        {
            openEmailErrorModalHandler(true);
            setErrorEmailList(errors);
            if (replayMailRef.current)
            {
                const { setSubmitting } = replayMailRef.current;
                setSubmitting(false);
            }
        }
        else
        {

            const formData = new FormData();

            // filter out local files and cap drive files 

            let alreadyExistingAttachments;
            if (convoboxdata.attachment_list)
            {
                alreadyExistingAttachments = convoboxdata.attachment_list.map(item => item.fileId);
            }
            else
            {
                alreadyExistingAttachments = [];
            }


            let localFileAttachments = [];
            let driveFiles = [];



            values.attachments.forEach(item => {
                if (item instanceof File)
                {
                    localFileAttachments.push(item)
                }
                else
                {
                    driveFiles.push(item.fileId)
                }
            })
            const hasAttachments = (localFileAttachments.length > 0 || driveFiles.length > 0) ? true : false;

            localFileAttachments.forEach(fileItem => {
                formData.append('attachments', fileItem, fileItem.name);
            })


            formData.append('has_attachments', hasAttachments);
            driveFiles.forEach(fileItem => {
                if (alreadyExistingAttachments.includes(fileItem))
                {
                    formData.append('file_id_list', fileItem);
                }
                else
                {
                    formData.append('cap_drive_attachments', fileItem);
                }
            })



            formData.append('user', currentlyLoggedUserId);
            formData.append('message_sender', currentUserEmail);
            transformedTo.forEach(toItem => {
                formData.append('message_to', toItem);
            })
            transformedCC.forEach(ccItem => {
                formData.append('message_cc', ccItem);
            })
            transformedBcc.forEach(bccItem => {
                formData.append('message_bcc', bccItem);
            })
            formData.append('message_reply_to', currentUserEmail);
            formData.append('conversation_id', convoboxdata.conversation_id);
            formData.append('message_body', values.replayMessageBody);
            formData.append('message_subject', convoboxdata.message_subject);

            formData.append('message_is_draft', isMessageDraft);
            formData.append('mail_action', values.action);
            formData.append('reply_id', values.reply_id);
            setHasSubmitCompleted(true);


            // convoboxdata.id (mailId) is sent for refreshing data right after success in service itself
            dispatch(sendMailReplayAsync(formData, {
                mailId : convoboxdata.id,
                isInitiatedFromOutSideClickModal : isInitiatedFromOutSideClickModal
            }));

        }



    }

    useEffect(() => {
        if (replayMailRef.current  && sendMailReplayLoading === false)
        {
            const { setSubmitting } = replayMailRef.current;
            setSubmitting(false);
            setIsReplayView(false);
            setIsDiscardModalShown(false);
            setHasSubmitCompleted(false);
        }
    }, [sendMailReplayLoading])

    // Code to clear mail details view if the last item deleted
    useEffect(()=>{
        if(mailsInFolderCount === 0){
            dispatch(removeMailDetails());
        }
    },[mailsInFolderCount])


    const [hasSubmitCompleted, setHasSubmitCompleted] = useState(false);
    const [editorFunction, setEditorFunction] = useState(null);
    // const totalAttachmentFileSize = useSelector(state => state.ui.totalAttachmentFileSize);

    const maxFileAttachmentSize = appConfig.document_upload.mail_attachments.maxSize;
    const attachmentSizeExceededMessage = `Attachment size exceeded (Max. ${bytesToSize(maxFileAttachmentSize)})`;
    const attachmentLabelMsg = `(Max file size is ${bytesToSize(maxFileAttachmentSize)})`;

    // --- Starts  : section only applicable in case of drafts, preparing initial data data for draft compose form component  -- 
    const initialDataForDrafts = () => {
        if (convoboxdata.message_is_draft)
        {
            const messageTo = (convoboxdata.message_to && convoboxdata.message_to.length > 0) ? uniqueIdObjectMaker([...convoboxdata.message_to], 'searchRecipientName') : []
            const messageCC = (convoboxdata.message_cc && convoboxdata.message_cc.length > 0) ? uniqueIdObjectMaker([...convoboxdata.message_cc], 'searchRecipientName') : []
            const messageBcc = (convoboxdata.message_bcc && convoboxdata.message_bcc.length > 0) ? uniqueIdObjectMaker([...convoboxdata.message_bcc], 'searchRecipientName') : []

            let messageBody;
            // fetch and set template only if draft has template selected
            if (convoboxdata.template !== null)
            {
                messageBody = convoboxdata.template_content ? convoboxdata.template_content : '';
            }
            else
            {
                messageBody = convoboxdata.message_body ? convoboxdata.message_body : '';
            }

            
            const subject = convoboxdata.message_subject ? convoboxdata.message_subject : '';
            const initialData = {
                to: messageTo,
                category: convoboxdata.category ? convoboxdata.category : '',
                template: convoboxdata.template ? convoboxdata.template : '',
                message_subject: subject,
                cc: messageCC,
                bcc: messageBcc,
                messageBody: messageBody,
                attachments: convoboxdata.attachment_list,
                templateHeader: convoboxdata.template_header,
                templateFooter: convoboxdata.template_footer,
                messageIsDraft :convoboxdata.message_is_draft,
                replay_id : convoboxdata.reply_id
            };

            // fetch and set template only if draft has template selected
            if (convoboxdata.template !== null)
            {
                dispatch(fetchMailTemplatesAsync(convoboxdata.category))
            }
            return initialData;
        }

    }
    // --- Ends  : section only applicable in case of drafts, preparing initial data data for draft compose form component  -- 


    const classGenerator = useCallback(() => {
        return `conversationBox mailContent ${isShown ? 'mailContentExpanded' : 'mailContentCollapsed'} 
        ${convoboxdata.message_is_deleted ? 'mailIsDeleted' : ''} ${convoboxdata.message_is_read ? 'mailIsRead' : ''} ${convoboxdata.message_is_draft ? 'mailIsDraft' : ''}`;
    }, [convoboxdata]);




    const toWhomPrinter = useCallback((convoboxdata) => {
        if(isEmpty(mailFolderList)) return;
        let { message_cc, message_to, message_bcc } = convoboxdata;
        message_cc = message_cc ? message_cc : []
        message_to = message_to ? message_to : []
        message_bcc = message_bcc ? message_bcc : []
        const mailOfFolder = mailFolderList.find(folderItem => folderItem.id === convoboxdata.folder).folder_name;
        return (
            <div className="mailToWhomDetails">
                <p className="subHead">
                    {message_to.length > 0 && `To : ${message_to.join(", ")}`}
                </p>
                <p className="subHead">
                    {message_cc.length > 0 && `Cc : ${message_cc.join(", ")}`}
                </p>

                {/* In inbox page bcc should be hidden */}
                {
                    toLowercaseAndRemoveSpaces(mailOfFolder) !== toLowercaseAndRemoveSpaces(appConfig.menu_static_names.inbox)
                    &&
                    <p className="subHead">
                        {message_bcc.length > 0 && `Bcc : ${message_bcc.join(", ")}`}
                    </p>
                }
            </div>
        )
    }, [convoboxdata, mailFolderList]);

    const handleDraftWhenClickedSomeWhere = (values, resetForm, editorFunction, onConfirm, locationData) => {
        replaySender(values, resetForm, 'draft',true);
        if (editorFunction)
        {
            // for resetting composer
            editorFunction.setData(""); 
        }
        onConfirm();

        // handling active mail indication and loading clicked mail content in mail view
        const clickedMailItem = locationData.state?.mailItem;
        if(clickedMailItem)
        {
            setCurrentlyActiveMail(clickedMailItem.id)
            dispatch(getMailDetailsAsync(clickedMailItem.id))
        }
    }





    // if there is action name set new files :: only dependant on mail replay,replayall,forward
    useEffect(() => {
        if (replayMailRef.current && filesSelectedFromAttachmentModalWithActionName[convoboxdata.id] !== undefined)
        {
            const newfiles = filesSelectedFromAttachmentModalWithActionName;
            if (!isEmpty(newfiles))
            {
                const newData = filesSelectedFromAttachmentModalWithActionName[convoboxdata.id];
                const { setFieldValue, values } = replayMailRef.current;
                setFieldValue('attachments', [...values.attachments, ...newData])
            }
        }
    }, [filesSelectedFromAttachmentModalWithActionName])
    // if there is action name set new files :: only dependant on mail replay,replayall,forward




    const checkReplayDirty = () => {
        if(replayMailRef.current)
        {
            const { dirty } = replayMailRef.current;
            return dirty;
        }
    }

    useImperativeHandle(ref, () =>({
        checkReplayDirty: checkReplayDirty,
    }),[replayMailRef.current])



    return (

        <div className={classGenerator()} id="mailsRightWrapper">
            {/* <Grid item xs={12} sm={6}>
                <pre>{JSON.stringify(convoboxdata, null, 2)}</pre>
            </Grid> */}
            {
                isReplayView
                    ?
                    <div className="mailReplayView mailReplay">

                        <Formik
                            innerRef={replayMailRef}
                            enableReinitialize
                            initialValues={{
                                // if have replayRecipient then show replayRecipient else check replayAllRecipient show it if that too doesnt exist show empty array
                                to: (
                                    replayRecipient ? [...replayRecipient.to]
                                        : replayAllRecipient ? [...replayAllRecipient.to]
                                            : forwardMailData ? [...forwardMailData.to]
                                                : []
                                ),
                                replayMessageBody: (
                                    replayRecipient ? replayRecipient.replayMessageBody
                                        : replayAllRecipient ? replayAllRecipient.replayMessageBody
                                            : forwardMailData ? forwardMailData.replayMessageBody
                                                : ''
                                ),
                                action: replayRecipient ? replayRecipient.action
                                    : replayAllRecipient ? replayAllRecipient.action
                                        : forwardMailData ? forwardMailData.action
                                            : '',
                                reply_id: replayRecipient ? replayRecipient.reply_id
                                    : replayAllRecipient ? replayAllRecipient.reply_id
                                        : forwardMailData ? forwardMailData.reply_id
                                            : '',
                                cc: [],
                                bcc: [],
                                attachments: forwardMailData ? forwardMailData.attachments : []
                            }}
                            validationSchema={replayMailSchema}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                replaySender(values, resetForm, '')
                            }}
                        >
                            {({ values, setFieldValue, isValid, dirty, resetForm, isSubmitting }) => {

                                return (
                                    <>
                                        <NavigationPrompt when={dirty === true && hasSubmitCompleted === false}>
                                            {(isOpen, onConfirm, onCancel, closeModal, locationData) => {

                                                return (
                                                    <Modal open={isOpen} handleClose={() => onCancel()} size="xs" className="messageDraftModal" showDefaultCloseIcon>
                                                        <DialogContent>
                                                            <h3 className="customHead">Discard Email ?</h3>
                                                            <p className="discardMessage customDescription">Do you want to save this mail as draft ?</p>

                                                            {
                                                                            attachmentsTotalFileSizeFinder(values.attachments) > maxFileAttachmentSize
                                                                                ?
                                                                                <p className="attachmentSizeExceedMsg">{attachmentSizeExceededMessage} </p>
                                                                                :
                                                                                null
                                                                        }


                                                            <DialogActions className="modalFooter">
                                                                <Button
                                                                    onClick={() => {
                                                                        handleDraftWhenClickedSomeWhere(values, resetForm, editorFunction, onConfirm, locationData);
                                                                    }}
                                                                    isLoading={sendMailReplayLoading}
                                                                    disabled={(() => {
                                                                        const totalFileSize =  attachmentsTotalFileSizeFinder(values.attachments) ;
                                                                        if (sendMailReplayLoading || hasTotalAttachmentsSizeCrossed(maxFileAttachmentSize, totalFileSize))
                                                                        {
                                                                            return true;
                                                                        }
                                                                        return false;
                                                                    })()}
                                                                    color="primary" autoFocus bgColor="secondary" width='150px' customStyle={{ marginRight: '20px' }}
                                                                >
                                                                    Save to Draft
                                                                </Button>
                                                                <Button onClick={() => {
                                                                    if (editorFunction)
                                                                    {
                                                                        editorFunction.setData(""); // for reseting composer
                                                                    }
                                                                    onConfirm()
                                                                    closeModal()
                                                                    setIsReplayView(false);
                                                                }} color="primary" customStyle={{ background: 'white', marginRight: '10px' }} width='150px'>
                                                                    Discard
                                                                </Button>
                                                            </DialogActions>
                                                        </DialogContent>
                                                    </Modal>
                                                )
                                            }}
                                        </NavigationPrompt>



                                        <Form>
                                            <div className="mailReplayFieldItem mailReplayHead replayMailForm">
                                                <div
                                                    className="cccBccWrapper"
                                                >

                                                    <Field
                                                        component={MultiSelect}
                                                        name="to"
                                                        label="To"
                                                        required
                                                        className="test"
                                                        options={searchMailRecipientsData}
                                                        onInputChange={(e, value, reason) => {
                                                            dispatch(searchMailRecipientsAsync(value));
                                                        }}
                                                        loading={isSearchMailReceiptLoading}
                                                        optionLabel="searchRecipientName"
                                                        creatable
                                                        clearOnBlur
                                                        placeholder={false}
                                                    />

                                                    <span className="cssBccItems">
                                                        <p onClick={() => setIsCCShown(prevState => {
                                                            // only hide cc if there is no value inside bcc
                                                            if (values.cc.length > 0)
                                                            {
                                                                return prevState
                                                            }
                                                            else
                                                            {
                                                                return !prevState
                                                            }
                                                        })}>
                                                            CC
                                                </p>
                                                        <p onClick={() => setIsBCCShown(prevState => {
                                                            // only hide bcc if there is no value inside bcc
                                                            if (values.bcc.length > 0)
                                                            {
                                                                return prevState
                                                            }
                                                            else
                                                            {
                                                                return !prevState
                                                            }
                                                        })}>
                                                            BCC
                                                </p>
                                                    </span>
                                                </div>
                                            </div>


                                            {
                                                isCCShown &&
                                                <div className="mailReplayFieldItem mailReplayHead">
                                                    <Field
                                                        component={MultiSelect}
                                                        name="cc"
                                                        label="Cc"
                                                        required
                                                        className="mailToMultiSelect"
                                                        options={searchMailRecipientsData}
                                                        onInputChange={(e, value, reason) => {
                                                            dispatch(searchMailRecipientsAsync(value));
                                                        }}
                                                        loading={isSearchMailReceiptLoading}
                                                        optionLabel="searchRecipientName"
                                                        creatable
                                                        clearOnBlur
                                                        placeholder={false}
                                                    />
                                                </div>
                                            }

                                            {
                                                isBCCShown &&
                                                <div className="mailReplayFieldItem mailReplayHead">
                                                    <Field
                                                        component={MultiSelect}
                                                        name="bcc"
                                                        label="Bcc"
                                                        required
                                                        className="mailToMultiSelect"
                                                        options={searchMailRecipientsData}
                                                        onInputChange={(e, value, reason) => {
                                                            dispatch(searchMailRecipientsAsync(value));
                                                        }}
                                                        loading={isSearchMailReceiptLoading}
                                                        optionLabel="searchRecipientName"
                                                        creatable
                                                        clearOnBlur
                                                        placeholder={false}
                                                    />
                                                </div>
                                            }






                                            <div className="mailReplayContent">
                                                <Composer
                                                    toolbarItems={toolbarItemsData}
                                                    className="mailReplayComposer"
                                                    data={values.replayMessageBody}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setFieldValue('replayMessageBody', data)
                                                    }}
                                                    onInit={editor => {
                                                        setEditorFunction(editor);
                                                        replayMailFormatter(editor, currentlyLoggedUserName, values.replayMessageBody);

                                                        editor.editing.view.document.on( 'enter', ( evt, data ) => {
                                                            if ( data.isSoft ) {
                                                                editor.execute( 'enter' );
                                                            } else {
                                                                editor.execute( 'shiftEnter' );
                                                                        }
                                                
                                                            data.preventDefault();
                                                            evt.stop();
                                                            editor.editing.view.scrollToTheSelection();
                                                        }, { priority: 'high' } );
                                                        
                                                    }}
                                                />
                                            </div>
                                            <div className="mailReplayFooter">
                                                <Grid container spacing={2}>

                                                    <Grid item xs={12}>
                                                                    {
                                                                        attachmentsTotalFileSizeFinder(values.attachments) > maxFileAttachmentSize
                                                                            ?
                                                                            <p className="attachmentSizeExceedMsg">{attachmentSizeExceededMessage} </p>
                                                                            :
                                                                            null
                                                                    }
                                                                </Grid>
                                                                {
                                                                    values.attachments.length > 0 &&
                                                                    <FileAttachmentPreview
                                                                        files={values.attachments}
                                                                        showDownloadAllLabel={false}
                                                                        showDownloadIcon={false}
                                                                        customRemoveFilesHandler={(index) => {
                                                                            const filesPicked = [...values.attachments];
                                                                            filesPicked.splice(index, 1)
                                                                            setFieldValue('attachments', filesPicked)
                                                                        }}
                                                                    />
                                                                }





                                                    <Grid item xs={12} sm={4} md={6} xl={2} >
                                                        <div className="attachIcon">
                                                            <AttachFileIcon
                                                                className="attachmentIcon"
                                                                onClick={() => {
                                                                    dispatch({ type: 'SET_ACTION_NAME', payload: convoboxdata.id })
                                                                    dispatch(openCloseAttachmentModal(true));
                                                                }}
                                                            />
                                                            <p>{attachmentLabelMsg}</p>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={3} xl={2}>
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            autoFocus
                                                            bgColor="secondary"
                                                            width='100%'
                                                            disabled={(() => {
                                                                const totalFileSize =  attachmentsTotalFileSizeFinder(values.attachments) ;
                                                                if ((isSubmitting) || hasTotalAttachmentsSizeCrossed(maxFileAttachmentSize, totalFileSize))
                                                                {
                                                                    return true;
                                                                }
                                                                return false;
                                                            })()}
                                                            isLoading={isSubmitting}>
                                                            Send
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={3} xl={2}>
                                                        <Button
                                                            buttonType="button"
                                                            onClick={() => {
                                                                if (dirty)
                                                                {
                                                                    setIsDiscardModalShown(true);
                                                                }
                                                                else
                                                                {
                                                                    setIsReplayView(false)
                                                                }
                                                            }}
                                                            color="primary"
                                                            customStyle={{ background: 'white' }}
                                                            width='100%'
                                                        >
                                                            Discard
                                                    </Button>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Form>

                                        <Modal open={isDiscardModalShown} handleClose={() => setIsDiscardModalShown(false)} size="xs" className="messageDraftModal" showDefaultCloseIcon>
                                            <DialogContent>
                                                <h3>Discard ?</h3>
                                                <p className="discardMessage">Are you sure you want to discard this draft ?</p>
                                                {
                                                    attachmentsTotalFileSizeFinder(values.attachments) > maxFileAttachmentSize
                                                        ?
                                                        <p className="attachmentSizeExceedMsg">{attachmentSizeExceededMessage} </p>
                                                        :
                                                        null
                                                }

                                                <DialogActions className="modalFooter">
                                                    <Button
                                                        onClick={() => {
                                                            const { resetForm } = replayMailRef.current;
                                                            resetForm({
                                                                values: {
                                                                    to: [],
                                                                    category: '',
                                                                    template: '',
                                                                    message_subject: '',
                                                                    cc: [],
                                                                    bcc: [],
                                                                    messageBody: '',
                                                                }
                                                            });
                                                            if (editorFunction)
                                                            {
                                                                editorFunction.setData(""); // for reseting composer
                                                            }
                                                            setIsDiscardModalShown(false);
                                                            setIsReplayView(false);
                                                        }}
                                                        color="primary" autoFocus bgColor="secondary" width='150px' customStyle={{ marginRight: '20px' }}
                                                    >
                                                        Ok
                                                    </Button>

                                                    <Button onClick={() => setIsDiscardModalShown(false)} color="primary" customStyle={{ background: 'white', marginRight: '10px' }} width='150px'>
                                                        Cancel
                                                    </Button>

                                                    <Button
                                                        onClick={() => {
                                                            const { resetForm, values } = replayMailRef.current;
                                                            replaySender(values, resetForm, 'draft');
                                                            if (editorFunction)
                                                            {
                                                                editorFunction.setData(""); // for reseting composer
                                                            }
                                                            // setIsDiscardModalShown(false)
                                                        }}
                                                        color="primary"
                                                        customStyle={{ background: 'white', marginRight: '10px' }}
                                                        width='150px'
                                                        isLoading={sendMailReplayLoading}
                                                        disabled={(() => {
                                                            const totalFileSize = attachmentsTotalFileSizeFinder(values.attachments);
                                                            if (sendMailReplayLoading || hasTotalAttachmentsSizeCrossed(maxFileAttachmentSize, totalFileSize))
                                                            {
                                                                return true;
                                                            }
                                                            return false;
                                                        })()}
                                                    >
                                                        {/* In drafts page text should be just save */}
                                                            Save {initialDataForDrafts() == null && 'to draft'}
                                                    </Button>
                                                </DialogActions>
                                            </DialogContent>
                                        </Modal>
                                    </>
                                );
                            }}
                        </Formik>
                    </div>
                    :
                    composeMailView
                        ?
                        <ComposeMailForm
                            openEmailErrorModalHandler={openEmailErrorModalHandler}
                            setErrorEmailList={setErrorEmailList}
                            setOpenPreviewModal={setOpenPreviewModal}
                            setMessageAsDraft={setMessageAsDraft}
                            initialDataForDrafts={initialDataForDrafts()}
                            setIsReplayView={setIsReplayView}
                            setComposeMailView={setComposeMailView}
                            objectId={convoboxdata.object_id}
                            mailId={convoboxdata.id}
                            messageDraftStatus={convoboxdata.message_is_draft}
                            composeComponentRef={composeComponentRef}
                        />
                        :
                        <div className="mailDetailsView mailContentBox content" >
                            {
                                pageName !== toLowercaseAndRemoveSpaces(appConfig.menu_static_names.sentItems)
                                &&
                                <div className="left">
                                    <NameCircle size="45px" name={firstTwoLetters} style={{ marginRight: '0' }} />
                                </div>
                            }
                            <div className="right">
                                <div className="rightWrapper">
                                    <div className="mailHeadAndForward">
                                        <div className="headerDetails">
                                            {
                                                isShown
                                                &&
                                                (
                                                    <div className="forwardIcons forwardIconsForMobileOnly">
                                                        {/* Note :: when mail is not synched object id will be null  */}

                                                        {/* only show replay,replay all, forward button if message is not draft */}
                                                        {
                                                            convoboxdata.object_id !== null && convoboxdata.message_is_draft === false
                                                            &&
                                                            <>
                                                                <span onClick={() => replaySetter()}>
                                                                    <ReplyIcon className="icon cursor-pointer" />
                                                                </span>
                                                                <span onClick={() => replayAllSetter()}>
                                                                    <ReplyAllIcon className="icon cursor-pointer" />
                                                                </span>
                                                                <span onClick={() => forwardSetter()}>
                                                                    <ArrowRightAltIcon className="icon replayIcon cursor-pointer" />
                                                                </span>
                                                            </>
                                                        }

                                                        {/* Only show edit if message is draft*/}

                                                        {
                                                            (convoboxdata.object_id !== null && convoboxdata.message_is_draft && pageName !== toLowercaseAndRemoveSpaces(appConfig.menu_static_names.deletedItems))
                                                            &&
                                                            <span onClick={() => {
                                                                if (isComposeMailFormAuthorized)
                                                                {
                                                                    dispatch(removeMailSuccessMessage())
                                                                    setIsReplayView(false);
                                                                    setComposeMailView(true);
                                                                }
                                                                else
                                                                {
                                                                    enqueueSnackbar(appConfig.messages.not_authorized_action_message, {
                                                                        variant: 'error',
                                                                        anchorOrigin: {
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        },
                                                                    });
                                                                }
                                                            }}>
                                                                <EditIcon className="icon editIcon cursor-pointer" />
                                                            </span>
                                                        }

                                                        {/* hide delete icon if message is already deleted */}
                                                        {
                                                            convoboxdata.object_id !== null && !convoboxdata.message_is_deleted
                                                            &&
                                                            <span onClick={() => {
                                                                if (isDeleteMailAuthorized)
                                                                {
                                                                    const details = {
                                                                        source_folder_id: convoboxdata.folder,
                                                                        target_folder_id: deleteFolderId,
                                                                        conversation_list: [],
                                                                        mail_id: convoboxdata.id,
                                                                        currentFolderId: folderId,
                                                                        isDeletingFromDeleteFolder: folderId === deleteFolderId ? true : false,
                                                                        limit: dataLimit,
                                                                        offset: mailsInFolderOffset ? mailsInFolderOffset : 0
                                                                    };
                                                                    dispatch(moveMailToFolderAsync(details))
                                                                }
                                                                else
                                                                {
                                                                    enqueueSnackbar(appConfig.messages.not_authorized_action_message, {
                                                                        variant: 'error',
                                                                        anchorOrigin: {
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        },
                                                                    });
                                                                }
                                                            }}>
                                                                <DeleteIcon className="icon deleteIcon cursor-pointer" />
                                                            </span>
                                                        }


                                                    </div>
                                                )
                                            }
                                            <div>
                                                {
                                                    convoboxdata.message_is_draft
                                                    &&
                                                    <p className="messageDraft">[ draft ]</p>
                                                }
                                                <h3 className="head cursor-pointer" onClick={() => handleExpandCollapse(id, dispatch)}>
                                                    {
                                                        convoHeaderPrinter(convoboxdata, isShown)
                                                    }
                                                </h3>
                                            </div>
                                        </div>

                                        {
                                            isShown
                                                ?
                                                <div className="forwardIcons">
                                                    {/* NOTE :: when mail is not synched object id will be null  */}


                                                    {
                                                        convoboxdata.object_id !== null && convoboxdata.message_is_draft === false
                                                        &&
                                                        <>
                                                            <span onClick={() => replaySetter()}>
                                                                <ReplyIcon className="icon cursor-pointer" />
                                                            </span>
                                                            <span onClick={() => replayAllSetter()}>
                                                                <ReplyAllIcon className="icon cursor-pointer" />
                                                            </span>
                                                            <span onClick={() => forwardSetter()}>
                                                                <ArrowRightAltIcon className="icon replayIcon cursor-pointer" />
                                                            </span>
                                                        </>
                                                    }





                                                    {/* Only show edit if message is draft*/}
                                                    {
                                                        convoboxdata.object_id !== null && convoboxdata.message_is_draft
                                                        &&
                                                        <span onClick={() => {
                                                            if (isComposeMailFormAuthorized)
                                                            {
                                                                dispatch(removeMailSuccessMessage())
                                                                setIsReplayView(false);
                                                                setComposeMailView(true);
                                                            }
                                                            else
                                                            {
                                                                enqueueSnackbar(appConfig.messages.not_authorized_action_message, {
                                                                    variant: 'error',
                                                                    anchorOrigin: {
                                                                        vertical: 'bottom',
                                                                        horizontal: 'center',
                                                                    },
                                                                });
                                                            }
                                                        }}>
                                                            <EditIcon className="icon editIcon cursor-pointer" />
                                                        </span>
                                                    }

                                                    {/* hide delete icon if message is already deleted */}
                                                    {
                                                        convoboxdata.object_id !== null && !convoboxdata.message_is_deleted
                                                        &&
                                                        <span onClick={() => {
                                                            
                                                            if (isDeleteMailAuthorized)
                                                            {
                                                                const details = {
                                                                    source_folder_id: convoboxdata.folder,
                                                                    target_folder_id: deleteFolderId,
                                                                    conversation_list: [],
                                                                    mail_id: convoboxdata.id,
                                                                    currentFolderId: folderId,
                                                                    isDeletingFromDeleteFolder: folderId === deleteFolderId ? true : false,
                                                                    limit: dataLimit,
                                                                    offset: mailsInFolderOffset ? mailsInFolderOffset : 0
                                                                };
                                                                dispatch(moveMailToFolderAsync(details))
                                                            }
                                                            else
                                                            {
                                                                enqueueSnackbar(appConfig.messages.not_authorized_action_message, {
                                                                    variant: 'error',
                                                                    anchorOrigin: {
                                                                        vertical: 'bottom',
                                                                        horizontal: 'center',
                                                                    },
                                                                });
                                                            }
                                                        }}>
                                                            <DeleteIcon className="icon deleteIcon cursor-pointer" />
                                                        </span>
                                                    }

                                                </div>
                                                :
                                                <div className="mailClosedInfo">
                                                    <div className="mailClosedInfoWrapper">
                                                        {
                                                            (convoboxdata.has_attachments && convoboxdata.attachment_list.length !== 0) && <AttachmentIcon className="icon" />
                                                        }
                                                        <p >{dateAndTime}</p>
                                                    </div>
                                                </div>
                                        }

                                    </div>




                                    {
                                        isShown &&
                                        <>
                                            <p className="subHead">
                                                {dateAndTime} <br />
                                            </p>
                                            {
                                                toWhomPrinter(convoboxdata)
                                            }




                                            {
                                                (() => {
                                                    // const data = convoboxdata.message_body.replace(/(<style[\w\W]+style>)/g, '');
                                                    const $ = cheerio.load(convoboxdata.message_body)
                                                    $('style').each(function() {
                                                        $(this).remove()
                                                    });
                                                    const data = $('body').html();
                                                    const sentItemsFolderType = toLowercaseAndRemoveSpaces(appConfig.menu_static_names.sentItems);
                                                    return (
                                                        <>
                                                            {
                                                                (convoboxdata.template !== null)
                                                                    ?
                                                                    <div className={`content mailData ${(pageName !== sentItemsFolderType ? 'widthControl' : 'noWidthControl')}`} dangerouslySetInnerHTML={{
                                                                        __html: `${convoboxdata.template_header}${data}${convoboxdata.template_footer}`
                                                                    }} />
                                                                    :
                                                                    <div className={`content mailData ${(pageName !== sentItemsFolderType ? 'widthControl' : 'noWidthControl')}`} dangerouslySetInnerHTML={{
                                                                        __html: data
                                                                    }} />
                                                            }
                                                        </>
                                                    )
                                                })()
                                            }


                                            {/* Showing attachments */}

                                            {
                                                convoboxdata.attachment_list.length > 0 && <FileAttachmentPreview files={convoboxdata.attachment_list} showCloseIcon={false} />
                                            }


                                            {/* Show replay and forward only if item is not draft */}
                                            {
                                                convoboxdata.object_id !== null && convoboxdata.message_is_draft === false
                                                &&
                                                <ul className="mail">
                                                    <li onClick={() => replaySetter()}>Reply</li>
                                                    <li onClick={() => forwardSetter()}>Forward</li>
                                                </ul>
                                            }

                                        </>
                                    }
                                </div>
                            </div>
                        </div>
            }



        </div>

    );
})

export default CoversationBox;
