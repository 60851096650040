import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import cheerio from 'cheerio';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import appConfig from '../../appConfig.json';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogContent from '@material-ui/core/DialogContent';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Menu, MenuItem} from "@mui/material";
import DialogTitle from '@mui/material/DialogTitle';
import {Grid, CircularProgress, IconButton, DialogActions} from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import messageSendIcon from "../../assets/icons/profileManagementIcons/sendMessageIcon.svg"
import attachmentIcon from "../../assets/icons/profileManagementIcons/attachmentIcon.svg"
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import Modal from '../Modal/Modal';
import { Box, Typography } from '@mui/material';
import Button from '../Button/Button';
import FileAttachmentModalComponent from '../FileAttachmentModal/FileAttachmentModalComponent/FileAttachmentModalComponent';
import FileAttachmentPreview from '../FileAttachmentPreview/FileAttachmentPreview';
import { useDispatch, useSelector } from 'react-redux';
import { axiosPost } from '../../utils/api';

import { removeIndivigualFileItems } from '../../redux/ui/ui.actions';
import { useSnackbar } from 'notistack';
import { getTrimmedString, toLowercaseAndRemoveSpaces } from '../../utils/utils';
import NameCircle from '../NameCircle/NameCircle';
import { letterMaker } from '../Mail/MailView/MailView';
import moment from 'moment';

function Attachments({isAttachmentAdded,extractFileName,getAttachments,selectedList,open,setOpenAttachments}) {
  const scrollContainerRef = useRef(null);
  const dispatch  = useDispatch();
  const {enqueueSnackbar}=useSnackbar();
  const [selectedAttachmentIndex,setSelectedAttachmentIndex]=useState(null)
  const selectedAttachment=useMemo(()=>selectedList?.attachments?.[selectedAttachmentIndex],[selectedAttachmentIndex]);
  const [isAttachmentUploading,setIsAttachmentUploading]=useState(false)
  const[openAttachmentModal,setOpenAttachmentModal]=useState(false)
  const handleModalClose = () => {
    setOpenAttachments(false);
    dispatch(removeIndivigualFileItems([]))
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = () => {
    indivigualItemDownloadHandler();
    handleMenuClose();
  };
  const allowedFileTypes=[".doc",".docx",".pdf",".eml"]
   const toWhomPrinter = useCallback((emlFileData) => {
          let { message_cc, message_to, message_bcc } = emlFileData;
          message_cc = message_cc ? message_cc : []
          message_to = message_to ? message_to : []
          message_bcc = message_bcc ? message_bcc : []
          return (
              <div className="mailToWhomDetails">
                  <p className="subHead">
                      {message_to.length > 0 && `To : ${message_to.join(", ")}`}
                  </p>
                  <p className="subHead">
                      {message_cc.length > 0 && `Cc : ${message_cc.join(", ")}`}
                  </p>
  
              </div>
          )
      }, [selectedAttachmentIndex]);
      const extractSenderName = (fromHeader) => {
        if (!fromHeader) return "Unknown Sender";
        const nameMatch = fromHeader.match(/^(.*?)\s*<.*>/);
        if (nameMatch) {
            return nameMatch[1].replace(/^"|"$/g, '').trim(); // Remove quotes
        }
    
        // If there's no email part, return the full string as name
        return fromHeader.replace(/^"|"$/g, '').trim(); // Remove surrounding quotes if any
    };
      const extractConvoboxDataFromHeaders = (headers) => {
        if (!headers) return null;
    
        return {
            message_bcc:"",
            message_cc:"",
            message_to: headers["To"] ? [headers["To"]] : [],
            message_sender_details: {
                sender_name: extractSenderName(headers["From"]),
                sender_mail: headers["From"] ? headers["From"].match(/<(.*)>/)?.[1] || headers["From"] : "Unknown Email"
            },
            message_subject: headers["Subject"] || "No Subject",
            message_received: headers["Date"]
                ? moment(headers["Date"]).format("ddd, DD/MM/YYYY HH:mm a") 
                : null,};
    };
      const convoHeaderPrinter = (emlFileData) => {

        const senderName = emlFileData.message_sender_details.sender_name 
        const senderFrom = emlFileData.message_sender_details.sender_mail;
        return (
            <>
                {/* Show sender from address when expanded and if sendername and senderfrom are different */}
                {
                    senderFrom && 
                    <span className="fromAddress">
                        {
                            `${senderName} <${senderFrom}>`
                        }
                    </span>
                }
    
            </>
        )
    }
  const indivigualItemDownloadHandler = useCallback( async ()=>{
           const itemName = extractFileName(selectedAttachment.attachment);
           const url = selectedAttachment.attachment;
           const a = document.createElement("a");
           a.href = url;
           a.download = itemName; 
           document.body.appendChild(a);
           a.click();
           document.body.removeChild(a);
        },[selectedAttachmentIndex])
  const filesSelectedFromAttachmentModal = useSelector(state => state.ui.filesSelectedFromAttachmentModal);
  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 200; 
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const submitFile=async (note)=>{
    try{
      setIsAttachmentUploading(true)
      const newFormData=new FormData()
      let localFileAttachments = [];
      filesSelectedFromAttachmentModal.forEach(item => {
        if (item instanceof File)
        {
            localFileAttachments.push(item)
        }
    })
    localFileAttachments.forEach(fileItem => {
      newFormData.append('attachments', fileItem, fileItem.name);
  })
  newFormData.append(selectedList?.event_flag?"event_id":"meeting_id" , selectedList?.id)
      const data = await axiosPost(`meetingattachments/`, newFormData,{},true);
      if (data.status) {
        enqueueSnackbar(data?.data?.message, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        })
        getAttachments();
        dispatch(removeIndivigualFileItems([]))
      }
      setIsAttachmentUploading(false)
    }
    catch{
      setIsAttachmentUploading(false)
    }
  }
  useEffect(()=>{
    if(selectedList?.attachments?.length>0){
      setSelectedAttachmentIndex(0)
    }
  },[selectedList])
  const headers=useMemo(()=>selectedAttachment?.eml_file?.headers,[selectedAttachmentIndex]);
  const emlAttachmentList=useMemo(()=>selectedAttachment?.eml_file?.attachment_list.filter(file=>{
    return allowedFileTypes.some(type=>file?.name.includes(type))
  }),[selectedAttachmentIndex]);
  const emlHeaderConverted=useMemo(()=>extractConvoboxDataFromHeaders(headers),[selectedAttachmentIndex]);
  const firstTwoLetters = useMemo(()=>letterMaker(emlHeaderConverted?.message_sender_details?.sender_name),[selectedAttachmentIndex]);
  
  return (
    <Modal
      className="customModal"
      open={open}
      handleClose={handleModalClose}
      size="md"
      titleBlocking
    >
      <DialogTitle
       style={{
          position: 'sticky',
          top: 0,
          left: 0,
          padding: '10px',
          backgroundColor: 'white',
          zIndex: 1000,
          display:"flex",
          justifyContent:"space-between",
          alignItems:"center"
        }}
      >
       <span>Attachments</span>
       <CloseIcon style={{color:"#686868"}} className="viewCalendarEventModalIcon closeIcon cursor-pointer" onClick={handleModalClose} />
      </DialogTitle>
      {(selectedList && selectedList?.attachments?.length > 0 ) &&<Box sx={{ display: "flex", alignItems: "center", gap: 1,marginBottom:"18px"}}>
      {/* Left Scroll Button */}
      <IconButton onClick={() => scroll("left")} sx={{ color: "#32c2d9" }}>
        <ChevronLeft fontSize="medium" />
      </IconButton>

      {/* Scrollable Attachment List */}
      <Box
        ref={scrollContainerRef}
        sx={{
          display: "flex",
          overflowX: "auto",
          whiteSpace: "nowrap",
          maxWidth: "auto",
          gap: 2,
          padding: 1,
          scrollbarWidth: "thin",
          scrollbarColor: "#32c2d9 #f0f0f0",
          "&::-webkit-scrollbar": {
            height: "6px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f0f0f0",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#32c2d9",
            borderRadius: "10px",
          },
        }}
      >
        { selectedList?.attachments?.map((file, index) => {
          const fileName=file?.attachment?.length?extractFileName(file?.attachment):""
          return (
            <Tooltip title={fileName}><Box
            key={index}
            sx={{
              minWidth: "120px",
              padding: "10px",
              color: "#32c2d9",
              borderRadius: "8px",
              textAlign: "center",
              cursor: "pointer",
              ...(selectedAttachmentIndex==index?{ background: "#1565c0",color: "white",display:"flex" }:{}),
            }}
            onClick={()=>{
                setSelectedAttachmentIndex(index)
            }}
          >
            <Typography variant="body2"> <img src={attachmentIcon} style={{margin:"3px"}}/>{getTrimmedString(fileName,5)}</Typography>{selectedAttachmentIndex==index&&<IconButton onClick={handleMenuOpen} style={{ padding: 0 }}>
          <MoreVertIcon style={{ fontSize: 18, color: "#fff" }} />
        </IconButton>}
          </Box></Tooltip>
        )})}
        <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleMenuClose}
        onClick={(e) => e.stopPropagation()} 
      >
        <MenuItem onClick={handleDownload}>Download</MenuItem>
      </Menu>
      </Box>

      {/* Right Scroll Button */}
      <IconButton onClick={() => scroll("right")}>
        <ChevronRight fontSize="medium" />
      </IconButton>
    </Box>}
      <DialogContent className="descriptionContent" style={{...(selectedList && selectedList?.attachments?.length > 0 ) &&{maxHeight:"420px"}}} >
          {isAttachmentAdded ? (
            <CircularProgress size="1.5rem" style={{ color: '#32c2d9' }} />
          ) : 
          (selectedList && selectedList?.attachments?.length > 0 && selectedAttachmentIndex >= 0) ?
          !selectedAttachment?.eml_file?<iframe
            // https://view.officeapps.live.com/op/embed.aspx?src=${selectedUrl}}
            // src={`https://view.officeapps.live.com/op/embed.aspx?src=${testURL}#toolbar=0&navpanes=0`}
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
              selectedAttachment?.attachment
            )}#toolbar=1&navpanes=1`}
            // src={`https://docs.google.com/viewer?url=${encodeURIComponent(
            //   testURL
            // )}?&embedded=true}`}
            frameborder="0"
            style={{
      width: "95%",  // Adjust width to match layout
      height: "350px", // Adjust height
      border: "none", 
    }} 
          ></iframe>:<div className="mailDetailsView mailContentBox content" >
          <div className="email-header">
    <div className="left">
        <NameCircle size="45px" name={firstTwoLetters} style={{ marginRight: '0' }} />
    </div>

    <div className="right">
        {convoHeaderPrinter(emlHeaderConverted)}
        <p className="subHead">
            {emlHeaderConverted?.message_received} <br />
            {toWhomPrinter(emlHeaderConverted)}
        </p>
        
    </div>
</div>
          {(() => {
                                                    // const data = emlFileData.message_body.replace(/(<style[\w\W]+style>)/g, '');
                                                    const $ = cheerio.load(selectedAttachment?.eml_file?.body)
                                                    $("img").each(function () {
                                                            let src = $(this).attr("src");
                                                        
                                                            if (src && src.startsWith("cid:")) {
                                                                let cid = src.replace("cid:", "");
                                                        
                                                                // Find the matching attachment
                                                                let attachment = selectedAttachment?.eml_file?.attachment_list.find(att => cid.includes(att.name));
                                                        
                                                                if (attachment) {
                                                                    $(this).attr("src", attachment.item_url);  // Replace src
                                                                }
                                                            }
                                                        });
                                                    const data = $('body').html();
                                                    const sentItemsFolderType = toLowercaseAndRemoveSpaces(appConfig.menu_static_names.sentItems);
                                                    return (
                                                        <>
                                                            {
                                                               
                                                                    <div className={`content mailData `} dangerouslySetInnerHTML={{
                                                                        __html: data
                                                                    }} />
                                                            }
                                                        </>
                                                    )
                                                })()}
                                            
                                                {
                                                  selectedAttachment?.eml_file?.attachment_list?.length > 0 && <FileAttachmentPreview files={emlAttachmentList} showCloseIcon={false} arrangeOnRow/>
                                            }
          </div>:<div className="noNotes">No Attachment Added</div>}
      </DialogContent>
      
      <DialogActions
        style={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'white',
          padding: '10px',
          boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
          zIndex: 1000
        }}
      >
          <Grid container spacing={2} alignItems='center'  ><Grid item xs={12} > {filesSelectedFromAttachmentModal?.length > 0 && (
                    <FileAttachmentPreview
                      arrangeOnRow
                      files={filesSelectedFromAttachmentModal}
                      showCloseIcon={true}
                      showDownloadIcon={false}
                    />
                  )}
                
                </Grid><Grid item xs={filesSelectedFromAttachmentModal?.length > 0?10:11} >
                <div style={{marginLeft:"8px"}}><Button
                          bgColor="secondary"
                          onClick={() => setOpenAttachmentModal(true)}
                        >
                         <AttachFileIcon />
                          Browse to Upload
                  </Button></div>
                  <FileAttachmentModalComponent
                    open={openAttachmentModal}
                    handleClose={() => setOpenAttachmentModal(false)}
                    size="sm"
                    showCloseIcon
                    enableCapDrive={false}
                    accept={allowedFileTypes.join(",")}
                  /></Grid>
                  <Grid item xs={2} > { filesSelectedFromAttachmentModal?.length > 0&& <IconButton onClick={submitFile} >
                  {isAttachmentUploading?<CircularProgress size="1rem" style={{ color: '#32c2d9' }} />:<img src={messageSendIcon}/>}
              </IconButton>}</Grid>
              
                </Grid>
      </DialogActions>
    </Modal>
  
  )
}

export default Attachments