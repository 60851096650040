import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, useMemo } from 'react'
import Grid from '@material-ui/core/Grid';
import { Formik, Form, Field } from 'formik';
import MultiSelect from '../../MultiSelect/MultiSelect';
import Box from '@material-ui/core/Box';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SingleSelect from '../../../components/SingleSelect/SingleSelect';
import { searchMailRecipientsAsync, fetchMailTemplatesAsync, composeMailAsync, getMailDetailsAsync , removeMailIdFromArray, clearTemplateData} from '../../../redux/mail/mail.action';
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Input from '../../Input/Input';
import { replayMailSchema, emailCheckerSchema } from '../../../schemas/mailSchema';
import { removeIndivigualFileItems } from '../../../redux/ui/ui.actions';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/Button/Button';
import NavigationPrompt from '../../NavigationPromt/NavigationPromt';
import Modal from '../../Modal/Modal';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useSnackbar } from 'notistack';
import { removeMailSuccessMessage, removeMailErrorMessage, removeMailCategoriesError } from '../../../redux/mail/mail.action';
import {customElementStylesPreservingPlugin, CustomFigureAttributes } from '../../Composer/Composer.jsx';
import './ComposeMailForm.scss';
import FileAttachmentPreview from '../../FileAttachmentPreview/FileAttachmentPreview';
import { openCloseAttachmentModal, setFilesSelectedFromAttachmentModal } from '../../../redux/ui/ui.actions';
import { MAIL_SENDING_ACTIONS } from '../../../utils/constants';
import { convertBlobToFile } from '../../../utils/utils';
import { hasTotalAttachmentsSizeCrossed, bytesToSize, getUrlParameters, removeOuterMostTag, getFileNameFromLink, getFileExtension, toLowercaseAndRemoveSpaces  } from '../../../utils/utils';
import { useParams, useLocation } from "react-router-dom";
import { isEmpty } from 'lodash';
import appConfig from '../../../appConfig.json';
import cheerio from 'cheerio';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import { axiosGet } from '../../../utils/api.js';



// CK editor toolbar options
const toolbarItems = ['heading', 'bold', 'Strike', 'Subscript', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote',
    'Undo', 'Redo', 'Underline', 'RemoveFormat', 'CopyFormatting', 'JustifyLeft'];



const previewButtonStyle = {
    background: 'white',
    color: '#585858',
}

const isMessageDraft = ({ to, cc, bcc, messageBody, category, template, message_subject }, setMessageAsDraft = null) => {

    if (to.length > 0 || bcc.length > 0 || cc > 0 || category !== '' || template !== '' || message_subject !== '' || messageBody !== '')
    {
        setMessageAsDraft(true);
        return true;
    }
    else
    {
        return false;
    }
}

const emailTemplateCustomiser = (emailHeader, emailContent, emailFooter) => {
    // emailContent is wrapped with a div so that mail sending data will have padding, currently ck editor removes content wrapping divs, this is a work around to fix that problem. in compose mail view this is managed by giving padding to  ".ck-editor__editable" div
    if (emailHeader && emailFooter)
    {
        return `${emailHeader}<div class="dontStyle" style="width:80%;margin:0 auto;">${addFigureTagStyles(emailContent)}</div>${emailFooter}`;
    }
    else
    {
        return `<div>${emailContent}</div>`;
    }
}


const addFigureTagStyles = (data) => {
	const $ = cheerio.load(data);
    const figureTag = $('figure.table');
    if(figureTag !== null && figureTag !== undefined)
    {
        figureTag.attr('style','margin:0;width:100%;');
    }
	const newOutput = $('body').html();
	return newOutput;
}


const ComposeMailForm = ({
    openEmailErrorModalHandler,
    setErrorEmailList,
    setMessageAsDraft,
    initialDataForDrafts = null, // if this is not null component is being used in drafts page,
    setComposeMailView = () => null,
    setIsReplayView = () => null,
    objectId = null, // when draft saved is edited object id needs to me passed
    mailId,
    messageDraftStatus,
    composeComponentRef,
},  ) => {

    const location = useLocation();
    const { search } = useLocation();
    const [templateContentForPreview, setTemplateContentForPreview] = useState('');
    const [isAttachmentsFetching, setIsAttachmentsFetching] = useState(false);
    const [templateData, setTemplateData] = useState({});
    const totalAttachmentFileSize = useSelector(state => state.ui.totalAttachmentFileSize);
    const maxFileAttachmentSize = appConfig.document_upload.mail_attachments.maxSize;
    const attachmentSizeExceededMessage = `Attachment size exceeded (Max. ${bytesToSize(maxFileAttachmentSize)})`;
    const attachmentLabelMsg = `(Max file size is ${bytesToSize(maxFileAttachmentSize)})`;

    const dataLimit = 10;
    const mailsInFolderOffset = useSelector(state => state.mail.mailsInFolderOffset);

    useEffect(() => {

        if (initialDataForDrafts)
        {
            // set attachments when this component is used at the time of editing a draft or opening a draft::  -- 
            dispatch(setFilesSelectedFromAttachmentModal(initialDataForDrafts.attachments));

            // fetch and set template only if draft has template selected
            if (initialDataForDrafts.template)
            {
                // setting header and footer and content for preview modal data and for getting header and footer inside composer
                setTemplateData({
                    emailTemplateHeader: initialDataForDrafts.templateHeader,
                    emailContent: initialDataForDrafts.messageBody,
                    emailTemplateFooter: initialDataForDrafts.templateFooter,
                });
                setTemplateContentForPreview(initialDataForDrafts.messageBody);

            }
            else
            {
                setTemplateData({
                    emailTemplateHeader: '',
                    emailContent: '',
                    emailTemplateFooter: '',
                });
                setTemplateContentForPreview('');
            }


            if(initialDataForDrafts.category == '')
            {
                dispatch(clearTemplateData())
            }


            // show cc if draft has cc
            if (initialDataForDrafts.cc && initialDataForDrafts.cc.length > 0)
            {
                setIsCCShown(true)
            }
            // show bcc if draft has cc
            if (initialDataForDrafts.bcc && initialDataForDrafts.bcc.length > 0)
            {
                setIsBCCShown(true)
            }
        }


        // clearing header and footer only when its not drafts
        if (!initialDataForDrafts)
        {
            setTemplateData({
                emailTemplateHeader: '',
                emailContent: '',
                emailTemplateFooter: '',
            });
        }

    }, [])

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const composeMailRef = useRef();

    const [isCCShown, setIsCCShown] = useState(false);
    const [isZoomCreating,setIsZoomCreating]= useState(false);
    const [isBCCShown, setIsBCCShown] = useState(false);
    const [hasSubmitCompleted, setHasSubmitCompleted] = useState(false);

    const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const handleClose = () => {
        setOpenPreviewModal(false);
    };


    const isSearchMailReceiptLoading = useSelector(state => state.mail.isSearchMailReceiptLoading);
    const searchMailRecipientsData = useSelector(state => state.mail.searchMailRecipientsData);

    const mailCategoriesData = useSelector(state => state.mail.mailCategoriesData);
    const mailCategoriesLoading = useSelector(state => state.mail.mailCategoriesLoading);
    const mailCategoriesError = useSelector(state => state.mail.mailCategoriesError);

    let templatesData = useSelector(state => state.mail.templatesData);
    const currentUserEmail = useSelector(state => state.auth.currentUserData.email);
    const filesSelectedFromAttachmentModal = useSelector(state => state.ui.filesSelectedFromAttachmentModal);
    const templateAttachments = [];
    const regularAttachments = [];
    filesSelectedFromAttachmentModal.forEach( item => {
        if(item.isSystemGenerated === true)
        {
            templateAttachments.push(item);
        }
        else
        {
            regularAttachments.push(item);
        }
    });


    const composeMailData = useSelector(state => state.mail.composeMailData);
    const composeMailError = useSelector(state => state.mail.composeMailError);
    const composeMailLoading = useSelector(state => state.mail.composeMailLoading);

    const currentlyLoggedUserId = useSelector(state => state.auth.currentUserData.id);



    let { folderId } = useParams();
    const getZoomMeetingLink=async ()=>{
        try {
          setIsZoomCreating(true)
          const response=await axiosGet("create-zoom-room",{},true)
          const respData=response.data
          const joinurl=respData.join_url
             setIsZoomCreating(false)
             return joinurl
        }
        catch{
          setIsZoomCreating(false)
        }
    }


      const isZoomLinkPresent = useMemo(() => {
       if(composeMailRef?.current?.values)
        return composeMailRef?.current?.values?.messageBody?.includes('id="zoom-link"')
       else
         return false
    }, [composeMailRef?.current?.values?.messageBody]);


    const handleInsertZoomLinkAtLine = async (templateData) => {
        if(templateData.template_name=="Catchup"){
            const parser = new DOMParser();
            const doc = parser.parseFromString(templateData.content,'text/html');
        
            if (isZoomLinkPresent) {
              // Remove the Zoom link if it exists
              const zoomLinkDiv = doc.querySelector('#zoom-link');
              if (zoomLinkDiv) zoomLinkDiv.remove();
            } else {
              // Fetch the Zoom link from the API
              let zoomLink=""
              try {
                 zoomLink = await getZoomMeetingLink();
        
                // Add the Zoom link if it doesn't exist
                const zoomContent = doc.querySelector('#zoom-content');
                if (zoomContent) {
                  const zoomDiv = document.createElement('div');
                  zoomDiv.innerHTML = `
                    <div class="public-DraftStyleDefault-block public-DraftStyleDefault-ltr" id="zoom-link">
                        <span class="rdw-link-decorator-wrapper">
                          Zoom link: <a href="${zoomLink}" target="_self">
                            <span data-text="true">${zoomLink}</span>
                          </a>
                        </span>
                    </div>`.trim();
                  zoomContent.insertAdjacentElement('afterend', zoomDiv.firstElementChild);
                }
              } catch (error) {
                
              }
            }
        
            // Serialize the updated DOM back to a string
            composeMailRef.current.setFieldValue("messageBody",new XMLSerializer().serializeToString(doc.documentElement));
            setIsZoomCreating(false);
    }
        
      };
    const mailSender = useCallback(async (values, resetForm, mailType, isInitiatedFromOutSideClickModal=false) => {

        // transform cc and bcc list to simple array 

        const isMessageDraft = mailType === 'draft' ? true : false;
        const transformedCC = values.cc.map(ccItem => ccItem.searchRecipientName);
        const transformedBcc = values.bcc.map(bccItem => bccItem.searchRecipientName);
        const transformedTo = values.to.map(toItem => toItem.searchRecipientName);

        // remove outer p tag as ck editor was automatically wrapping p tags around content, also add figure tag style to prevent layout breaking issue
        let emailContent = addFigureTagStyles(removeOuterMostTag(values.messageBody,'p')); 
            


        // check if any of the email entered is error and show popup else submit form
        const toErrors = [];
        const ccErrors = [];
        const bccErrors = [];
        const isToValid = await Promise.all(transformedTo.map(async (emailItem) => {
            const isItemValid = await emailCheckerSchema.isValid({ emailItem: emailItem });
            if (!isItemValid)
            {
                toErrors.push(emailItem);
            }
            return emailCheckerSchema.isValid({ emailItem: emailItem })
        }))
        const isCcEmailsValid = await Promise.all(transformedCC.map(async (emailItem) => {
            const isItemValid = await emailCheckerSchema.isValid({ emailItem: emailItem });
            if (!isItemValid)
            {
                ccErrors.push(emailItem);
            }
            return emailCheckerSchema.isValid({ emailItem: emailItem })
        }))
        const isBccEmailsValid = await Promise.all(transformedBcc.map(async (emailItem) => {
            const isItemValid = await emailCheckerSchema.isValid({ emailItem: emailItem });
            if (!isItemValid)
            {
                bccErrors.push(emailItem);
            }
            return emailCheckerSchema.isValid({ emailItem: emailItem })
        }))
        const areAllToEmailsValid = isToValid.every(Boolean);
        const areAllCCEmailsValid = isCcEmailsValid.every(Boolean);
        const areAllBccEmailsValid = isBccEmailsValid.every(Boolean);
        const errors = {
            "toErrors": toErrors,
            "ccErrors": ccErrors,
            "bccErrors": bccErrors
        }


        
        // send if everything is ok
        if (!areAllToEmailsValid || !areAllCCEmailsValid || !areAllBccEmailsValid)
        {
            openEmailErrorModalHandler(true);
            setErrorEmailList(errors);
            if (composeMailRef.current)
            {
                const { setSubmitting } = composeMailRef.current;
                setSubmitting(false);
            }
        }
        else
        {
            const formData = new FormData();




            let alreadyExistingAttachments;
            if (initialDataForDrafts && initialDataForDrafts.attachments)
            {
                alreadyExistingAttachments = initialDataForDrafts.attachments.map(item => item.fileId);
            }
            else
            {
                alreadyExistingAttachments = [];
            }



            // filter out local files and cap drive files 
            let localFileAttachments = [];
            let driveFiles = [];


            filesSelectedFromAttachmentModal.forEach(item => {
                if (item instanceof File)
                {
                    localFileAttachments.push(item)
                }
                else
                {
                    driveFiles.push(item.fileId)
                }
            })
            const hasAttachments = (localFileAttachments.length > 0 || driveFiles.length > 0) ? true : false;


            driveFiles.forEach(fileItem => {
                if (alreadyExistingAttachments.includes(fileItem))
                {
                    formData.append('file_id_list', fileItem);
                }
                else
                {
                    formData.append('cap_drive_attachments', fileItem);
                }
            })
            localFileAttachments.forEach(fileItem => {
                formData.append('attachments', fileItem, fileItem.name);
            })




            formData.append('user', currentlyLoggedUserId);
            formData.append('message_sender', currentUserEmail);
            transformedTo.forEach(toItem => {
                formData.append('message_to', toItem);
            })
            transformedCC.forEach(ccItem => {
                formData.append('message_cc', ccItem);
            })
            transformedBcc.forEach(bccItem => {
                formData.append('message_bcc', bccItem);
            })
            formData.append('message_reply_to', currentUserEmail);

            formData.append('message_body', emailContent);

            formData.append('message_subject', values.message_subject);
            formData.append('has_attachments', hasAttachments);
            formData.append('message_is_draft', isMessageDraft);
            formData.append('template_id', values.template);
            formData.append('category_id', values.category);

            // when draft saved is edited object id needs to me passed
            if (objectId)
            {
                formData.append('object_id', objectId);
            }
            // else
            // {
            //     formData.append('object_id', '');
            // }


            // this is not taken from form field, extra static params requested by backend team
            formData.append('mail_action', MAIL_SENDING_ACTIONS[0].id); 
            
            // this is not taken from form field, extra static params requested by backend team
            formData.append('reply_id', '');

            formData.append('watchable_attachment', values.watchAttachments); 


            // after sending mail both mailleft and mailright needs to refresh on nomral compose mail mail this is not needed
            dispatch(composeMailAsync(formData, {
                folderId: folderId,
                mailId: mailId,
                messageDraftStatus: messageDraftStatus,
                isInitiatedFromOutSideClickModal : isInitiatedFromOutSideClickModal,  // if true 
                limit: dataLimit,
                offset: mailsInFolderOffset ? mailsInFolderOffset : 0
            }));

        }
    }, [composeMailRef.current]);


    const [isDiscardModalShown, setIsDiscardModalShown] = useState(false);
    const [editorFunction, setEditorFunction] = useState(null);
    const [savingToDraft, setIsSavingToDraft] = useState(false);


    // showing success and removing success messages from store 
    useEffect(() => {
        if (composeMailError)
        {
            enqueueSnackbar(composeMailError, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                onExit: () => {
                    dispatch(removeMailErrorMessage())
                }
            });
        }
        if (mailCategoriesError)
        {
            enqueueSnackbar(mailCategoriesError, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                onExit: () => {
                    dispatch(removeMailCategoriesError())
                }
            });
        }
        if (composeMailData)
        {

            enqueueSnackbar(composeMailData, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                onEnter: () => {

                    if(composeMailRef.current)
                    {
                        const { resetForm } = composeMailRef.current;
                        resetForm();
                    }
                    
                    setHasSubmitCompleted(true);
                    

                    setTemplateData({
                        emailTemplateHeader: '',
                        emailContent: '',
                        emailTemplateFooter: '',
                    });

                    if (editorFunction)
                    {
                        editorFunction.setData(""); // for reseting composer
                    }

                    dispatch(removeIndivigualFileItems([]))
                    setComposeMailView(false);
                },
                onExit: () => {
                    dispatch(removeMailSuccessMessage())
                }
            });
        }
    }, [mailCategoriesError, composeMailData, composeMailError])


    // Close draft modal ( that comes when clicking button) when draft has completed saving -- 
    useEffect(() => {
        if (!composeMailLoading)
        {
            setIsDiscardModalShown(false)
        }
    }, [composeMailLoading])




    const checkComposeMailDirty = () => {
        if(composeMailRef.current)
        {
            const { dirty } = composeMailRef.current;
            return dirty;
        }
    }


    useImperativeHandle(composeComponentRef, () =>({
        checkComposeMailDirty: checkComposeMailDirty,
    }),[composeMailRef.current])



    useEffect(() => {
        if (composeMailRef.current  && composeMailLoading === false)
        {
            const { setSubmitting } = composeMailRef.current;
            setSubmitting(false);
        }
    }, [composeMailLoading])
    const currentTemplateData=useMemo(()=>{
       return templatesData?templatesData?.find(templateItem => {
        const th=templateItem.id === composeMailRef?.current?.values?.template
        return th
    }):false;
    },[composeMailRef?.current?.values?.template])
    const makeFileObjectFromFileURL = useCallback( async (fileDetails) => {
        try
        {
            const { attachmentDetails, categoryName, templateName } = fileDetails;
            const fileObjectPromiseArray = attachmentDetails.map( async urlItem => {
                let fileName
                if(urlItem?.doc_name){
                    fileName = getFileNameFromLink(urlItem?.doc_name);
                }else{
                    fileName = getFileNameFromLink(urlItem.item_path);
                }
                if(fileName === null || fileName === undefined)
                {
                    const fileExtension = getFileExtension(urlItem.item_path);
                    fileName = `${toLowercaseAndRemoveSpaces(categoryName)}_${toLowercaseAndRemoveSpaces(templateName)}.${fileExtension}`;
                }
                const data = await fetch(urlItem.item_path);
                const blob = await data.blob();
                const fileObject = convertBlobToFile(blob,fileName);
                fileObject.isSystemGenerated = urlItem.watchable_attachment;
                return fileObject;
            })
            return await Promise.all(fileObjectPromiseArray);
        }
        catch (err)
        {
            console.dir(err)
            enqueueSnackbar('Failed to set attachments, Please try again.', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                }
            });
        }
    },[]);


    return (
        <>
            {isAttachmentsFetching && <LinearProgress color="secondary"/>}
            {/* For drafts initialdata will be passed */}
            <Formik
                // enableReinitialize
                innerRef={composeMailRef}
                initialValues={initialDataForDrafts ? initialDataForDrafts : {
                    to: [],
                    category: '',
                    template: '',
                    message_subject: '',
                    cc: [],
                    bcc: [],
                    messageBody: '',
                    watchAttachments : false
                }}
                validationSchema={replayMailSchema}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                    setSubmitting(true)
                    mailSender(values, resetForm, 'newmail')
                }}
            >
                {({ values, errors, setFieldValue, resetForm, dirty, submitForm, isSubmitting }) => {
                    return (
                        <>
                            <Form className="mailForm composeMailForm">
                                <div className="formWrapper">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <div
                                                className="cccBccWrapper"
                                            >
                                                <Field
                                                    component={MultiSelect}
                                                    name="to"
                                                    label="To"
                                                    required
                                                    className="mailToMultiSelect"
                                                    options={searchMailRecipientsData}
                                                    onInputChange={(e, value, reason) => {
                                                        dispatch(searchMailRecipientsAsync(value));
                                                    }}
                                                    loading={isSearchMailReceiptLoading}
                                                    optionLabel="searchRecipientName"
                                                    creatable
                                                    clearOnBlur
                                                    placeholder={false}
                                                />

                                                <span className="cssBccItems">
                                                    <p onClick={() => setIsCCShown(prevState => {
                                                        // only hide cc if there is no value inside bcc
                                                        if (values.cc.length > 0)
                                                        {
                                                            return prevState
                                                        }
                                                        else
                                                        {
                                                            return !prevState
                                                        }
                                                    })}>
                                                        CC
                                                                                        </p>
                                                    <p onClick={() => setIsBCCShown(prevState => {
                                                        // only hide bcc if there is no value inside bcc
                                                        if (values.bcc.length > 0)
                                                        {
                                                            return prevState
                                                        }
                                                        else
                                                        {
                                                            return !prevState
                                                        }
                                                    })}>
                                                        BCC
                                                    </p>
                                                </span>
                                            </div>
                                        </Grid>


                                        {
                                            isCCShown &&
                                            <Grid item xs={12}>
                                                <Field
                                                    component={MultiSelect}
                                                    name="cc"
                                                    label="Cc"
                                                    required
                                                    className="mailToMultiSelect"
                                                    options={searchMailRecipientsData}
                                                    onInputChange={(e, value, reason) => {
                                                        dispatch(searchMailRecipientsAsync(value));
                                                    }}
                                                    loading={isSearchMailReceiptLoading}
                                                    optionLabel="searchRecipientName"
                                                    creatable
                                                    clearOnBlur
                                                    placeholder={false}
                                                />
                                            </Grid>
                                        }

                                        {
                                            isBCCShown &&
                                            <Grid item xs={12}>
                                                <Field
                                                    component={MultiSelect}
                                                    name="bcc"
                                                    label="Bcc"
                                                    required
                                                    className="mailToMultiSelect"
                                                    options={searchMailRecipientsData}
                                                    onInputChange={(e, value, reason) => {
                                                        dispatch(searchMailRecipientsAsync(value));
                                                    }}
                                                    loading={isSearchMailReceiptLoading}
                                                    optionLabel="searchRecipientName"
                                                    creatable
                                                    clearOnBlur
                                                    placeholder={false}
                                                />
                                            </Grid>
                                        }

                                        
                                        {/* When url doesnt have action=search and  initialDataForDrafts.messageIsDraft is true, and when replay_id is null   */}

                                        {
                                            isEmpty(search) && initialDataForDrafts && initialDataForDrafts.messageIsDraft  && !initialDataForDrafts.replay_id
                                                ?
                                                <>
                                                    <Grid item xs={12} md={6}>
                                                        <Field
                                                            component={SingleSelect}
                                                            name="category"
                                                            label="Select Category"
                                                            placeHolder="Select Category"
                                                            variant="outlined"
                                                            options={mailCategoriesData}
                                                            optionLabel="temp_category_name"
                                                            required
                                                            onChange={(e) => {
                                                                const selectedItem = e.target.value;
                                                                if (selectedItem === '')
                                                                {
                                                                    setFieldValue('message_subject', '');
                                                                    setFieldValue('template', '');
                                                                    setFieldValue('messageBody', '');
                                                                    setTemplateData({
                                                                        emailTemplateHeader: '',
                                                                        emailContent: '',
                                                                        emailTemplateFooter: '',
                                                                    });
                                                                    dispatch(clearTemplateData())
                                                                }
                                                                else
                                                                {
                                                                    dispatch(fetchMailTemplatesAsync(selectedItem))
                                                                    setFieldValue('message_subject', '');
                                                                    setFieldValue('template', '');
                                                                    setFieldValue('messageBody', '');
                                                                    setTemplateData({
                                                                        emailTemplateHeader: '',
                                                                        emailContent: '',
                                                                        emailTemplateFooter: '',
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={6}>
                                                        <Field
                                                            component={SingleSelect}
                                                            name="template"
                                                            label="Select Template"
                                                            placeHolder="Select Template"
                                                            variant="outlined"
                                                            options={templatesData}
                                                            optionLabel="template_name"
                                                            required
                                                            disabled={mailCategoriesLoading}
                                                            onChange={(e) => {
                                                                const currentItem = e.target.value;
                                                                const currentSelectedItem = templatesData.find(templateItem => templateItem.id === currentItem)
                                                                if (currentSelectedItem)
                                                                {
                                                                    setFieldValue('message_subject', currentSelectedItem.subject);
                                                                    setFieldValue('messageBody', currentSelectedItem.content);

                                                                    setTemplateData({
                                                                        emailTemplateHeader: currentSelectedItem.template_header,
                                                                        emailContent: currentSelectedItem.content,
                                                                        emailTemplateFooter: currentSelectedItem.template_footer,
                                                                    });

                                                                }
                                                                else
                                                                {
                                                                    setFieldValue('message_subject', '');
                                                                    setFieldValue('messageBody', '');

                                                                    setTemplateData({
                                                                        emailTemplateHeader: '',
                                                                        emailContent: '',
                                                                        emailTemplateFooter: '',
                                                                    });
                                                                }

                                                            }}
                                                        />
                                                    </Grid>


                                                    <Grid item xs={12}>
                                                        <Field
                                                            component={Input}
                                                            name="message_subject"
                                                            label="Subject"
                                                            variant="outlined"
                                                        />
                                                    </Grid>

                                                </>
                                                :
                                                isEmpty(search) 
                                                    ?
                                                    null
                                                    :
                                                    <>
                                                        <Grid item xs={12} md={6}>
                                                            <Field
                                                                component={SingleSelect}
                                                                name="category"
                                                                label="Select Category"
                                                                placeHolder="Select Category"
                                                                variant="outlined"
                                                                options={mailCategoriesData}
                                                                optionLabel="temp_category_name"
                                                                required
                                                                onChange={(e) => {
                                                                    // remove previously set attachments 
                                                                    dispatch(removeIndivigualFileItems([]));
                                                                    setFieldValue('watchAttachments', false);
                                                                    const selectedItem = e.target.value;
                                                                    if (selectedItem === '')
                                                                    {

                                                                        setFieldValue('message_subject', '');
                                                                        setFieldValue('template', '');
                                                                        setFieldValue('messageBody', '');
                                                                        setTemplateData({
                                                                            emailTemplateHeader: '',
                                                                            emailContent: '',
                                                                            emailTemplateFooter: '',
                                                                        });
                                                                        dispatch(clearTemplateData()) 
                                                                    }
                                                                    else
                                                                    {
                                                                        dispatch(fetchMailTemplatesAsync(selectedItem))
                                                                        setFieldValue('message_subject', '');
                                                                        setFieldValue('template', '');
                                                                        setFieldValue('messageBody', '');
                                                                        setTemplateData({
                                                                            emailTemplateHeader: '',
                                                                            emailContent: '',
                                                                            emailTemplateFooter: '',
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Field
                                                                component={SingleSelect}
                                                                name="template"
                                                                label="Select Template"
                                                                placeHolder="Select Template"
                                                                variant="outlined"
                                                                options={templatesData}
                                                                optionLabel="template_name"
                                                                required
                                                                disabled={mailCategoriesLoading}
                                                                onChange={async (e) => {
                                                                    const currentItem = e.target.value;
                                                                    const currentSelectedItem = templatesData.find(templateItem => templateItem.id === currentItem);
                                                                    const currentSelectedCategory = mailCategoriesData.find(categoryItem => categoryItem.id === values.category);

                                                                    // remove previously set attachments 
                                                                    dispatch(removeIndivigualFileItems([]));

                                                                    if (currentSelectedItem)
                                                                    {
                                                                        setFieldValue('message_subject', currentSelectedItem.subject);
                                                                        setFieldValue('messageBody', currentSelectedItem.content);
                                                                        setFieldValue('watchAttachments', false);
                                                                        setTemplateData({
                                                                            emailTemplateHeader: currentSelectedItem.template_header,
                                                                            emailContent: currentSelectedItem.content,
                                                                            emailTemplateFooter: currentSelectedItem.template_footer,
                                                                        });
                                                                        // set template attachments 
                                                                        if(!isEmpty(currentSelectedItem.template_attachment_path))
                                                                        {
                                                                            setIsAttachmentsFetching(true);
                                                                            const fileDetails = {
                                                                                attachmentDetails : currentSelectedItem.template_attachment_path,
                                                                                categoryName : currentSelectedCategory.temp_category_name,
                                                                                templateName : currentSelectedItem.template_name
                                                                            }
                                                                            const fileObjectArray = await makeFileObjectFromFileURL(fileDetails);
                                                                            if(fileObjectArray)
                                                                            {
                                                                                const isAnyFileWatchable = currentSelectedItem.template_attachment_path.some( item => item.watchable_attachment );
                                                                                setFieldValue('watchAttachments', isAnyFileWatchable);
                                                                                dispatch(setFilesSelectedFromAttachmentModal(fileObjectArray));  
                                                                            }
                                                                            setIsAttachmentsFetching(false);
                                                                        }
    
                                                                    }
                                                                    else
                                                                    {
                                                                        setFieldValue('message_subject', '');
                                                                        setFieldValue('messageBody', '');
                                                                        setTemplateData({
                                                                            emailTemplateHeader: '',
                                                                            emailContent: '',
                                                                            emailTemplateFooter: '',
                                                                        });
                                                                        setFieldValue('watchAttachments', false);
                                                                    }

                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                component={Input}
                                                                name="message_subject"
                                                                label="Subject"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        {
                                                            currentTemplateData&&<Grid item xs={12} md={10}>
              <Button
                          width="170px"
                          bgColor="secondary"
                          disabled={isZoomCreating}
                          onClick={(e) => {
                            handleInsertZoomLinkAtLine(currentTemplateData)
                            }}
                          className="insertZoomlink btn"
                        >
                          {isZoomLinkPresent ? 'Remove Zoom Link' : 'Attach Zoom Link'} {isZoomCreating&&<CircularProgress size={12} style={{ color: "#32C2D9",marginLeft:"3px" }}/>}
                        </Button>
              </Grid>
                                                        }
                                                    </>
                                        }




                                        {/* composer sections starts */}

                                        <Grid item xs={12}>
                                            <Box className="editorWrapper">

                                                {templateData.emailTemplateHeader !== '' && <div className="header emailTemplateHeader" dangerouslySetInnerHTML={{ __html: templateData.emailTemplateHeader }} />}

                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data={values.messageBody || ''}
                                                    config={{
                                                        mediaEmbed: {
                                                            previewsInData: true,
                                                        },
                                                        removePlugins: ['TableToolbar'],
                                                        toolbar: toolbarItems,
                                                        extraPlugins: [customElementStylesPreservingPlugin, CustomFigureAttributes],
                                                    }}
                                                    disabled={false}
                                                    onInit={editor => {

                                                        setEditorFunction(editor);

                                                        editor.editing.view.change(writer => {
                                                            writer.setStyle(
                                                                "min-height",
                                                                '120px',
                                                                editor.editing.view.document.getRoot()
                                                            );
                                                        });

                                                        editor.editing.view.document.on( 'enter', ( evt, data ) => {
                                                            if ( data.isSoft ) {
                                                                editor.execute( 'enter' );
                                                            } else {
                                                                editor.execute( 'shiftEnter' );
                                                                        }
                                                
                                                            data.preventDefault();
                                                            evt.stop();
                                                            editor.editing.view.scrollToTheSelection();
                                                        }, { priority: 'high' } );
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setFieldValue('messageBody', data);
                                                        setTemplateContentForPreview(data);
                                                    }}
                                                />

                                                {templateData.emailTemplateFooter && <div className="footer emailTemplateFooter" dangerouslySetInnerHTML={{ __html: templateData.emailTemplateFooter }} />}
                                            </Box>
                                        </Grid>

                                        {/* composer section ends */}


                                        <Grid item xs={12}>

                                            <Box className="emailFooterSection">
                                                <Grid item xs={12} container justify="space-between" alignItems="center" >
                                                    <Grid item xs={12}>
                                                        {
                                                            totalAttachmentFileSize > maxFileAttachmentSize
                                                                ?
                                                                <p className="attachmentSizeExceedMsg">{attachmentSizeExceededMessage} </p>
                                                                :
                                                                null
                                                        }
                                                    </Grid>

                                                    {
                                                        templateAttachments.length > 0 
                                                        && 
                                                        <FileAttachmentPreview 
                                                            files={templateAttachments}
                                                            showDownloadIcon={false}
                                                            showCloseIcon={false}
                                                        />
                                                    }


                                                    {
                                                        regularAttachments.length > 0 
                                                        && 
                                                        <FileAttachmentPreview 
                                                            files={regularAttachments}
                                                            showDownloadIcon={false}
                                                            customRemoveFilesHandler={(index)=>{
                                                                const itemToDelete = regularAttachments.find((item, i) => i === index);
                                                                const indexOfItemToDelete = filesSelectedFromAttachmentModal.findIndex(item => item.name == itemToDelete.name);
                                                                const newFileArray = [...filesSelectedFromAttachmentModal];
                                                                newFileArray.splice(indexOfItemToDelete, 1);
                                                                dispatch(removeIndivigualFileItems(newFileArray));
                                                            }}
                                                        />
                                                    }


                                                    <Grid item xs={12} md={4} className="attachmentIconWrapper">
                                                        <div className="attachIcon">
                                                            <AttachFileIcon
                                                                className="attachmentIcon"
                                                                onClick={() => {
                                                                    dispatch(openCloseAttachmentModal(true));
                                                                }}
                                                            />
                                                            <p>{attachmentLabelMsg}</p>
                                                        </div>
                                                    </Grid>
                                                    <Grid container item xs={12} md={8} spacing={2} justify="flex-end">
                                                        <Grid item xs={12} sm={6} md={4}>

                                                            {
                                                                (isMessageDraft(values, setMessageAsDraft) && (values.category !== '' && values.template !== ''))
                                                                    ?
                                                                    <Button
                                                                        buttonType="button"
                                                                        variant="contained"
                                                                        type="button"
                                                                        customStyle={previewButtonStyle}
                                                                        onClick={() => {
                                                                            setOpenPreviewModal(true);
                                                                        }}
                                                                    >
                                                                        Preview
                                                                    </Button>
                                                                    :
                                                                    <>
                                                                        {
                                                                            (dirty || filesSelectedFromAttachmentModal.length > 0) ?
                                                                                <Button
                                                                                    buttonType="button"
                                                                                    variant="contained"
                                                                                    type="button"
                                                                                    customStyle={previewButtonStyle}
                                                                                    onClick={() => setIsDiscardModalShown(true)}
                                                                                >
                                                                                    Discard
                                                                                </Button>
                                                                                : null
                                                                        }
                                                                    </>

                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4}>
                                                            <Button
                                                                bgColor="secondary"
                                                                variant="contained"
                                                                type="button"
                                                                disabled={(isSubmitting && savingToDraft === false) || hasTotalAttachmentsSizeCrossed(maxFileAttachmentSize, totalAttachmentFileSize) || isAttachmentsFetching} // also check if attachment size has exceeded
                                                                isLoading={(isSubmitting && savingToDraft === false)}
                                                                onClick={() => {
                                                                    submitForm();
                                                                    setIsSavingToDraft(false);
                                                                }}
                                                            >
                                                                Send
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Form>



                            {/* Opens when form is dirty or attachments has been choosen  */}
                            <NavigationPrompt when={(dirty === true && hasSubmitCompleted === false) || filesSelectedFromAttachmentModal.length > 0}>
                                {(isOpen, onConfirm, onCancel, closeModal) => {
                                    return (
                                        
                                        <Modal open={isOpen} handleClose={() => onCancel()} size="xs" className="messageDraftModal" showDefaultCloseIcon>
                                            
                                            <DialogContent>
                                                <h3 className="customHead">Discard Email ?</h3>
                                                <p className="discardMessage customDescription">Do you want to save this mail as draft ?</p>

                                                {
                                                    totalAttachmentFileSize > maxFileAttachmentSize
                                                        ?
                                                        <p className="attachmentSizeExceedMsg">{attachmentSizeExceededMessage} </p>
                                                        :
                                                        null
                                                }

                                                <DialogActions className="modalFooter">
                                                    <Button
                                                        onClick={() => {
                                                            const { values, resetForm } = composeMailRef.current;
                                                            mailSender(values, resetForm, 'draft', true);
                                                            // for reseting composer
                                                            if (editorFunction)
                                                            {
                                                                editorFunction.setData(""); 
                                                            }
                                                            onConfirm();
                                                        }}
                                                        isLoading={composeMailLoading}
                                                        color="primary" autoFocus bgColor="secondary" width='150px' customStyle={{ marginRight: '20px' }}
                                                        disabled={composeMailLoading || hasTotalAttachmentsSizeCrossed(maxFileAttachmentSize, totalAttachmentFileSize)}
                                                    >
                                                        {/* In drafts page text should be just save */}
                                                            Save {initialDataForDrafts == null && 'to draft'}
                                                    </Button>
                                                    <Button onClick={() => {
                                                        // for reseting composer
                                                        if (editorFunction)
                                                        {
                                                            editorFunction.setData(""); 
                                                        }
                                                        onConfirm();
                                                        closeModal();
                                                        setComposeMailView();
                                                    }} color="primary" customStyle={{ background: 'white', marginRight: '10px' }} width='150px'>
                                                        Discard
                                                    </Button>
                                                </DialogActions>
                                            </DialogContent>
                                        
                                        </Modal>
                                    )
                                }}
                            </NavigationPrompt>
                        </>
                    );
                }}
            </Formik>




            <Modal open={isDiscardModalShown} handleClose={() => setIsDiscardModalShown(false)} size="xs" className="messageDraftModal" showDefaultCloseIcon>
                <DialogContent>
                    <h3>Discard ?</h3>
                    <p className="discardMessage">Are you sure you want to discard this draft ? </p>

                    {
                        totalAttachmentFileSize > maxFileAttachmentSize
                            ?
                            <p className="attachmentSizeExceedMsg">{attachmentSizeExceededMessage}</p>
                            :
                            null
                    }

                    <DialogActions className="modalFooter">
                        <Button
                            onClick={() => {
                                const { resetForm, values } = composeMailRef.current;
                                resetForm();
                                // for reseting composer
                                if (editorFunction)
                                {
                                    editorFunction.setData(""); 
                                }
                                setIsDiscardModalShown(false);
                                setComposeMailView(false);
                                dispatch(removeIndivigualFileItems([]))
                            }}
                            color="primary" autoFocus bgColor="secondary" width='150px' customStyle={{ marginRight: '20px' }}
                        >
                            Ok
                        </Button>

                        <Button onClick={() => setIsDiscardModalShown(false)} color="primary" customStyle={{ background: 'white', marginRight: '10px' }} width='150px'>
                            Cancel
                        </Button>

                        <Button onClick={() => {
                            // this is to avoid showing loader in send button, we are using same api call for draft and send
                            setIsSavingToDraft(true); 
                            const { resetForm, values } = composeMailRef.current;
                            mailSender(values, resetForm, 'draft');
                            // for reseting composer
                            if (editorFunction)
                            {
                                editorFunction.setData(""); 
                            }

                        }}
                            color="primary"
                            customStyle={{ backgroundColor: 'white', marginRight: '10px' }}
                            width='150px'
                            isLoading={composeMailLoading}
                            disabled={composeMailLoading || hasTotalAttachmentsSizeCrossed(maxFileAttachmentSize, totalAttachmentFileSize)}
                        >
                            {/* In drafts page text should be just save */}
                            Save {initialDataForDrafts == null && 'to draft'}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Modal>




            {/* Email preview Modal */}
            <Modal open={openPreviewModal} handleClose={handleClose} size="sm" className="emailPreviewModal modalPaddingRemover">
                <DialogContent>
                    <div className="emailContentWrapper">
                        {
                            (templateData.emailTemplateHeader !== '' && templateData.emailTemplateFooter !== '')
                                ?
                                <div className="emailView emailPreviewContent">
                                    <div style={{ width: '100%' }} dangerouslySetInnerHTML={{
                                        __html: emailTemplateCustomiser(templateData.emailTemplateHeader, templateContentForPreview, `<span class="emailFooterPreview">${templateData.emailTemplateFooter}</span>`)
                                    }} />
                                </div>
                                :
                                <div className="emailView emptyContent"><p>No email view.</p></div>
                        }

                    </div>

                    <DialogActions className="modalFooter">
                        <div className="fileAttachmentWrapper">
                            {
                                filesSelectedFromAttachmentModal.length > 0 &&
                                <FileAttachmentPreview
                                    files={filesSelectedFromAttachmentModal}
                                    showCloseIcon={false}
                                />
                            }

                        </div>
                        <div className="modalFooterWrapper">
                            <Button onClick={handleClose} color="primary" autoFocus bgColor="secondary" width='150px' >
                                Edit
                            </Button>
                        </div>

                    </DialogActions>
                </DialogContent>
            </Modal>


        </>

    )
}

export default ComposeMailForm
