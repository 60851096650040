import React, { useEffect, useMemo, useRef, useState,useCallback } from 'react'
import './DashboardContent.scss'

import DashbaordMailView from '../Dashboard/DasboardMailView/DashboardMailView';
import Modal from '../Modal/Modal';
import { axiosGet } from '../../utils/api';
import { debounce, isNOTNullOrUndefined, truncateString } from '../../utils/utils';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useLocation } from 'react-router-dom';
import { Treemap, Tooltip, ResponsiveContainer } from "recharts";
import { CustomTreeComponent } from "./CustomTreeComponent";
import AddandEditEventModal from '../Calendar/AddEditModal/AddEditModal';
import '../Calendar/CalendarMain/CalendarMain.scss';
import moment from 'moment';
import RenderTooltip from "./RenderTooltip";
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import DialogContent from "@material-ui/core/DialogContent";
import { Autocomplete} from "@mui/material";
import { memoize } from 'lodash';
import Button from "../../components/Button/Button";
import DialogActions from "@material-ui/core/DialogActions";
import usePermissionCheck from '../../hooks/usePermissionCheck';
import CircularLoader from '../CircularLoader/CircularLoader';
import { useDispatch, useSelector } from 'react-redux';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import Tab from '../../components/TabsComponent/Tab/Tab';
import Tabs from '../../components/TabsComponent/Tabs/Tabs';
import TabPanel from '../../components/TabsComponent/TabPanel/TabPanel';
import { fetchSocialMediums, setCurrentClientType } from '../../redux/dashboard/dashboard.actions';
import { DialogTitle, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Slider, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { fetchEventDataAsync, markParticipationAsync, removeEventData, deleteOrCancelEvent, fetchEventListAsync, clearAddEditEventSuccessMessage, clearEditEventErrorMessage, clearAddEventErrorMessage } from '../../redux/calendar/calendar.actions';
import { setFilesSelectedFromAttachmentModal, removeIndivigualFileItems } from '../../redux/ui/ui.actions';
import { useHistory } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Add, Remove } from "@mui/icons-material";
import { GenerateData } from './data';
import SelectFieldReact from '../SelectField/SelectFieldReact';
import appConfig from '../../appConfig.json';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'; 
import ScheduleMeetingForm from './ScheduleMeetingForm';
import { fetchGET } from '../../services/FeeMatchingService';
import RecordedMeeting from './RecordMeeting';
import { fetchStaffAsync } from '../../redux/tasks/tasks.actions';
import { makeStyles } from '@material-ui/core/styles';
import {  KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import PropTypes from 'prop-types';
import { TablePagination } from '@material-ui/core';

const useStyles = makeStyles(theme => {
  return {
      root: {
                  '& label.Mui-focused': {
                      color: theme.palette.secondary.main
                  },
                  '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                          borderColor: theme.palette.secondary.main
                      },
                  },
                  "& .MuiAutocomplete-popupIndicator": 
                  { 
                      color: theme.palette.secondary.main,
                      transform: "none" 
              
                  },
                  "& .MuiOutlinedInput-input":{
                      height:"0.9em !important"
                  }
              }
          ,
      
      detailsCircle: {
          background: theme.palette.secondary.main
      },
      vulnerableRestrictedCircle:{
          backgroundImage:"linear-gradient(to right, #e65050ff, #e6c250ff)"
      },
      vulnerableCircle:{
          backgroundColor:"#e65050ff"
      },
      restrictedCircle:{
          backgroundColor: "#e6c250ff"
      },
    
      buttonSubmit:{
        display:"flex !important",
        justifyContent:"flex-end !important",
      },
      clearIndicator: {
          padding: '2px',  // 4px padding for clear indicator
        },
        popupIndicator: {
          padding: '2px',  // 2px padding for popup indicator
        },
  }
});
function CustomSelect({selectedOption,handleOptionChange,options}) {

  const [isOpen, setIsOpen] = useState(false);
  return (
    <div style={{ position: "relative", width: "auto" }}>
      <div
        style={{
          backgroundColor: "#32c2d9",
          color: "#fff",
          padding: "4px",
          borderRadius: "5px",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
      { !selectedOption? <span>Past Meeting</span>:<span>{selectedOption}</span>}
        <span
          style={{
            marginLeft: "10px",
            transition: "transform 0.3s",
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
          }}
        >
          <KeyboardArrowDownIcon style={{color: "#fff",}}/>      
            </span>
      </div>
      {<Select
        value={selectedOption}
        onChange={(value)=>handleOptionChange(value)}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          opacity:"0",
          width: "100%",
          height: "100%",
          cursor: "pointer",
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
      >
        {options&&options?.length&&options.map((options, index) => (
          <MenuItem key={index} value={options.id} style={{ display: "block",padding:"0px 16px 7px 16px" }}>
            {options.name}
          </MenuItem>
        ))}
      </Select>}

      
    </div>
  );
}


const clientMailvalue = {
    client_mail: ['shamil@techversantinfotech.com'],
    mail_cc: ['saamil@gmail.com', 'azasda@gmail.com'],
    mail_bcc: ['samil@gmail.com', 'afasfa@gmail.com'],
    subject: 'hey there new subject',
    content: 'dasdas asd asda '
}

const sortOptions = [
    '87FFB9',
    '42C77A',
    '328956',
    'FFA498',
    'D91E1E',
]

const filterOptions = [
    { id: 'all', name: "All Clients" },
    { id: 'Fidelity', name: 'Fidelity' },
    { id: 'Non_Fidelity', name: 'Non Fidelity' },
    { id: 'Fidelity_plus_Non_Fidelity', name: 'Fidelity + Non Fidelity' },
]
export const ReusableTablePagination = ({
  totalCount,
  offset,
  limit,
  getFilterList,
}) => {
  const [page, setPage] = useState(Math.floor(offset / limit));
  const [rowsPerPage, setRowsPerPage] = useState(limit);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const params = {
      limit: rowsPerPage,
      offset: newPage * rowsPerPage,
    };
    getFilterList(params);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    const params = {
      limit: newRowsPerPage,
      offset: 0,
    };
    getFilterList(params);
  };

  const TablePaginationActions = (props) => {
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = () => {
      onChangePage(null, 0);
    };

    const handleBackButtonClick = () => {
      onChangePage(null, page - 1);
    };

    const handleNextButtonClick = () => {
      onChangePage(null, page + 1);
    };

    const handleLastPageButtonClick = () => {
      onChangePage(null, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div style={{ display: "inline-flex" }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  };

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25, 35]}
      component="div"
      count={totalCount}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
    />
  );
};

ReusableTablePagination.propTypes = {
  totalCount: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  getFilterList: PropTypes.func.isRequired,
};
const ProfileMangementContent = () => {
    const dispatch = useDispatch();
    const customStyles = useStyles();
    const [tabValue, setTabValue] = useState(0);
    const [selectedOption, setSelectedOption] = useState("");
    const [scheduleFormOpen, setScheduleFormOpen] = useState(false);
    const [meetingTypeOption, setMeetingTypeOption] = useState([])
    const handleMeetingTypeChange = (event) => {
      setSelectedOption(event.target.value);
      setScheduleFormOpen(true);
    };
    
    const [totalCount, setTotalCount] = useState(0); // State to store total count of items
    const [offset, setOffset] = useState(0); // State to track the current offset
    const [limit, setLimit] = useState(10);
    const [currentLoggedInAdvisor,setCurrentLoggedInAdvisor]=useState()
    const staffList = useSelector(state => state.tasks.staffList);
    const userToken = useSelector((state) => state.auth.currentUserData.token);
    const is_fetching_socials = useSelector(state => state.dashboard.is_Fetching_social_mediums);
    const social_mediums = useSelector(state => state.dashboard.social_medium_data);
    const currentUserData = useSelector(state => state.auth.currentUserData);
    const clientDetails = useSelector(state => state.client?.clientDetails);
    const eventData = useSelector(state => state.calendar.eventData);
    const addEventData = useSelector(state => state.calendar.addEventData);
    const newEditData = useSelector(state => state.calendar.newEditData);
    const addEventSuccessMessage = useSelector(state => state.calendar.addEventSuccessMessage);
    const editEventSuccessMessage = useSelector(state => state.calendar.editEventSuccessMessage);
    const editErrorMessage = useSelector(state => state.calendar.editErrorMessage);
    const addEventErrorMessage = useSelector(state => state.calendar.addEventErrorMessage);
    const [showMailModal, setShowMailModal] = useState(false)
    const [next, setnext] = useState(true)
    const [deleteProvider,setDeleteProvider] = useState(false)
    const [sortColor, setSortColor] = useState('')
    const [sortOrder, setSortOrder] = useState('')
    const [loading, setLoading] = useState(false);
    const [scheduleMeetingList,setScheduleMeetingList]=useState([]);
    const [scheduleMeetingListFetching,setScheduleMeetingListFetching]=useState([]);
    const [isAddEVentModalOpen, setIsAddEventModalOpen] = useState({ status: false, isOpenForEditing: false });
    const [data, setData] = useState([])
    const [meetingSchedule, setMeetingSchedule] = useState({})
    const [redirectRequired, setRedirectRequired] = useState(false);
    const [offsetValue, setOffsetValue] = useState(0)
    const [openEventId, setOpenEventId] = useState(null)
    const [selectedCalendarInfo, setSelectedCalendarInfo] = useState(null);
    const [itemRemoved, setItemRemoved] = useState('');
    const [isScheduleMeeting, setIsScheduleMeeting] = useState(false)
    const [modalClosed, setModalClosed] = useState(false);
    const [openEmailErrorModal, setOpenEmailErrorModal] = useState(false);
    const [errorEmailList, setErrorEmailList] = useState([]);
    const [selectedCient, setSelectedClient] = useState(null)
    const [selectedFilter, setSelectedFilter] = useState('all')
    const [zoomLevel, setZoomLevel] = useState(100);
    const [widthValue, setWidthValue] = useState(200); // Example initial width
     const [heightValue, setHeightValue] = useState(150)
     const [containerWidth, setContainerWidth] = useState(0); // Store container dimensions
     const [containerHeight, setContainerHeight] = useState(0);
     const [selectedClientId, setSelectedClientId] = useState(null);
     const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
     const [rawData, setRawData] = useState([]); 
     const [selectedAdvisor, setSelectedAdvisor] = useState(null);
     const [selectedBoxPosition, setSelectedBoxPosition] = useState({ x: 0, y: 0 });



    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation()
    const client_type_id = location.state
    if (isNullOrUndefined(client_type_id)) {
        dispatch(setCurrentClientType(''))
    }
    const controller = new AbortController();
    const history = useHistory();


    const fetchMeetingType = () => {
        axiosGet('meeting-types/').then(res => {
          if (res?.data?.data) {
            setMeetingTypeOption(res.data.data)
          }
        })
      }

   
    const cardParentRef = useRef(null);
    const contentRef = useRef(null);
   
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [scrollTop, setScrollTop] = useState(0);
    const [isZoomeds, setIsZoomeds] = useState(false);
    useEffect(() => {
      setIsZoomeds(zoomLevel > 200);
    }, [zoomLevel]);

    const calendarRef = useRef();
    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
      setScheduleFormOpen(false)
      dispatch(removeIndivigualFileItems([]))
  };
  const handleFilterList = (params) => {
    setOffset(params.offset); // Update the offset state
    setLimit(params.limit); // Update the limit state
    getRecordedMeetingList(params); // Fetch data with the new limit and offset
  };
    const getRecordedMeetingList=async(params={limit:10,offset:0})=>{
      setScheduleMeetingListFetching(true)
     const {limit,offset}=params;
      try{
        const fetchUrl="clientmeeting";
        const response=await fetchGET(userToken,fetchUrl,{client_id:selectedCient?.client_id,limit,offset})
        setScheduleMeetingList(response.data)
        setTotalCount(response.count)
        setOffset(offset); // Update the offset state
        setLimit(limit);
      }
      catch{
         
      }
      setScheduleMeetingListFetching(false)
    }
    const setErrorToast = (errMessage) => {

        enqueueSnackbar(errMessage, {
            variant: 'error',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
        });
    }
    useEffect(()=>{
      if(staffList?.length>0)
      staffList.forEach((option)=>{
        if(option?.id == currentUserData?. staff_id){
          setCurrentLoggedInAdvisor({display:"Me",id:option?.id})
        }
      })
    } ,[staffList]);
    useEffect(()=>{
      const condition= true;
      if(currentUserData && currentUserData?.company?.id){
          dispatch(fetchStaffAsync(currentUserData.company.id,'',condition))
    }} ,[currentUserData]);
    useEffect(()=>{
        if(addEventSuccessMessage)
        {
                enqueueSnackbar(addEventSuccessMessage, {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    onClose: () => {
                        dispatch(clearAddEditEventSuccessMessage())
                    }
                });
        }

        else if(editEventSuccessMessage)
        {
                enqueueSnackbar(editEventSuccessMessage, {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    onClose: () => {
                        dispatch(clearAddEditEventSuccessMessage())
                    }
                });
        }
        else if(addEventErrorMessage)
        {
                enqueueSnackbar(addEventErrorMessage, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    onClose: () => {
                        dispatch(clearAddEventErrorMessage())
                    }
                }); 
        }
        else if(editErrorMessage)
        {
                enqueueSnackbar(editErrorMessage, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    onClose: () => {
                        dispatch(clearEditEventErrorMessage())
                    }
                });
        }
        setIsAddEventModalOpen({ status: false, isOpenForEditing: false });
    },[addEventSuccessMessage, editEventSuccessMessage,addEventErrorMessage,editErrorMessage])
    useEffect(()=>{
      if(selectedCient)
      getRecordedMeetingList()
    },[selectedCient])
    useEffect(()=>{
      fetchMeetingType()
    },[])
    useEffect(()=>{
        if(location.state)
        {
            const { jumpToEventId, isNewMeeting, clientEmail, eventTitle, taskId, is_draft_meeting } = location.state;
            if(jumpToEventId || isNewMeeting == true){
                setRedirectRequired(true)
            }

            if(isNewMeeting)
            {
                setIsAddEventModalOpen({ status: true, isOpenForEditing: false });
                let attendeesList = [];
                const timestamp = new Date().getUTCMilliseconds();
                attendeesList.push({"id":timestamp, "searchRecipientName":clientEmail})
                setMeetingSchedule({
                    "subject":eventTitle,
                    "attendees":attendeesList,
                    "attachment_list":[],
                    "user":currentUserData.id,
                    "client_id":clientDetails.id,
                    "advisor_id":currentUserData.id,
                    "task_id":taskId ? taskId : null,
                    "description":"",
                    "is_draft_meeting": is_draft_meeting ? is_draft_meeting : null
                })
                setIsScheduleMeeting(isNewMeeting)
            }
            else
            {
                if(jumpToEventId){
                    dispatch(fetchEventDataAsync(jumpToEventId))
                    setOpenEventId(jumpToEventId)
                }
            }
        }
    },[location])

    useEffect(() => {
        dispatch(removeIndivigualFileItems([]))
        if (isAddEVentModalOpen.isOpenForEditing)
        {
            dispatch(setFilesSelectedFromAttachmentModal([...eventData.attachment_list]))
        }
    }, [isAddEVentModalOpen])

    const handleClose = () => {
        setIsAddEventModalOpen({ status: false, isOpenForEditing: false });
        dispatch(removeIndivigualFileItems([]));
        setModalClosed(true);
        // setIsScheduleMeeting(false)
    };

    useEffect(() => {
        if (selectedCalendarInfo !== null)
        {
            setIsAddEventModalOpen({ status: true, isOpenForEditing: false });
        }
    }, [selectedCalendarInfo])

   

    const openEmailErrorModalHandler = (trueOrFalse) => {
        setOpenEmailErrorModal(trueOrFalse);
    }

    useEffect(() => {
        setData([])
        setOffsetValue(0)
        fetchClientsData(0)
        dispatch(fetchSocialMediums())
    }, [sortColor, client_type_id, sortOrder, selectedFilter])
    const abortFetch = () => {
        controller.abort(); // Abort the ongoing request
    };
    const getColorCode = useMemo(() => {
        return (colorCode) => {
            let regx = /#/g;
            return regx.test(colorCode) ? colorCode : '#' + colorCode;
        };
    }, []);


    useEffect(() => {
      if (cardParentRef.current) {
        setContainerWidth(cardParentRef.current.offsetWidth);
        setContainerHeight(cardParentRef.current.offsetHeight);
      }
    }, []);
  
    const handleZoomChange = (newZoomLevel) => {
      const prevZoom = zoomLevel;
      setZoomLevel(newZoomLevel);
  
      if (cardParentRef.current) {
        const container = cardParentRef.current;
        const contentWidth = container.scrollWidth;
        const contentHeight = container.scrollHeight;
  
        const centerX = container.scrollLeft + containerWidth / 2;
        const centerY = container.scrollTop + containerHeight / 2;
  
        const newScrollLeft = centerX * (newZoomLevel / prevZoom) - containerWidth / 2;
        const newScrollTop = centerY * (newZoomLevel / prevZoom) - containerHeight / 2;
  
        container.scrollLeft = newScrollLeft;
        container.scrollTop = newScrollTop;
      }
    };
  
    const handleZoomIn = () => {
      handleZoomChange(Math.min(zoomLevel + 5, 400));
    };
  
    const handleZoomOut = () => {
      handleZoomChange(Math.max(zoomLevel - 5, 100));
    };
  
    const handleSliderChange = (event, newValue) => {
      handleZoomChange(newValue);
    };
  
    const handleReset = () => {
      handleZoomChange(100);
      if (cardParentRef.current) {
        cardParentRef.current.scrollTop = 0;
        cardParentRef.current.scrollLeft = 0;
      }
    };
  

  const handleMainScroll = (event) => {
      // This may not be needed anymore with the useEffect above, but keep it if you need it for other scroll handling.
      if (cardParentRef.current) {
          cardParentRef.current.scrollTop = event.target.scrollTop;
          cardParentRef.current.scrollLeft = event.target.scrollLeft;
      }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - cardParentRef.current.offsetLeft);
    setStartY(e.pageY - cardParentRef.current.offsetTop);
    setScrollLeft(cardParentRef.current.scrollLeft);
    setScrollTop(cardParentRef.current.scrollTop);
};

const handleMouseMove = (e) => {
    if (!isDragging) return;
   // e.preventDefault();
    const x = e.pageX - cardParentRef.current.offsetLeft;
    const y = e.pageY - cardParentRef.current.offsetTop;
    const walkX = (x - startX) * 1; // The multiplier controls the speed of the scroll
    const walkY = (y - startY) * 1;
    cardParentRef.current.scrollLeft = scrollLeft - walkX;
    cardParentRef.current.scrollTop = scrollTop - walkY;
    const rect = contentRef.current?.getBoundingClientRect();
    if (rect) {
      setCursorPos({
        x: (e.clientX - rect.left) * (100 / zoomLevel),
        y: (e.clientY - rect.top) * (100 / zoomLevel),
      });
    }
};

const handleMouseUp = () => {
    setIsDragging(false);
};
const handleDoubleClick = useCallback((client) => {
  setSelectedClient(client);
  setSelectedClientId(client.client_id);
  setDeleteProvider(true);
}, []);

const handleProviderModalClose=()=>{
  setScheduleFormOpen(false)
  setTabValue(0)
  setScheduleMeetingList([])
  dispatch(removeIndivigualFileItems([]))
  setDeleteProvider(false)
}

    const getTextColor = (backgroundColor) => {
        // Convert the hex color to RGB
        const r = parseInt(backgroundColor.slice(0, 2), 16);
        const g = parseInt(backgroundColor.slice(2, 4), 16);
        const b = parseInt(backgroundColor.slice(4, 6), 16);
    
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
      // Choose black or white based on the brightness
        return brightness > 128 ? 'black' : 'white';
    };

    
    const fetchClientsData = async () => {
      setLoading(true);
      const url = `dashboard-metrix/?ordering=-ratio${isNOTNullOrUndefined(client_type_id) ? `&client_type=${client_type_id}` : ''}${isNOTNullOrUndefined(sortColor) ? `&color_code=${sortColor}` : ''}${isNOTNullOrUndefined(selectedFilter) && selectedFilter != 'all' ? `&instrument=${selectedFilter}` : ''}`;

      try {
        const response = await axiosGet(url, { signal: controller.signal });

          if (response.data.data) {
              setRawData(response.data.data); 
            //  console.log("memo",rawData)// Store raw data in state
          } else {
              setRawData([]); // Clear data if no response
          }
      } catch (error) {
          if (error.name === 'AbortError') {
              console.log('Request aborted:', error.message);
              enqueueSnackbar("Previous request canceled", {
                  variant: "error",
                  anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                  }
              });
          } else if (error.response) {
              console.error('Server error:', error.response.status, error.response.data);
              enqueueSnackbar(`Server error: ${error.response.status}`, {
                  variant: "error",
                  anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                  }
              });
          } else if (error.request) {
              console.error('Network error:', error.request);
              enqueueSnackbar("Network error: Please check your connection", {
                  variant: "error",
                  anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                  }
              });
          } else {
              console.error('Unexpected error:', error.message);
              enqueueSnackbar("Error to fatching data", {
                  variant: "error",
                  anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                  }
              });
          }
      } finally {
          setLoading(false);
      }
  };
  const formatAum = useCallback((aumValue) => {
    if (aumValue < 1000000) {
        return `${(aumValue / 1000).toFixed(0)}k`;
    } else {
        return `${(aumValue / 1000000).toFixed(1)}m`;
    }
},[])
  // Use useMemo to format the raw data
  const formattedData = useMemo(() => {
      return rawData.map(i => ({
        id: `client-box-${i.client.id}`,
          name: `${i.client.user.first_name} ${i.client.user.last_name}`,
          size: i.ratio + 1,
          color: getColorCode(i.current_color),
          last_email_sent: i.last_email_sent,
          email: i.client.user.email,
          client_id: i.client.id,
          aum: formatAum(i.client.aum),
          socials: i.client.social_mediums,
          relative_client_name: i.client.relative_client_name,
          first_name: i.client.user.first_name,
          last_name: i.client.user.last_name,
          advisor: i.client.advisor
      }));
  }, [rawData,getColorCode,formatAum]);

  useEffect(() => {
    setData(formattedData)
    
}, [formattedData]) 


    const clearAllFilters = () => {
        //dispatch(setCurrentClientType(''))
     //   setSortOrder('')
        setSortColor('')
        // setSelectedFilter('all')
        // history.replace({ ...history.location, state: undefined });
    }
    const calculateZoomLevel = (size) => {
      // Define the mapping between box size and zoom level
      const minSize =  7; // Box size of 7 corresponds to zoom level 200
      const maxSize = 1; // Box size of 14 corresponds to zoom level 400
      const minZoom = 195;
      const maxZoom = 150;
    
      // Ensure the size is within the expected range
      if (size <= minSize) return minZoom;
      if (size >= maxSize) return maxZoom;
    
      // Linear interpolation formula
      const zoomLevel = minZoom + ((size - minSize) / (maxSize - minSize)) * (maxZoom - minZoom);
      return Math.round(zoomLevel); // Round to the nearest integer
    };

    const handlePreciseCentering = (selectedClient) => {
      // Store the selected client
      setSelectedAdvisor(selectedClient);
  
      // Calculate the zoom level
      const newZoomLevel = calculateZoomLevel(selectedClient.size);
  
      // Apply the new zoom level
      setZoomLevel(newZoomLevel);
  
      // Reference to container and client ID
      const container = cardParentRef.current;
      const clientId = selectedClient.client_id;
  
      if (!container) return;
  
      // Allow the render to complete with the new zoom level
      setTimeout(() => {
        // Get references to DOM elements
        const contentEl = contentRef.current;
        const selectedEl = document.getElementById(`client-${clientId}`);
  
        if (!contentEl || !selectedEl) return;
  
        // Get the scaled dimensions
        const scale = newZoomLevel / 100;
  
        // Find the selected element's position within the tree
        // This uses layout information which is more reliable than visual position
  
        // Get the raw tree data information for this client
        const clientData = data.find((item) => item.client_id === clientId);
        if (!clientData) return;
  
        // Find this element's position in the treemap
        // We need the original unscaled coordinates from the treemap layout
        // Method 1: Get it from the DOM directly if possible
        const selectedRect = selectedEl.getBoundingClientRect();
        const contentRect = contentEl.getBoundingClientRect();
  
        // Get the position relative to the content element (pre-scaling)
        let elemLeft = (selectedRect.left - contentRect.left) / scale;
        let elemTop = (selectedRect.top - contentRect.top) / scale;
        let elemWidth = selectedRect.width / scale;
        let elemHeight = selectedRect.height / scale;
  
        // Method 2: Alternative approach - traverse the DOM tree
        // This can be more reliable in some cases
        let cumOffsetLeft = 0;
        let cumOffsetTop = 0;
        let currentNode = selectedEl;
  
        // Walk up the DOM tree until we reach the content container
        while (currentNode && currentNode !== contentEl) {
          cumOffsetLeft += currentNode.offsetLeft;
          cumOffsetTop += currentNode.offsetTop;
          currentNode = currentNode.offsetParent;
        }
  
        // If method 2 gave us values, use those instead
        if (cumOffsetLeft > 0 || cumOffsetTop > 0) {
          elemLeft = cumOffsetLeft;
          elemTop = cumOffsetTop;
          elemWidth = selectedEl.offsetWidth;
          elemHeight = selectedEl.offsetHeight;
        }
  
        // Calculate the center point of the element (pre-scaling)
        const elemCenterX = elemLeft + elemWidth / 2;
        const elemCenterY = elemTop + elemHeight / 2;
  
        // Scale the center point
        const scaledCenterX = elemCenterX * scale;
        const scaledCenterY = elemCenterY * scale;
  
        // Calculate container center
        const containerCenterX = container.clientWidth / 2;
        const containerCenterY = container.clientHeight / 2;
  
        // Calculate the scroll position that would center the element
        const scrollLeft = scaledCenterX - containerCenterX;
        const scrollTop = scaledCenterY - containerCenterY;
  
        // Apply the scroll position - use direct assignment for more reliability
        container.scrollLeft = Math.max(0, scrollLeft);
        container.scrollTop = Math.max(0, scrollTop);
  
        // Store this position for later reference
        setSelectedBoxPosition({
          x: scaledCenterX,
          y: scaledCenterY,
        });
  
        // Draw a debug marker if needed
        if (process.env.NODE_ENV === "development") {
          // Remove any existing debug markers
          document.querySelectorAll(".debug-marker").forEach((el) => el.remove());
  
          // Create a new debug marker
          const marker = document.createElement("div");
          marker.classList.add("debug-marker");
          marker.style.position = "absolute";
          marker.style.zIndex = "9999";
          marker.style.width = "10px";
          marker.style.height = "10px";
          marker.style.borderRadius = "50%";
          marker.style.backgroundColor = "red";
          marker.style.left = `${scaledCenterX}px`;
          marker.style.top = `${scaledCenterY}px`;
          marker.style.transform = "translate(-50%, -50%)";
          contentEl.appendChild(marker);
        }
      }, 50);
    };


    const handleDirectCentering = (selectedClient) => {
      // Store reference to the client
      setSelectedAdvisor(selectedClient);
  
      // Calculate the new zoom level
      const newZoomLevel = calculateZoomLevel(selectedClient.size);
  
      // Get essential references
      const container = cardParentRef.current;
      const content = contentRef.current;
  
      if (!container || !content) return;
  
      // First update the zoom level (this triggers a re-render)
      setZoomLevel(newZoomLevel);
  
      // After re-render, find and center the element
      setTimeout(() => {
        const selectedBox = document.getElementById(
          `client-${selectedClient.client_id}`
        );
        if (!selectedBox) return;
  
        // Get the original transform origin
        const originalTransform = content.style.transform;
        const originalOrigin = content.style.transformOrigin;
  
        // Temporarily change transform to identity to get accurate measurements
        content.style.transform = "none";
        content.style.transformOrigin = "0 0";
  
        // Get measurements in the untransformed state
        const contentRect = content.getBoundingClientRect();
        const boxRect = selectedBox.getBoundingClientRect();
  
        // Get the center of the selected box relative to the content
        const centerX = boxRect.left - contentRect.left + boxRect.width / 2;
        const centerY = boxRect.top - contentRect.top + boxRect.height / 2;
  
        // Restore the original transform
        content.style.transform = originalTransform;
        content.style.transformOrigin = originalOrigin;
  
        // Calculate the scroll position that would center the element
        const scale = newZoomLevel / 100;
        const scaledCenterX = centerX * scale;
        const scaledCenterY = centerY * scale;
  
        // Calculate container center
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
  
        // Calculate and apply scroll
        const scrollLeft = scaledCenterX - containerWidth / 2;
        const scrollTop = scaledCenterY - containerHeight / 2;
  
        // Apply scroll immediately
        container.scrollTo({
          left: Math.max(0, scrollLeft),
          top: Math.max(0, scrollTop),
          behavior: "auto", // Use 'auto' for immediate scroll
        });
  
        // Store the selected box position
        setSelectedBoxPosition({
          x: scaledCenterX,
          y: scaledCenterY,
        });
      }, 100);
    };

    
    const handleClientSelection = handleDirectCentering;
    useEffect(() => {
      if (
        selectedAdvisor &&
        cardParentRef.current &&
        selectedBoxPosition.x &&
        selectedBoxPosition.y
      ) {
        const container = cardParentRef.current;
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
  
        // Recalculate the scroll position based on the new zoom level and selected position
        const scrollLeft = selectedBoxPosition.x - containerWidth / 2;
        const scrollTop = selectedBoxPosition.y - containerHeight / 2;
  
        // Apply the scroll position without smooth behavior to avoid animation conflicts
        container.scrollLeft = Math.max(0, scrollLeft);
        container.scrollTop = Math.max(0, scrollTop);
      }
    }, [zoomLevel, selectedBoxPosition]);
  
    // Custom hook to ensure element is in view after rendering
    const useEnsureElementInView = (clientId, isSelected, zoomLevel) => {
      useEffect(() => {
        if (!isSelected) return;
  
        // Small delay to ensure DOM has fully updated
        const timer = setTimeout(() => {
          const element = document.getElementById(`client-${clientId}`);
          if (!element || !cardParentRef.current) return;
  
          // Trigger the centering logic again
          const containerWidth = cardParentRef.current.clientWidth;
          const containerHeight = cardParentRef.current.clientHeight;
  
          // Find the element's position relative to the content
          let currentElement = element;
          let totalOffsetLeft = 0;
          let totalOffsetTop = 0;
  
          while (currentElement && currentElement !== contentRef.current) {
            totalOffsetLeft += currentElement.offsetLeft;
            totalOffsetTop += currentElement.offsetTop;
            currentElement = currentElement.offsetParent;
          }
  
          // Add half of the element's dimensions to get its center
          totalOffsetLeft += element.offsetWidth / 2;
          totalOffsetTop += element.offsetHeight / 2;
  
          // Scale the offsets according to the zoom level
          const scaledOffsetLeft = totalOffsetLeft * (zoomLevel / 100);
          const scaledOffsetTop = totalOffsetTop * (zoomLevel / 100);
  
          // Calculate the scroll position needed to center the element
          const scrollLeft = scaledOffsetLeft - containerWidth / 2;
          const scrollTop = scaledOffsetTop - containerHeight / 2;
  
          // Apply the scroll without smooth behavior
          cardParentRef.current.scrollLeft = Math.max(0, scrollLeft);
          cardParentRef.current.scrollTop = Math.max(0, scrollTop);
  
          // Update the stored position
          setSelectedBoxPosition({
            x: scaledOffsetLeft,
            y: scaledOffsetTop,
          });
        }, 100); // Small delay to ensure rendering complete
  
        return () => clearTimeout(timer);
      }, [clientId, isSelected, zoomLevel]);
    };
  
    // Modify your CustomTreeComponent to use this hook
    // This would be added to the component that renders individual client boxes
    const ClientBox = ({ client, zoomLevel, isSelected }) => {
      // Use the custom hook to ensure element is in view when selected
      useEnsureElementInView(client.client_id, isSelected, zoomLevel);
  
      // Rest of your client box rendering code
      return (
        <div
          id={`client-${client.client_id}`}
          className={`client-box ${isSelected ? "selected" : ""}`}
          // Other props
        >
          {/* Client box content */}
        </div>
      );
    };
  
  
    useEffect(() => {
      if (selectedAdvisor && cardParentRef.current) {
        const container = cardParentRef.current;
        const containerRect = container.getBoundingClientRect();
    
        // Recalculate the scroll position based on the new zoom level
        const newScrollLeft = selectedBoxPosition.x - containerRect.width / 2;
        const newScrollTop = selectedBoxPosition.y - containerRect.height / 2;
    
        // Adjust the scroll position
        container.scrollLeft = newScrollLeft;
        container.scrollTop = newScrollTop;
      }
    }, [zoomLevel, selectedAdvisor, selectedBoxPosition]);

    if (is_fetching_socials) return <div className='loader'> <CircularLoader className="pageCirclularLoader" /></div>
    return (
        <div className='content-body'>
            <div className="top-bar">
                <p className="title">AUM figures</p>
                <div style={{ width: '1px', height: '100%', background: '#848484' }} />
                <p className="text-style">Sort by :</p>
                {sortOptions.map((i, index) => <div onClick={() => setSortColor(i)} key={index} className='sort-btn' style={{ background: getColorCode(i) ,  color: getTextColor(i)}} >{index + 1}</div>)}
                {/* <div className='sort-btn-2' onClick={() => setSortOrder('client__user__first_name')} >
                    A-Z
                </div>
                <div className='sort-btn-2' onClick={() => setSortOrder('-client__user__first_name')}  >
                    Z-A
                </div> */}
              {!loading ?    <div style={{ width: "auto" }}>
                    <SelectFieldReact options={filterOptions.map(i => {
                        return { value: i.id, label: i.name }
                    })} value={selectedFilter} onChange={(e) => {
                        setSelectedFilter(e.value)
                    }} />
                </div>: null}
                {
                // isNOTNullOrUndefined(client_type_id) || isNOTNullOrUndefined(sortOrder) || 
                isNOTNullOrUndefined(sortColor) 
                // || selectedFilter != 'all'
                 ? <div className='clear-btn' onClick={() => clearAllFilters()}>
                    Clear Filters
                </div> : null}

                <div >
<Box
sx={{
display: "flex",
alignItems: "center",
gap: "8px",
}}
>
{/*-------| Zoom Count |-------*/}
<Typography
sx={{
width: "2rem",
fontSize: "16px",
fontWeight: "400",
color: "#000",
}}
>
{zoomLevel}%
</Typography>

{/*-------| Minus Btn |-------*/}
<IconButton size="small" onClick={handleZoomOut}>
<Remove />
</IconButton>

{/*-------| Slider |-------*/}
<Slider
min={100}
max={400}
step={5}
sx={{ width: "100px" }}
value={zoomLevel}
onChange={handleSliderChange}
/>

{/*-------| Plus Button |-------*/}
<IconButton size="small" onClick={handleZoomIn}>
<Add />
</IconButton>

{/*-------| Reset Button |-------*/}
<Button variant="contained" size="small" onClick={handleReset}   bgColor="secondary"  width= '100px' >
<RestartAltIcon fontSize="small"/>
Reset
</Button>
</Box>
</div> 

<Grid item xs={3}>
          <Autocomplete
            id="advisorName"
            name="advisorName"
            className={customStyles.root}
            options={[...data].sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
            popupIcon={<SearchIcon />}
            classes={{
              clearIndicator: customStyles.clearIndicator, // Apply custom clear indicator styles
              popupIndicator: customStyles.popupIndicator, // Apply custom popup indicator styles
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Enter Client Name"
                variant="outlined"
                size="small"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
            openOnFocus
            onChange={(event, newValue) => {
              if (newValue) {
                handleClientSelection(newValue); // Handle client selection
              } else {
                setSelectedAdvisor(null); // Clear selected client when input is cleared
              }
            }}

            // onInputChange={}
          />
        </Grid>
            </div>
            <Box
  ref={cardParentRef}
  sx={{
    width: "100%",
    height: "75vh",
    bgcolor: "#fff",
    overflow: "auto",
    borderRadius: "2px",
    border: "1px solid #000",
    cursor: isDragging ? 'grabbing' : 'grab',
    //-------| Scroll Bar |-------//
    "&::-webkit-scrollbar": {
      width: "10px",
      height: "10px",
      display: "block",
      backgroundColor: "#b6b6b6",
      borderRadius: "50px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#eeeeee",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#b6b6b6",
      borderRadius: "50px",
    },
  }}
  onMouseDown={handleMouseDown}
  onMouseMove={handleMouseMove}
  onMouseUp={handleMouseUp}
  onMouseLeave={handleMouseUp}
  onScroll={handleMainScroll}
>

  {loading && (
    <div className="loader" style={{
      position: "absolute", 
      top: "60%", 
      left: "50%", 
      transform: "translate(-50%, -50%)",
      zIndex: 1, 
    }}>
      <CircularLoader className="pageCirclularLoader" />
    </div>
  )}

 
  <Box
    ref={contentRef}
    sx={{
      width: `${widthValue * zoomLevel / 100}%`,
      height: `${heightValue * zoomLevel / 100}vh`,
      display: "flex",
      bgcolor: "#fff",
      flexWrap: "wrap",
      transform: `scale(${zoomLevel / 100})`,
      transformOrigin: "0% 0%",
      overflow: "hidden",
    }}
  >
    {data?.length > 0 ? (
      <ResponsiveContainer width="100%" height="100%">
        <Treemap
          data={data}
          dataKey="size"
          stroke="#fff"
          fill="orange"
          content={
            <CustomTreeComponent
            onDoubleClick={handleDoubleClick}
              zoomLevel={zoomLevel}
              selectedAdvisor={selectedAdvisor}
              
            />
          }
          isAnimationActive={false}
          aspectRatio={1 / 1}
        >
          <Tooltip content={<RenderTooltip zoomLevel={zoomLevel } />}  position={zoomLevel > 200 ? { x: cursorPos.x, y: cursorPos.y } : undefined} />
        </Treemap>
      </ResponsiveContainer>
    ) : !loading ? (
      <div className="loader">
        <p>No data</p>
      </div>
    ) : null}
  </Box>
</Box> 
            <Modal
        open={deleteProvider}
        handleClose={() => handleProviderModalClose()}
        size="md"
        showDefaultCloseIcon
        className="deleteModal"
      >
      <DialogTitle
        style={{
          position: 'sticky',
          top: 0,
          left: 0,
          padding: '10px',
          width:"96.5%",
          backgroundColor: 'white',
          zIndex: 1000,
          display: 'flex',
          flexDirection:"column",
          alignItems:"center"
        }}
      >

          <h4>{selectedCient && selectedCient.first_name && selectedCient.last_name
        ? `${selectedCient.first_name} ${selectedCient.last_name}`
        : "Client Name"}</h4>
        <Grid container >
        {scheduleFormOpen&&<Grid item xs={1} style={{ display: "flex", justifyContent: "center" }}>
    <IconButton onClick={() => {setScheduleFormOpen(false);dispatch(removeIndivigualFileItems([]))}}>
      <KeyboardBackspaceIcon style={{ color: "#32c2d9" }} fontSize="medium" />
    </IconButton>

                    </Grid>}
        <Grid item xs={scheduleFormOpen?10:12} style={{ display: "flex", justifyContent: "center" }}><Tabs value={tabValue} onChange={handleTabChange} aria-label="file browser">
                    <Tab label="Interaction"  style={{ color: tabValue === 0 ? '#32c2d9' : 'inherit' }} />
                    <Tab label="Recorded Meetings" style={{ color: tabValue === 1 ? '#32c2d9' : 'inherit' }} />
                    </Tabs></Grid>
                    </Grid>
                    
          </DialogTitle>
      <hr style={{margin:"3px"}}/>
   <TabPanel value={tabValue} index={0}>  
   <> { !scheduleFormOpen?<> <DialogContent>
      
        <div className="snoozeSubHeadWrapper">
        
            <div className="snoozeChangeSelectWrapper">
              <Grid container spacing={2}>
                <Grid item xs={12} className="deleteMessage" style={{ textAlign: 'center',fontSize: '1rem' }}>
                 Do you want to catch up or schedule a meeting with this client?
                 
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
        
        
          <DialogActions  style={{position:"sticky",bottom:0,left:0,padding:"10px",
                backgroundColor: 'white',zIndex: 1000,}}> 
         <Grid container spacing={3} alignItems='center' justifyContent='center' >
         <Grid item xs={3}> <Button
              className="deleteBtn"
              variant="contained"
              size="small"
              bgColor="secondary"
              onClick={() => setShowMailModal(true)}
            
            >
              Catch up mail
            </Button> </Grid>
            <Grid item xs={3}>  
          <Button
              className="deleteBtn"
              variant="contained"
              size="small"
              bgColor="secondary"
              onClick={() => {
                setIsAddEventModalOpen({ status: true, isOpenForEditing: false });

                let attendeesList = [];
                const timestamp = new Date().getUTCMilliseconds();
                attendeesList.push({ id: timestamp, searchRecipientName: selectedCient?.email });
                const { taskId, is_draft_meeting } = location.state || {};

                // Set the meeting schedule state with the selected client details
                setMeetingSchedule({
                  subject: [],
                  attendees: attendeesList,
                  attachment_list: [],
                  user: currentUserData?.id,
                  client_id: selectedCient?.client_id,
                  advisor_id: currentUserData?.id,
                  task_id: taskId ? taskId : null,
                  description:"",
                  is_draft_meeting: is_draft_meeting ? is_draft_meeting : null
                });
            
                // Ensure modal opens for scheduling a meeting
                setIsScheduleMeeting(true);

            }}
            
            >
              Schedule meeting
            </Button></Grid>
            {/* <Button
              className="cancelBtn"
              variant="contained"
              size="small"
              bgColor="success"
              onClick={() => setDeleteProvider(false)}
            >
              Cancel
            </Button> */}
            
            <Grid item xs={3}> <CustomSelect options={meetingTypeOption} handleOptionChange={(value)=>handleMeetingTypeChange(value)} /></Grid></Grid>
          </DialogActions></>:<ScheduleMeetingForm currentLoggedInAdvisor={currentLoggedInAdvisor}  deleteProvider={deleteProvider} getRecordedMeetingList={()=>{getRecordedMeetingList()}} selectedTreeMapClientId={selectedCient?.client_id} selectedOption={selectedOption} scheduleFormOpen={scheduleFormOpen} setScheduleFormOpen={setScheduleFormOpen}/>}
        <AddandEditEventModal
                isAddEVentModalOpen={isAddEVentModalOpen}
                setIsAddEventModalOpen={setIsAddEventModalOpen}
                eventData={isScheduleMeeting ? meetingSchedule : eventData}
                selectedCalendarInfo={selectedCalendarInfo}
                handleClose={handleClose}
                {...(isScheduleMeeting ?{addZoomLinkButton:true}:{})}
              //  calendarRef={calendarRef}
                openEmailErrorModalHandler={openEmailErrorModalHandler}
                setErrorEmailList={setErrorEmailList}
            /></></TabPanel>
   <TabPanel value={tabValue} index={1}>  
   <DialogContent style={{minHeight:"400px"}}> <RecordedMeeting handleFilterList={handleFilterList} limit={limit} offset={offset} totalCount={totalCount} deleteProvider={deleteProvider}  selectedTreeMapClientId={selectedCient?.client_id} getRecordedMeetingList={getRecordedMeetingList} scheduleMeetingList={scheduleMeetingList} isScheduleFetching={scheduleMeetingListFetching} /></DialogContent></TabPanel>
      </Modal>
            <Modal open={showMailModal} handleClose={() => setShowMailModal(false)} >
                <DashbaordMailView
                    afterSuccessfullySent={() => {
                        setShowMailModal(false)
                        fetchClientsData()
                    }}
                    client_details={selectedCient} close={() => setShowMailModal(false)} className="mailModal" clientMailvalue={clientMailvalue} />
            </Modal>
        </div>
    )
}

export default ProfileMangementContent