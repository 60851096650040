import { 
    fetchTaskListService,
    addTaskCommentService,
    fetchTaskCommentsService,
    fetchUsersBasedOnParamsService,
    fetchCurrentTaskMeetingService,
    fetchTaskStatusBarDetailsService,
    fetchTaskTimelineService,
    fetchStaffService,
    putVerifyTaskService,
    postAssignTaskService,
    fetchStaffMentionsService,
    verifyCheckListService,
    getKYCDataService,
    getAlreadyGeneratedKYCDataService,
    markReferAsSuccessService,
    putSendFeedback
} from '../../services/taskService.js';
import { TASKS_TYPES } from '../../redux/tasks/tasks.types';



export const clearActionMessages = (actionType) => ({
    type : actionType
})


export const fetchFilteredTaskListAsync = (filterParams) => {
    return dispatch => {
        fetchTaskListService(dispatch, filterParams)
    }
}
export const modifyPaginationProps = (payload) => {
    return dispatch => dispatch({type:TASKS_TYPES.MODIFY_PAGINATION_PROPS,payload:payload})
}
export const clearPaginationProps = () => {
    return ({type:TASKS_TYPES.CLEAR_MODIFY_PAGINATION_PROPS})
}


export const addTaskCommentAsync = (commentDetails) => {
    return dispatch => {
        addTaskCommentService(dispatch, commentDetails)
    }
}
export const clearAddTaskErrorMsg = () => ({
    type  : TASKS_TYPES.CLEAR_TASK_COMMENT_ERROR_MSG
})
export const clearAddTaskSuccessMsg = () => ({
    type  : TASKS_TYPES.CLEAR_TASK_COMMENT_SUCCESS_MSG
})



export const fetchTaskCommentsAsync = (taskId) => {
    return dispatch => {
        fetchTaskCommentsService(dispatch, taskId)
    }
}


export const fetchUsersBasedOnParamsAsync = (params) => {
    return dispatch => {
        fetchUsersBasedOnParamsService(dispatch, params)
    }
}
export const fetchCurrentTaskMeetingAsync = (clientId) => {
    return dispatch => {
        fetchCurrentTaskMeetingService(dispatch, clientId)
    }
}

export const fetchTaskStatusBarDetailsAsync = (cliendId) => {
    return dispatch => {
        fetchTaskStatusBarDetailsService(dispatch, cliendId)
    }
}

export const fetchTaskTimelineAsync = (taskId) => {
    return dispatch => {
        fetchTaskTimelineService(dispatch, taskId)
    }
}

export const fetchStaffAsync = (company, type,condition,limit,offset) => {
    return dispatch => {
        if (condition) {
            fetchStaffService(dispatch, company='', type='',limit,offset); // Pass company and type if condition is true
        } else {
            fetchStaffService(dispatch, company, type,limit,offset); // Pass empty strings if condition is false
        }
    }
}
export const sendFeedbackAsync= (taskId,loggedInUserType,feedback_Content,isFeedbackFromloggedInUserType,remark)=>{
    return dispatch=>{
        putSendFeedback (dispatch,taskId,loggedInUserType,feedback_Content,isFeedbackFromloggedInUserType,remark)
    }
}
export const verifyTaskAsync = (taskId, loggedInUserType, isKYC,compliance_remark) => {
    return dispatch => {
        putVerifyTaskService(dispatch, taskId, loggedInUserType, isKYC,compliance_remark)
    }
}

export const assignTaskAsync = (taskId, assignedTo) => {
    return dispatch => {
        postAssignTaskService(dispatch, taskId, assignedTo)
    }
}

export const fetchStaffMentionsAsync = (company) => {
    return dispatch => {
        fetchStaffMentionsService(dispatch, company)
    }
}


export const verifyCheckListAsync = (taskId, useFor) => {
    return dispatch => {
        verifyCheckListService(dispatch, taskId, useFor)
    }
}


export const getKYCDataAsync = (clientId, KYCData = null, taskId) => {
    return dispatch => {
        getKYCDataService(dispatch, clientId, KYCData, taskId);
    }
}


export const getAlreadyGeneratedKYCDataAsync = (clientId, task_id) => {
    return dispatch => {
        getAlreadyGeneratedKYCDataService(dispatch, clientId, task_id);
    }
}

export const clearTaskReducerState = (state) => ({
    type : TASKS_TYPES.CLEAR_TASK_REDUCER_STATE,
    payload : state
})

export const markAsSuccess = (ssid, client_id) => {
    return dispatch => {
        markReferAsSuccessService(dispatch, ssid, client_id)
    }
}