import React, { memo } from 'react';
import MailIcon from '@mui/icons-material/Mail';
import './DashboardContent.scss';
import { formatNumber, isNOTNullOrUndefined } from '../../utils/utils';

export const CustomTreeComponent = memo((props) => {
  const {
    root,
    depth,
    x,
    y,
    width,
    height,
    index,
    colors,
    aum,
    email,
    client_id,
    color,
    name,
    size,
    value,
    advisor,
    first_name,
    last_name,
    zoomLevel,
    onDoubleClick,
    selectedAdvisor,
  } = props;

  // Apply minimum width and height
  const rectWidth = width;
  const rectHeight = height;
  const zoomFactor = zoomLevel / 100;
  const isWiderThanMin = width > 100;
  const isShorterThanMin = height < 100;
  const proportionHeightFix = isShorterThanMin ? 10 : 0;
  const isWidthOrHeightTooSmall = width < 100 || height < 100;
  const fontSizeFactor = isShorterThanMin || isWidthOrHeightTooSmall ? 0.06 : 0.08; // Adjust for font size scaling
  const averageDimension = (width + height) / 2;
  const minDimension = Math.min(width, height);
  const dynamicFactor = minDimension <= 100 ? 3 - minDimension / 100 : 2;
  const fontSizeFormatted = averageDimension * fontSizeFactor * (zoomFactor / dynamicFactor);
  const extraSize = zoomFactor > 2 ? 0 : 3;
  const fontSize = fontSizeFormatted > 10 ? 10 : fontSizeFormatted + extraSize;

  const textSpacing = 12;
  const spacingOffset = (10 - fontSize) * textSpacing / 10;

  const fontColor = '#fff';
  const mailIconWidth = 20; // Set the width of the MailIcon
  const halfWidth = width / 2;
  const halfIconWidth = mailIconWidth / 4;
  const iconX = x + halfWidth - halfIconWidth;
  const iconY = y + height / 2 + 25 - proportionHeightFix;

  const getStrokeColor = (backgroundColor) => {
    if (!backgroundColor || backgroundColor.length < 7) {
      return '#000'; // Default stroke color
    }

    const slicedColor = backgroundColor.slice(1); // Remove the '#' from the color string
    const r = parseInt(slicedColor.slice(0, 2), 16);
    const g = parseInt(slicedColor.slice(2, 4), 16);
    const b = parseInt(slicedColor.slice(4, 6), 16);

    if (isNaN(r) || isNaN(g) || isNaN(b)) {
      return '#000'; // Default stroke color if parsing fails
    }

    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 128 ? '#000' : '#D3D3D3'; // Choose stroke color based on brightness
  };

  const strokeColor = getStrokeColor(color);

  const preventTextSelection = (e) => {
    e.preventDefault(); // Prevents text selection on double-click
  };

  // Check if this client is the selected one
  const isSelected = selectedAdvisor && selectedAdvisor.client_id === client_id;

  return (
    <g
      id={`client-${client_id}`} // Unique ID for each client box
      style={{ cursor: 'pointer', userSelect: 'none' }}
      onDoubleClick={(e) => {
        e.stopPropagation();onDoubleClick({ email: email, client_id: client_id,advisor:advisor,first_name:first_name, last_name:last_name })}}
      onMouseDown={preventTextSelection}
    >
      <rect
        x={x}
        y={y}
        width={rectWidth}
        height={rectHeight}
        style={{
          fill: color,
          stroke: isSelected ? '#fff' : 'none', // Highlight selected client
          strokeWidth: isSelected ? 3 : 2 / (depth + 1e-10),
          strokeOpacity: isSelected ? 1 : 1 / (depth + 1e-10),
        }}
      />

      {isWiderThanMin ? (
        <text
          fontFamily="Poppins"
          x={x + width / 2}
          y={y + height / 2 + spacingOffset - proportionHeightFix - 10}
          textAnchor="middle"
          fill={fontColor}
          stroke={strokeColor}
          fontSize={fontSize}
       //  fontWeight={0}
         className='cellStyle'
          style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', userSelect: 'none' }}
        >
          {name}
        </text>
      ) : null}

      {!isWiderThanMin ? (
        <text
          fontFamily="Poppins"
          x={x + width / 2}
          y={y + height / 2 + spacingOffset - proportionHeightFix - 15}
          textAnchor="middle"
          fill={fontColor}
          stroke={strokeColor}
          fontSize={fontSize}
          //fontWeight={0}
            className='cellStyle'
          style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', userSelect: 'none' }}
        >
          {first_name}
        </text>
      ) : null}

      {!isWiderThanMin ? (
        <text
          fontFamily="Poppins"
          x={x + width / 2}
          y={y + height / 2 + spacingOffset - proportionHeightFix - 6}
          textAnchor="middle"
          fill={fontColor}
          stroke={strokeColor}
          fontSize={fontSize}
         // fontWeight={0}
          className='cellStyle'
          style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', userSelect: 'none' }}
        >
          {last_name}
        </text>
      ) : null}

      <text
        x={x + width / 2}
        y={y + height / 2 + spacingOffset + 8 - proportionHeightFix}
        textAnchor="middle"
        fill={fontColor}
        stroke={strokeColor}
        fontSize={fontSize}
        fontWeight={395}
        style={{ userSelect: 'none' }}
      >
        £ {formatNumber(aum)}
      </text>

      <text
        x={x + width / 2}
        y={y + height / 2 + spacingOffset + 24 - proportionHeightFix}
        textAnchor="middle"
        fill={fontColor}
        stroke={strokeColor}
        fontSize={fontSize}
      // fontWeight={395}
         className='cellStyle'
        style={{ userSelect: 'none' }}
      >
        AUM
      </text>

      {isNOTNullOrUndefined(email) ? (
        <g style={{ cursor: 'pointer' }}>
          <foreignObject x={iconX} y={iconY} width={mailIconWidth} height={mailIconWidth}>
            <MailIcon style={{ fontSize: fontSize + 2, color: strokeColor }} fill={fontColor} />
          </foreignObject>
        </g>
      ) : null}
    </g>
  );
});