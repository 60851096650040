import {
    axiosPost,
    axiosGet,
    axiosPut,
    handleOnError,
    handleOnSuccess,
    handleActionStart
} from '../utils/api';
import { TASKS_TYPES } from '../redux/tasks/tasks.types';
import { CHECK_LIST_STATIC_NAMES, LOGGEDIN_USER_TYPES } from '../utils/constants';
import { count } from 'draft-js/lib/DefaultDraftBlockRenderMap';

/*
    Service to fetch filtered task list
*/
export const fetchTaskListService = async (dispatch, filterparams) => {
    try
    {
        dispatch(handleActionStart(TASKS_TYPES.FETCH_FILTERED_TASK_LIST_START));
        const {  
            client,
            task,
            status,
            created,
            assigned,
            limit,
            offset
        } = filterparams;

        const data = await axiosGet(`client-tasks/?client=${client}&status=${status}&assigned=${assigned}&created=${created}&task=${task}&limit=${limit}&offset=${offset}`, TASKS_TYPES.FETCH_FILTERED_TASK_LIST_SUCCESS);
        dispatch(handleOnSuccess(data,TASKS_TYPES.FETCH_FILTERED_TASK_LIST_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response,TASKS_TYPES.FETCH_FILTERED_TASK_LIST_FAILURE));
    }
}


/*
    Service to add comment on task
*/
export const addTaskCommentService = async (dispatch, commentDetails) => {
    try {
        dispatch(handleActionStart(TASKS_TYPES.ADD_TASK_COMMENT_START));
        const { comment, task_id, commented_by } = commentDetails;
        const data = await axiosPost('comments/',{
            comment,
            task_id : task_id,
            commented_by, 
        });
        dispatch(handleOnSuccess(data, TASKS_TYPES.ADD_TASK_COMMENT_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,TASKS_TYPES.ADD_TASK_COMMENT_FAILURE));
    }
}



/*
    Service to fetch comments related to tasks
*/
export const fetchTaskCommentsService = async (dispatch, taskId) => {
    try {
        dispatch(handleActionStart(TASKS_TYPES.FETCH_TASK_COMMENTS_START));
        const data = await axiosGet(`comments/?task_id=${taskId}`);
        dispatch(handleOnSuccess(data,TASKS_TYPES.FETCH_TASK_COMMENTS_SUCCESS ));
    }
    catch(err) {
        dispatch(handleOnError(err.response,TASKS_TYPES.FETCH_TASK_COMMENTS_FAILURE));
    }
}




/*
    Service to fetch users based on params
    eg : is_staff = true, type = Advisor etc..
*/
export const fetchUsersBasedOnParamsService = async (dispatch, params) => {
    try {
        dispatch(handleActionStart(TASKS_TYPES.FETCH_USERS_BASED_ON_PARAMS_START));
        const data = await axiosGet(`users/`,{
            params : params
        });
        dispatch(handleOnSuccess(data,TASKS_TYPES.FETCH_USERS_BASED_ON_PARAMS_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,TASKS_TYPES.FETCH_USERS_BASED_ON_PARAMS_FAILURE));
    }
}



/*
    Service to fetch meeting date scheduled related to current task
*/
export const fetchCurrentTaskMeetingService = async (dispatch, clientId) => {
    try {
        dispatch(handleActionStart(TASKS_TYPES.FETCH_CURRENT_TASK_MEETING_START));
        const data = await axiosGet(`task-event/?client=${clientId}`);
        dispatch(handleOnSuccess(data, TASKS_TYPES.FETCH_CURRENT_TASK_MEETING_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,TASKS_TYPES.FETCH_CURRENT_TASK_MEETING_FAILURE));
    }
}



/*
    Service to fetch task status bar details 
*/
export const fetchTaskStatusBarDetailsService = async (dispatch, cliendId) => {
    try {
        dispatch(handleActionStart(TASKS_TYPES.FETCH_TASK_STATUSBAR_DETAILS_START));
        const data = await axiosGet(`client-tasks/${cliendId}/`);
        dispatch(handleOnSuccess(data, TASKS_TYPES.FETCH_TASK_STATUSBAR_DETAILS_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,TASKS_TYPES.FETCH_TASK_STATUSBAR_DETAILS_FAILURE));
    }
}
/**
 * Fetch task timeline details
 */
export const fetchTaskTimelineService = async (dispatch, taskId) => {
    try {
        dispatch(handleActionStart(TASKS_TYPES.FETCH_TASK_TIMELINE_START));
        const data = await axiosGet(`client-task-timeline/?client_task=${taskId}`);
        dispatch(handleOnSuccess(data, TASKS_TYPES.FETCH_TASK_TIMELINE_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,TASKS_TYPES.FETCH_TASK_TIMELINE_FAILURE));
    }
}

/**
 * Fetch staff list
 */
export const fetchStaffService = async (dispatch, company, type,limit = 100,offset= 0) => {
    try {
        dispatch(handleActionStart(TASKS_TYPES.FETCH_STAFF_START));
        const data = await axiosGet(`staffs/?type=${type}&company=${company}&limit=${limit}&offset=${offset}`); 
        dispatch(handleOnSuccess(data, TASKS_TYPES.FETCH_STAFF_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,TASKS_TYPES.FETCH_STAFF_FAILURE));
    }
}

export const postAssignTaskService = async (dispatch, taskId, assignedTo) => {
    try {
        dispatch(handleActionStart(TASKS_TYPES.POST_ASSIGN_TASK_START));
        let postData = {
            "task_id" : taskId,
	        "assigned_to" : assignedTo
        }
        const data = await axiosPost(`client-tasks/${taskId}/assign_task/`, postData);
        dispatch(handleOnSuccess(data, TASKS_TYPES.POST_ASSIGN_TASK_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,TASKS_TYPES.POST_ASSIGN_TASK_FAILURE));
    }
}
export const putSendFeedback=async (dispatch,taskId,loggedInUserType,feedback_Content,isFeedbackFromloggedInUserType,remark)=>{
    try{
        dispatch(handleActionStart(TASKS_TYPES.PUT_SEND_FEEDBACK_START));
            let data;
        if(loggedInUserType === LOGGEDIN_USER_TYPES.typeCompliance){
            data = await axiosPut(`client-tasks/${taskId}/`, {
                compliance_feedback : feedback_Content,
                compliance_feedback_to_administrator:isFeedbackFromloggedInUserType,
                compliance_remarks:remark,
            }); 
        }
        if(loggedInUserType === LOGGEDIN_USER_TYPES.typeAdministrator){
            data = await axiosPut(`client-tasks/${taskId}/`, {
                administrator_feedback :feedback_Content,
                administrator_feedback_to_compliance:isFeedbackFromloggedInUserType,
            });
        }
        dispatch(handleOnSuccess(data,TASKS_TYPES.PUT_SEND_FEEDBACK_SUCCESS));
    }
    catch(err) {
            dispatch(handleOnError(err.response,TASKS_TYPES.PUT_SEND_FEEDBACK_FAILURE));
        }
    }

/**
 * Fetch staff list for Mention only
 */
export const fetchStaffMentionsService = async (dispatch, company) => {
    try {
        dispatch(handleActionStart(TASKS_TYPES.FETCH_MENTION_STAFF_START));
        const data = await axiosGet(`staffs/?company=${company}`);
        dispatch(handleOnSuccess(data, TASKS_TYPES.FETCH_MENTION_STAFF_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,TASKS_TYPES.FETCH_MENTION_STAFF_FAILURE));
    }
}


/**
 * Service to verify task
 */
 export const putVerifyTaskService = async (dispatch, taskId, loggedInUserType,isKYC,compliance_remark) => {
    try {
        dispatch(handleActionStart(TASKS_TYPES.PUT_VERIFY_START));
        let data;
        if(loggedInUserType === LOGGEDIN_USER_TYPES.typeOps)
        {
            data = await axiosPut(`client-tasks/${taskId}/`, {
                is_ops_verified : 'True'
            });
        }
        else if(loggedInUserType === LOGGEDIN_USER_TYPES.typeAdministrator)
        {
            data = await axiosPut(`client-tasks/${taskId}/`, {
                is_admin_verified : 'True'
            });
        }
        else if(loggedInUserType === LOGGEDIN_USER_TYPES.typeAdvisor)
        { 
            if(isKYC)
            {
                data = await axiosPut(`client-tasks/${taskId}/`, {
                    is_kyc_confirmed : 'True'
                }); 
            }
            else
            {
                data = await axiosPut(`client-tasks/${taskId}/`, {
                    advisor_approved : 'True'
                }); 
            }
            
        }
        else if(loggedInUserType === LOGGEDIN_USER_TYPES.typeCompliance)
        {
            data = await axiosPut(`client-tasks/${taskId}/`, {
                is_compliance_verified : 'True',
               ... (compliance_remark?{compliance_remark}:{}),

            });
        }
        dispatch(handleOnSuccess(data,TASKS_TYPES.PUT_VERIFY_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,TASKS_TYPES.PUT_VERIFY_FAILURE));
    }
}

/*
    Service to verify checklist 
*/
export const verifyCheckListService = async (dispatch, taskId, useFor) => {
    try {
        dispatch(handleActionStart(TASKS_TYPES.VERIFY_CHECK_LIST_START)); 
        let data;
        if(useFor === CHECK_LIST_STATIC_NAMES.administratorchecklist)
        {
            data = await axiosPut(`client-tasks/${taskId}/`,{
                is_admin_checklist_verified : 'True'
            });
        }
        else if(useFor === CHECK_LIST_STATIC_NAMES.complaincechecklist)
        {
            data = await axiosPut(`client-tasks/${taskId}/`, {
                is_compliance_checklist_verified : 'True'
            });
        }
        else if(useFor === CHECK_LIST_STATIC_NAMES.advisorchecklist)
        {
            data = await axiosPut(`client-tasks/${taskId}/`, {
                is_advisor_checklist_verified : 'True'
            });
        }
        dispatch(handleOnSuccess(data,TASKS_TYPES.VERIFY_CHECK_LIST_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,TASKS_TYPES.VERIFY_CHECK_LIST_FAILURE));
    }
}

/*
    Service to get KYC data
*/
export const getKYCDataService = async (dispatch, clientId, KYCData = null, taskId) => {
    try {
        dispatch(handleActionStart(TASKS_TYPES.GET_KYC_DATA_START));
        let fromData = { client_id : clientId, client_task_id : parseInt(taskId)} ;
        if(KYCData)
        {
            fromData = { client_id : clientId, ssid : KYCData.ssid, client_task_id : parseInt(taskId) }
        }
        const data = await axiosPost('smart-search/', fromData);
        dispatch(handleOnSuccess(data,TASKS_TYPES.GET_KYC_DATA_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,TASKS_TYPES.GET_KYC_DATA_FAILURE));
    }
}

/* 
    Service to get already generated PDF data.
 */
export const getAlreadyGeneratedKYCDataService = async (dispatch, clientId, task_id) => {
    try
    {
        dispatch(handleActionStart(TASKS_TYPES.GET_ALREADY_GENERATED_KYC_DATA_START));
        const data = await axiosGet(`smart-search/?client_id=${clientId}&client_task_id=${task_id}`);
        dispatch(handleOnSuccess(data,TASKS_TYPES.GET_ALREADY_GENERATED_KYC_DATA_SUCCESS));
    }
    catch(err)
    {
        dispatch(handleOnError(err.response,TASKS_TYPES.GET_ALREADY_GENERATED_KYC_DATA_FAILURE));
    }
}

/**
 * Service for marking refer response as Success
 */
export const markReferAsSuccessService = async (dispatch, ssid, client_id) => {
    try {
        dispatch(handleActionStart(TASKS_TYPES.PUT_REFER_TO_SUCCESS_START)); 
        const data = await axiosPut(`smart-search/`, {ssid : ssid, client_id : client_id});
        dispatch(handleOnSuccess(data,TASKS_TYPES.PUT_REFER_TO_SUCCESS_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,TASKS_TYPES.PUT_REFER_TO_SUCCESS_FAILURE));
    }
}