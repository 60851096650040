import React, { useCallback } from 'react';
import './FileAttachmentPreview.scss';
import { getFileExtension, bytesToSize, getObjectUrl, getTrimmedString } from '../../utils/utils';
import DescriptionIcon from '@material-ui/icons/Description';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import CancelIcon from '@material-ui/icons/Cancel'; 
import { useDispatch, useSelector } from 'react-redux';
import { removeIndivigualFileItems } from '../../redux/ui/ui.actions';
import GetAppIcon from '@material-ui/icons/GetApp';





const downloadFilesHandler = (filesArray) => {
    const urlArray = filesArray.map(fileItem => fileItem.filePath)
    const zip = new JSZip();
    let count = 0;
    const zipFilename = "Attachments.zip";
    urlArray.forEach(function (url, i) {
        const filename = `${i+1}_${filesArray[i].fileName}`;
        // loading a file and add it in a zip file
        JSZipUtils.getBinaryContent(url, function (err, data) {
            if (err)
            {
                throw err; // or handle the error
            }
            zip.file(filename, data, { binary: true });
            count++;
            if (count == filesArray.length)
            {
                zip.generateAsync({ type: 'blob' }).then(function (content) {
                    saveAs(content, zipFilename);
                });
            }
        });
    });
}





const FileAttachmentPreview = ({ files,arrangeOnRow=false, showCloseIcon=true, showDownloadIcon=true, customRemoveFilesHandler=null, showDownloadAllLabel=true }) => {
    
    //arrangeOnRow display the files order in row 
    const dispatch = useDispatch();
    const filesSelectedFromAttachmentModal = useSelector(state => state.ui.filesSelectedFromAttachmentModal);

    const token = useSelector(state => state.auth.currentUserData.token);


    let removeFileItemHandler = (index) => {
        const filesPicked = [...filesSelectedFromAttachmentModal];
        filesPicked.splice(index, 1)
        dispatch(removeIndivigualFileItems(filesPicked))
    }

    if(customRemoveFilesHandler)
    {
        removeFileItemHandler = customRemoveFilesHandler
    }



    const indivigualItemDownloadHandler = useCallback( async (index)=>{
        const itemToDownload = files.find(( item,i ) => i === index);
        // instance of file comes when file is picked from local system if picked from cap drive it will an object with items path
        if (itemToDownload instanceof File)
        {
            const itemName = itemToDownload.name;
            saveAs(itemToDownload, itemName);
        }
        else
        {
            const itemName = itemToDownload.name;
            const url = itemToDownload.item_path;
            let blob = await fetch(url).then(r => r.blob());
            saveAs(blob, itemName);
        }
    },[files])
    

    let totalFileSizeInBytes = 0;
    const newFilesArray = files.map(fileItem => {
        const fileExtension = getFileExtension(fileItem.name);
        if (fileItem instanceof File)
        {
            const fileUrl = getObjectUrl(fileItem);
            totalFileSizeInBytes = totalFileSizeInBytes + parseInt(fileItem.size);
            return {
                fileName: fileItem.name,
                fileExtension: fileExtension,
                filePath: fileUrl,
                fileSize: bytesToSize(fileItem.size),
                fileUrl: fileUrl
            }
        }
        else
        {
            totalFileSizeInBytes = totalFileSizeInBytes + parseInt(fileItem.item_size);
            return {
                fileName: fileItem.name,
                fileExtension: fileExtension,
                filePath: fileItem.item_path,
                fileSize: bytesToSize(fileItem.item_size),
                fileUrl: fileItem.item_path
            }
        }

    })
    totalFileSizeInBytes = bytesToSize(totalFileSizeInBytes);
    return (
        <div className="fileAttachmentPreview">
            {
                newFilesArray.length > 0
                &&
                <>
                    <div className='fileAttachments' {...(arrangeOnRow?{style:{display:"flex",flexDirection:"row",overflowX:"auto"}}:{})}>
                        {
                            newFilesArray.map((item,i) => {
                                const fileType = item.fileExtension.toLowerCase();
                                if (fileType !== 'png' && fileType !== 'jpeg' && fileType !== 'jpg')
                                {

                                    return (
                                        <div className="fileItem fileItemDocument" key={`${item.fileExtension}${i}`}>
                                            <DescriptionIcon className="icon descriptionIcon" />
                                            <div className="fileMetaDetails">
                                                <div className="fileMetaDetailsData">
                                                    <p className="fileName">
                                                        {
                                                            item && item.fileName.length >= 30 
                                                            ?
                                                            `${getTrimmedString(item.fileName,26)}.${item.fileExtension}`
                                                            :
                                                            getTrimmedString(item.fileName,30)
                                                        }
                                                    </p>
                                                    <p className="fileSize">{item.fileSize}</p>
                                                </div>
                                                <div className="fileMetaDetailsActions">
                                                    {
                                                        showCloseIcon && <span onClick={()=>removeFileItemHandler(i)}><CancelIcon className="icon removeIcon cursor-pointer"/></span>
                                                    }
                                                    {
                                                        showDownloadIcon && <span onClick={()=>indivigualItemDownloadHandler(i)}><GetAppIcon className="icon downloadIcon cursor-pointer"/></span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                else
                                {
                                    return (
                                        <div className="fileItem fileItemImage" key={`${item.fileExtension}${i}`}>
                                            <div className="imageWrapper">
                                                <img src={item.filePath} alt={getTrimmedString(item.fileName,14)} />
                                                <div className="overlay">
                                                    <p className="fileName">{getTrimmedString(item.fileName,14)}</p>
                                                    <div className="fileDownloadRemoveWrapper">
                                                        {
                                                            showCloseIcon && <span onClick={()=>removeFileItemHandler(i)} ><CancelIcon className="icon removeIcon cursor-pointer"/></span>
                                                        }
                                                        {
                                                            showDownloadIcon && <span onClick={()=>indivigualItemDownloadHandler(i)} ><GetAppIcon className="icon downloadIcon cursor-pointer"/></span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                    {
                        showDownloadAllLabel &&
                        <div className="fileAttachmentDetails">
                            {
                                (newFilesArray.length > 1 && showDownloadIcon)
                                &&
                                <p className="downloadBtn cursor-pointer customDescription" onClick={() => downloadFilesHandler(newFilesArray)}>{`Download ${newFilesArray.length} Attachment${newFilesArray.length === 1 ? '' : 's'} (${totalFileSizeInBytes})`}</p>
                            }
                        </div>
                    }
                    
                </>
            }
        </div>
    )
}

export default FileAttachmentPreview;

