import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { getError } from '../../utils/utils';
import { Select } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';



const useStyles = makeStyles( theme => ({
    root: {
        width : '100%',
        '& label': {
            background: 'white'
        },
        '& label.Mui-focused': {
            color: theme.palette.secondary.main
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.secondary.main
            },
        },
    },
    labelClass : {
        '&.Mui-focused': {
            color: theme.palette.secondary.main
        },
    },
    focused: {},
    notchedOutline: {}
}));



const SingleSelect = ({
    field: { name, value, ...otherFieldProps },
    form: { touched, errors, values, setFieldValue, status },
    onChange,
    label,
    defaultValue,
    placeHolder,
    required,
    variant="outlined",
    options,
    readOnly=false,
    size="small",
    onClose,
    className,
    optionLabel="option",
    disabled=false,
    disablePlaceholder=false,
    showValidationError=true,
    ...otherProps
}) => {
     const [selectOnFocus,setSelectOnFocus]=useState(false)
    const errorText = getError(name, { touched, status, errors });
    const isError = (errorText) ? true : false;


    const shouldLabelShrink = label ? true : false ;
    const isRequired = required ? true : false ;

    // This is for reducing select size as there is no explicit size prop in material ui for select component
    let margin;
    if(size === 'small')
    {
        margin = "dense"
    }
    const classes = useStyles();

    return (
        <Tooltip title={label} placement="top">
        <FormControl variant={variant} error={isError} margin={margin} className={classes.root}>
            <InputLabel className={classes.labelClass} required={isRequired} style={selectOnFocus?{color:"#32c2d9"}:{}}shrink={shouldLabelShrink} id={label}>{label}</InputLabel>
            <Select
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    getContentAnchorEl: null
                }}
                disabled={disabled}
                fullWidth
                size={size}
                id={label}
                labelId={label}
                readOnly={readOnly}
                value={value} 
                onClose={onClose}
                defaultValue={defaultValue}
               displayEmpty={false}
                name={name}
                label={label}
                {...otherFieldProps} // dont change prop order, formik change is overrided by onChange below this
                onChange={(e,index,value) => {
                    setFieldValue(name, e.target.value);
                    // Running the custom on change function if passed
                    if (onChange)
                    {
                        onChange(e);
                    }
                }}
                onFocus={()=>{
                   setSelectOnFocus(true) 
                }}
                onBlur={()=>{
                    setSelectOnFocus(false) 
                }}
                {...otherProps}
                className={`
                    ${className}
                    ${classes.root}
                `}
            >
                <MenuItem value="" disabled={disablePlaceholder}>{placeHolder}</MenuItem>
                {
                    options && options.map((selectItem, i) => {
                        return <MenuItem key={i} value={selectItem.id}>{selectItem[optionLabel]}</MenuItem>
                    })
                }
            </Select>
            {
                isError && showValidationError && <FormHelperText className="errorMessageText">{errorText}</FormHelperText>
            }
        </FormControl>
        </Tooltip>
    )
}



export default SingleSelect;