import { DialogContent, Grid, IconButton} from "@mui/material";
import { FastField, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import TimePicker from "../TimePicker/TimePicker";
import DatePicker from "../DatePicker/DatePicker";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { useSnackbar } from "notistack";
import { axiosPost } from "../../utils/api";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useDispatch, useSelector } from "react-redux";
import FileAttachmentPreview from "../FileAttachmentPreview/FileAttachmentPreview";
import FileAttachmentModalComponent from "../FileAttachmentModal/FileAttachmentModalComponent/FileAttachmentModalComponent";
import Autocomplete from '@mui/material/Autocomplete';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import * as Yup from "yup"
import { formatStaffForMention} from '../../utils/utils';
import {
    fetchStaffAsync,
} from '../../redux/tasks/tasks.actions.js';
import moment from 'moment';
import { makeStyles } from "@material-ui/core";
import { moment_UTC_format } from "../Calendar/AddEditModal/AddEditModal.jsx";
import { removeIndivigualFileItems } from "../../redux/ui/ui.actions.js";

const validationSchema = Yup.object().shape({
  scheduleMeetingDate: Yup.date()
    .required("Meeting date is required"),
  scheduleMeetingTime: Yup.date()
              .required("Meeting time is required"),
  advisorName: Yup.string().required("Organizer selection is required"),
});
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& label.Mui-focused': {
            color: theme.palette.secondary.main
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.secondary.main
            },
        },
        "& .MuiAutocomplete-popupIndicator": 
        { 
          
            transform: "none" 
    
        },
        "& .MuiOutlinedInput-input":{
            height:"0.7em !important"
        },
        '&.css-wjh20t-MuiPagination-ul': {
            justifyContent: 'end'
          },
          '& .MuiPaginationItem-root': {
            borderRadius: '50%', // Make each page item round
            border: 'none !important', // Remove the border from each page item
            color: '##32c2d9 !important'
          },
          '& .Mui-selected': {
            backgroundColor: '#32c2d9 !important',
            color: 'white !important',
            '&:hover': {
              backgroundColor: '#463B97 !important',
            },
          },
          '& .MuiPaginationItem-root.Mui-selected:hover': {
            backgroundColor: '#463B97 !important', // Keep the selected page background purple on hover
          },
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    clearIndicator: {
        padding: '4px',  // 4px padding for clear indicator
      },
      popupIndicator: {
        padding: '1px',  // 2px padding for popup indicator
      },
      dropdownList: {
        width: '230px !important', // Set the desired width for the dropdown
      },
}));
 const ScheduleMeetingForm=({setScheduleFormOpen,scheduleFormOpen,deleteProvider, currentLoggedInAdvisor,selectedOption,selectedTreeMapClientId,getRecordedMeetingList})=>{
    const {enqueueSnackbar}=useSnackbar();
    const dispatch  = useDispatch();
    const formikRef=useRef();
    const classes = useStyles();
    const dataLimit = 100;
    const totalCount = useSelector(state => state.tasks.totalCount);
    const dropPerPage = 100;
    const totalPages = Math.ceil(totalCount / dropPerPage);
    const userToken = useSelector((state) => state.auth.currentUserData.token);
    const initialOffset = 0;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(dataLimit);

    const currentUserData = useSelector(state => state.auth.currentUserData);
    const staffList = useSelector(state => state.tasks.staffList);
const staffListFormatted = formatStaffForMention(staffList).sort((a, b) => 
    a.display.localeCompare(b.display)
); 

const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    const condition = true;
    const newOffset = (newPage - 1) * dropPerPage;
    dispatch(fetchStaffAsync(currentUserData.company.id, '', condition, dropPerPage, newOffset));
    setIsDropdownOpen(true); // Keep the dropdown open
};
useEffect(()=>{
  if(!deleteProvider || !scheduleFormOpen){
      formikRef.current.resetForm();
}
} ,[deleteProvider,scheduleFormOpen]);
const handleInputChange = (event, inputSearch) => {
    if (inputSearch.trim()) {
        const filtered = staffListFormatted.filter((option) =>
            option.display.toLowerCase().startsWith(inputSearch.toLowerCase())
        );
        setFilteredOptions(filtered);
    } else {
        setFilteredOptions(staffListFormatted);
    }
};
    const [openAttachmentModal,setOpenAttachmentModal]=useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(staffListFormatted);
    const [currentPage, setCurrentPage] = useState(1);

    const filesSelectedFromAttachmentModal = useSelector(state => state.ui.filesSelectedFromAttachmentModal);
    const submitMatch = async (values, cc, setSubmitting) => {
        let url = "clientmeeting/"
        const formData = new FormData()
        let localFileAttachments = [];
        filesSelectedFromAttachmentModal.forEach(item => {
          if (item instanceof File)
          {
              localFileAttachments.push(item)
          }
      })
      localFileAttachments.forEach(fileItem => {
        formData.append('attachments', fileItem, fileItem.name);
    })
    const utcFormatMeetingDateAndTime=moment_UTC_format(values?.scheduleMeetingDate,values?.scheduleMeetingTime);
    const meetingTime= moment(values?.scheduleMeetingTime).format("HH:mm:ss")
    formData.append('meeting_type',selectedOption);
    formData.append('meeting_note', values?.note);
    formData.append('meeting_time', meetingTime);
    formData.append('meeting_date', utcFormatMeetingDateAndTime);
    formData.append('client',selectedTreeMapClientId);
    formData.append('organizer',values?.advisorName);
    
        await axiosPost(url, formData)
          .then((res) => {
            if (res?.data?.status&&res?.data?.status_code==201) {
              enqueueSnackbar(res?.data?.message || "Meeting Added", {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
              })
              getRecordedMeetingList()
              dispatch(removeIndivigualFileItems([]))
             setScheduleFormOpen(false)
            } else {
              if(res?.data?.status_code>=500)
              enqueueSnackbar("Something unexpected happened:Sending Failed", {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
              })
            }
          }).catch((error) => {
            enqueueSnackbar("Something unexpected happened:Sending Failed", {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            })
          })
      }
     return <Formik
     innerRef={formikRef}
     initialValues={{ note: '',scheduleMeetingDate:new Date(),scheduleMeetingTime:new Date(),advisorName:currentLoggedInAdvisor?.id }}
     validationSchema={validationSchema}
     validateOnChange
     onSubmit={(values) => {
       submitMatch(values)
     }}
   >
   {({ values, errors,setFieldValue ,touched}) => {
    return (
     <Form><DialogContent style={{minHeight:"420px"}}>
     <div className="snoozeSubHeadWrapper" style={{marginBottom:"6px"}}>
         <div className="snoozeChangeSelectWrapper">
         <Grid container spacing={2} alignItems="center">

  <Grid item xs={11} style={{ textAlign: 'center',fontSize: '1rem' }}>
    Do you want to catch up or schedule a meeting with this client?
  </Grid>
</Grid>
         </div>
       </div>
      
         <Grid container spacing={2} alignItems='center' justifyContent='center' >
           <Grid item xs={5}>
           <FastField
                     component={DatePicker}
                     name="scheduleMeetingDate"
                     label="Date"
                     className=""
                     icon={false}
                     disablePast={false}
                     disableFuture={false}
                     clearable={true}
                     // onChange={(value)=>{
                     //     setOnChangeModal(true)
                     //     lastUpdatedField(value,"Assets",fieldItem)
                     //     setInial(false)
                     // }}
                 />
           </Grid>
           <Grid item xs={5}>
            <FastField
             component={TimePicker}
             name="scheduleMeetingTime"
             label="Time"
             icon={false}
             className="eventModalItem"
         />
          {errors.scheduleMeetingTime && touched.scheduleMeetingTime && (
              <div className="text-danger">{errors.scheduleMeetingTime}</div>
            )}
           </Grid>
           <Grid item xs={10}>
            
             <FastField
               component={Input}
               placeholder="Write a note..."
               label="Note"
               name="note"
               size="small"
               className=""
               multiline={true}
               stripTags={true}
               inputLabelStyle={false}
               convertToCase="sentenceCase"
             />
           </Grid>
           <Grid item xs={10}>
            
            <Autocomplete
                         id="advisorName"
                         name="advisorName"
                         className={classes.root}
                         defaultValue={currentLoggedInAdvisor}
                         options={[...staffListFormatted, { isPagination: true }]} // Add a special item for pagination
                         open={isDropdownOpen} // Explicitly control dropdown visibility
                         onOpen={() => setIsDropdownOpen(true)} // Open dropdown
                         onClose={() => setIsDropdownOpen(false)} // Close dropdown
                         getOptionLabel={(option) =>
                             option.isPagination ? "" : option.display // Avoid showing text for pagination
                         }
                         disableCloseOnSelect
                         classes={{
                             clearIndicator: classes.clearIndicator,
                             popupIndicator: classes.popupIndicator,
                             paper: classes.dropdownList,
                         }}
                         renderOption={(props, option) => {
                             if (option.isPagination && totalPages!=1) {
                                 return (
                                     <li {...props} style={{ padding: '8px', justifyContent: 'center' }}>
                                         <Pagination
                                             className={classes.root}
                                             count={totalPages} // Total pages from API
                                             page={currentPage} // Current active page
                                             onChange={handlePageChange} // Handle page change
                                             size="small"
                                             variant="outlined"
                                             siblingCount={0}
                                         />
                                     </li>
                                 );
                             }
           
                             return (
                                 <li {...props} key={option.id}>
                                     {option.display}
                                 </li>
                             );
                         }}
                         renderInput={(params) => (
                             <TextField
                                 {...params}
                                 label="Organizer"
                                 variant="outlined"
                                 size="medium"
                             />
                         )}
                        
                         onInputChange={(event, inputSearch) => handleInputChange(event, inputSearch)}
                         onChange={(event, selectedOption) => {
                          setFieldValue("advisorName",selectedOption?.id || "")
                          if (selectedOption && !selectedOption.isPagination) {
                            setIsDropdownOpen(false);
                          }
                         }}
                     />
                     {errors.advisorName && touched.advisorName && (
              <div className="text-danger">{errors.advisorName}</div>
            )}
           </Grid>
           {filesSelectedFromAttachmentModal?.length > 0 && ( <Grid item xs={10}>
                
                <FileAttachmentPreview
                  arrangeOnRow
                  files={filesSelectedFromAttachmentModal}
                  showCloseIcon={true}
                  showDownloadIcon={false}
                />
              </Grid>)}
         
         <Grid item xs={10} style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                          width="157px"
                          bgColor="secondary"
                          onClick={() => setOpenAttachmentModal(true)}
                        >
                          <AttachFileIcon />
                          Attach {filesSelectedFromAttachmentModal.length > 0 ? 'more' : 'file'}
                  </Button>
                  <FileAttachmentModalComponent
                    open={openAttachmentModal}
                    handleClose={() => setOpenAttachmentModal(false)}
                    size="sm"
                    showCloseIcon
                    enableCapDrive={false}
                    accept=".doc, .docx,.pdf,.eml"
                  />
                  <Button
              width="160px"
              type="submit"
              bgColor="secondary"
            >
              Submit
            </Button>
                </Grid>
              </Grid> 
     </DialogContent>
       
                 </Form>)}}
                 </Formik>
     
}

export default ScheduleMeetingForm